import { useState, useEffect } from 'react';

const useClock = (d, i) => {
  const [date, setDate] = useState(d);

  const tick = () => {
    setDate(new Date());
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), i);
    return () => clearInterval(timerID);
  });

  return { hours: new Date(date).getHours(), minutes: new Date(date).getMinutes() };
};

export default useClock;
