import { Colors } from './Variables';

const Themes = {
  dark: {
    background: Colors.blackDarker,
    color: Colors.white,
    border: Colors.blackLighter,
    main: Colors.black,
    accent: Colors.blueberry,
    negative: Colors.strawberry,
    positive: Colors.kiwi,
    ripple: Colors.white
  },
  light: {
    background: Colors.white,
    color: Colors.black,
    border: Colors.whiteDarker,
    main: Colors.ice,
    accent: Colors.blueberry,
    negative: Colors.strawberry,
    positive: Colors.kiwi,
    ripple: Colors.black
  }
};

export default Themes;
