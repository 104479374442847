import { lighten, darken } from 'polished';

export const Fonts = {
  // font-size
  sizeSuperTiny: '0.7rem',
  sizeTiny: '0.9rem',
  sizeSmall: '1rem',
  sizeRegular: '1.25rem',
  sizeLarge: '2rem',
  sizeXLarge: '3rem',

  // font-weight
  weightLight: 300,
  weightRegular: 400,
  weightMedium: 500,
  weightSemiBold: 600,
  weightBold: 700,

  // font-family
  head: "'Open sans', 'sans-serif'",
  text: "'Roboto', sans-serif",
  mono: "'Roboto Mono', monospace;"
};

export const Colors = {
  white: '#fdfdfd',
  whiteLighter: '#ffffff',
  whiteDarker: '#f2f2f2',
  black: '#222222',
  blackLighter: '#333333',
  blackDarker: '#111111',
  gray: '#575757',
  grayLighter: '#707070',
  grayDarker: darken(0.15, '#575757'),
  blueberry: '#4897d8',
  blueberryLighter: lighten(0.15, '#4897d8'),
  blueberryDarker: darken(0.15, '#4897d8'),
  kiwi: '#5fad56',
  kiwiLighter: lighten(0.15, '#5fad56'),
  kiwiDarker: darken(0.15, '#5fad56'),
  strawberry: '#fa6e59',
  strawberryLighter: lighten(0.15, '#fa6e59'),
  strawberryDarker: darken(0.15, '#fa6e59'),
  physalis: '#f8a055',
  physalisLighter: lighten(0.15, '#f8a055'),
  physalisDarker: darken(0.15, '#f8a055'),
  banana: '#ffd85c',
  bananaLighter: lighten(0.15, '#ffd85c'),
  bananaDarker: darken(0.15, '#ffd85c'),
  ice: '#F5F7FF',
  iceLighter: lighten(0.15, '#F5F7FF'),
  iceDarker: darken(0.15, '#F5F7FF')
};

export const Media = {
  phone: '650px',
  tablet: '1250px',
  computer: '1600px'
};

export const ZIndex = {
  sideBar: 100000,
  topBar: 110000,
  main: 0,
  dropdown: 111000
};

export const Dimensions = {
  navigationHeight: '60px',
  cornerRadius: '5px'
};

/**
 * Use with box-shadow.
 */
export const Shadows = {
  regular: `1px 5px 15px -10px ${Colors.blackDarker}`
};

export const Regexp = {
  username: `[A-Za-z\\d]+`,
  password:
    `[A-Za-z\\d\\\\!\\\\"\\\\#\\\\$\\\\%\\\\&\\\\'\\\\(\\\\)\\\\*\\\\+\\\\,\\\\-\\\\!\\\\"\\\\#\\\\$\\\\%\\\\&\\\\'\\\\(\\\\)\\\\*\\\\+\\\\,\\\\.\\\\!\\\\"\\\\#\\\\$\\\\%\\\\&\\\\'\\\\(\\\\)\\\\*\\\\+\\\\,\\\\/\\\\:\\\\;\\\\<\\\\=\\\\>\\\\?\\\\@\\\\[\\]\\\\^\\\\_` +
    '\\\\`' +
    `\\\\{\\\\|\\\\}\\\\~]+`,
  time: `^[0-9]+[:]+[0-9]+`,
  org: `^[0-9A-Za-Z]+`
};
