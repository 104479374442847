import { SET_USERS, UPDATE_USER, ADD_USER, DEL_USER } from './actions';

const sortByFirstName = (a, b) => (a.firstName > b.firstName ? 1 : -1);

const users = (state = [], action) => {
  switch (action.type) {
    case SET_USERS: {
      // eslint-disable-next-line no-param-reassign
      state = action.payload ? [...state, ...action.payload] : state;
      state.sort(sortByFirstName);
      return [...state];
    }

    case UPDATE_USER: {
      const user = action.payload;
      // eslint-disable-next-line no-param-reassign
      state = state.map(e => (e.id === user.id ? { ...e, ...user } : e));
      state.sort(sortByFirstName);
      return [...state];
    }

    case ADD_USER: {
      const user = action.payload;
      const dup = state.find(e => e.id === user.id);
      if (dup) {
        return state;
      }

      state.push(user);
      state.sort(sortByFirstName);
      return [...state];
    }

    case DEL_USER: {
      const { id } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state = state.filter(e => e.id !== id);
      return [...state];
    }

    default:
      return state;
  }
};

export default users;
