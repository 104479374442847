import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../Forms/Input';
import Select from '../../Forms/Select';
import Checkbox from '../../Forms/Checkbox';
import { Cancel, Button, Avatar } from '../../../Styles/Styles';
import useForm from '../../../Hooks/useForm';
import {
  FIELD_FULL,
  FIELD_HALF,
  FIELD_MATCH,
  FIELD_ONE_THIRD,
  CALENDAR_REPEAT,
  FIELD_TWO_THIRD,
  LOCKER
} from '../../../Library/Variables';
import {
  addHours,
  cap,
  getWeekNumber,
  ISODateString,
  isSameDate,
  isValidTime,
  parseTime,
  timeBetween
} from '../../../Library/Library';
import { Regexp, ZIndex } from '../../../Resources/Stylesheets/Variables';
import { addErr } from '../../../Reducers/Error/actions';
import DatePicker from '../../Forms/DatePicker';
import { useAddBasketProduct, useGetUnoccupiedProducts } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';

const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  padding: 30px;
  grid-gap: 30px;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr 150px;
  align-items: center;
  grid-column-gap: 15px;
`;

const Content = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 250px) 150px;
  grid-gap: 15px;
  align-items: center;
  min-height: 50px;
`;

const Tools = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 15px;
`;

const lockerSelector = state => state.products.lockers;

const selectLockers = ids =>
  createSelector(lockerSelector, state => {
    if (!ids) return [];
    return state.filter(e => ids.includes(e.id));
  });

const FieldBookingForm = ({ data, close }) => {
  const dispatch = useDispatch();
  const date = new Date(data.cell).toLocaleDateString('sv-SV');
  const startTime = new Date(data.cell).toLocaleTimeString('sv-SV', { hour: '2-digit', minute: '2-digit' });
  const endTime = new Date(addHours(data.cell, 1)).toLocaleTimeString('sv-SV', { hour: '2-digit', minute: '2-digit' });
  const add = useAfterSuccess(useAddBasketProduct(), close);
  const { request, data: searchData } = useGetUnoccupiedProducts();
  const lockers = useSelector(selectLockers(searchData));

  const getVersions = () => {
    const temp = [];
    const { spec } = data.data;
    if (spec.match) temp.push(FIELD_MATCH);
    if (spec.fullField) temp.push(FIELD_FULL);
    if (spec.halfField) temp.push(FIELD_HALF);
    if (spec.oneThirdField) temp.push(FIELD_ONE_THIRD);
    if (spec.twoThirdsField) temp.push(FIELD_TWO_THIRD);
    return temp;
  };
  const versions = getVersions();

  const { values, handleChange } = useForm({
    date,
    startTime,
    endTime,
    wholeDay: false,
    repeatUntil: new Date(date),
    week: getWeekNumber(new Date(date)),
    type: ''
  });

  const getRepeated = useCallback(() => {
    if (values.repeatEvery) {
      const [h, m] = values.endTime.split(':').map(x => parseInt(x, 10));
      const until = new Date(values.repeatUntil);
      until.setHours(h, m);
      return {
        repeatUntil: ISODateString(until),
        repeatEvery: values.repeatEvery
      };
    }
    return undefined;
  }, [values.endTime, values.repeatEvery, values.repeatUntil]);

  useEffect(() => {
    if (!values.version || !isValidTime(values.startTime) || !isValidTime(values.endTime)) return;
    if (!values.wholeDay && timeBetween(values.date, values.startTime, values.endTime) < 0) return;
    if (values.startTime === values.endTime) return;
    const body = {
      startTime: parseTime(values.date, values.wholeDay ? '00:00' : values.startTime),
      endTime: parseTime(values.date, values.wholeDay ? '23:59' : values.endTime),
      type: LOCKER,
      ...getRepeated()
    };

    request(body);
  }, [getRepeated, request, values.date, values.endTime, values.startTime, values.version, values.wholeDay]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!isValidTime(values.startTime)) {
      dispatch(addErr('Starttid har fel format.'));
      return;
    }

    if (!isValidTime(values.endTime)) {
      dispatch(addErr('Sluttid har fel format.'));
      return;
    }

    if (!values.wholeDay) {
      if (timeBetween(values.date, values.startTime, values.endTime) < 0) {
        dispatch(addErr('Start kan inte vara efter slut.'));
        return;
      }
      if (values.startTime === values.endTime) {
        dispatch(addErr('Start och slut kan inte vara samma.'));
        return;
      }
    }

    if (values.repeatEvery) {
      if (isSameDate(values.date, values.repeatUntil)) {
        dispatch(addErr('Start och sluta repetera kan inte vara samma datum.'));
        return;
      }

      if (new Date(values.date).getTime() > new Date(values.repeatUntil).getTime()) {
        dispatch(addErr('Sluta repetera kan inte vara före startdatum.'));
        return;
      }
    }

    if (!values.version) {
      dispatch(addErr('Välj typ av bokning.'));
      return;
    }

    const selectedLockers = Object.keys(values)
      .filter(key => key.includes(LOCKER))
      .map(key => parseInt(key.substring(LOCKER.length), 10))
      .filter(id => lockers.find(x => x.id === id));

    const body = {
      productID: data.data.id,
      type: data.data.type,
      startTime: parseTime(values.date, values.wholeDay ? '00:00' : values.startTime),
      endTime: parseTime(values.date, values.wholeDay ? '23:59' : values.endTime),
      spec: {
        version: values.wholeDay ? `${values.version}_DAY` : values.version,
        comment: values.comment
      }
    };
    if (selectedLockers.length > 0) body.lockerIDs = selectedLockers;
    body.spec = {
      ...body.spec,
      ...getRepeated()
    };
    add.request(body);
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Header>
        <Avatar image={data.data.image} initials={data.data.name.charAt(0)} size="60px" />
        <h4>{cap(data.data.name)}</h4>
        <Checkbox name="wholeDay" value={values.wholeDay} onChange={handleChange} label="Heldag" />
      </Header>
      <Content>
        <Row style={{ zIndex: ZIndex.dropdown - 1 }}>
          <Input label="Datum" name="date" value={values.date} onChange={handleChange} type="text" />
          {!values.wholeDay && (
            <>
              <Input
                label="Start"
                name="startTime"
                value={values.startTime}
                onChange={handleChange}
                type="text"
                pattern={Regexp.time}
              />
              <Input
                label="Slut"
                name="endTime"
                value={values.endTime}
                onChange={handleChange}
                type="text"
                pattern={Regexp.time}
              />
              <h4>{`${timeBetween(values.date, values.startTime, values.endTime)} h`}</h4>
            </>
          )}
        </Row>
        <Row style={{ zIndex: ZIndex.dropdown }}>
          <Select
            value={values.repeatEvery}
            onChange={handleChange}
            name="repeatEvery"
            label="Repetera"
            options={CALENDAR_REPEAT}
          />
          {values.repeatEvery && (
            <DatePicker
              label="Sluta repetera den"
              name="repeatUntil"
              value={values.repeatUntil}
              onChange={handleChange}
            />
          )}
        </Row>
        <Row>
          <Select
            value={values.version}
            onChange={handleChange}
            name="version"
            label="Typ av bokning"
            options={versions}
          />
        </Row>
      </Content>
      <Content>
        <h4>{`Lediga omklädningsrum  (${lockers.length})`}</h4>
        <Row>
          {lockers.map(e => (
            <Checkbox
              key={`${LOCKER}${e.id}`}
              name={`${LOCKER}${e.id}`}
              onChange={handleChange}
              label={cap(e.name)}
              value={values[`${LOCKER}${e.id}`]}
            />
          ))}
        </Row>
      </Content>
      <Tools>
        <Cancel value="Avbryt" onClick={close} />
        <Button type="submit" value="Lägg till i varukorgen" />
      </Tools>
    </Container>
  );
};

FieldBookingForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
    .isRequired,
  close: PropTypes.func.isRequired
};

export default FieldBookingForm;
