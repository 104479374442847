import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import useForm from '../../../Hooks/useForm';
import { BasketType } from '../../../Types/types';
import { useEditBasketProduct } from '../../../Library/Hooks';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 20px;
  grid-template-rows: 1fr;
  grid-gap: 3px;
  align-items: center;
  grid-auto-flow: column;

  input {
    transition: 300ms ease-out;
    width: ${props => `${props.length * 10 || 20}px`};
    max-width: 100px;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeTiny};
    background: none;
    color: ${props => props.theme.color};
    appearance: none;
    overflow-x: hidden;
    border: 2px solid ${props => (props.valid ? 'transparent' : props.theme.negative)};
  }
`;

const Guests = ({ data }) => {
  const { values, handleChange } = useForm({ guests: data.spec.guests || '0' });
  const { request } = useEditBasketProduct();

  const handleBlur = async () => {
    const val = parseInt(values.guests, 10);
    if (!Number.isNaN(val)) {
      const body = data;
      body.spec.guests = val;
      request(body);
    }
  };

  return (
    <Container length={values.guests ? values.guests.toString().length : 0} valid={parseInt(data.spec.guests, 10) > 0}>
      <input
        type="text"
        name="guests"
        value={values.guests}
        onChange={handleChange}
        onBlur={handleBlur}
        pattern="[0-9]+"
        required
      />
      <h5>st</h5>
    </Container>
  );
};

Guests.propTypes = {
  data: PropTypes.shape(BasketType).isRequired
};

export default Guests;
