import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useComponentVisible } from '../../../Hooks';
import { Media, Dimensions, Fonts } from '../../../Resources/Stylesheets/Variables';
import Calendar from './Calendar/Calendar';

const Container = styled.div`
  grid-area: date;
  border-right: 2px solid ${props => props.theme.border};
  user-select: none;
  position: relative;
  width: 100%;
  @media (max-width: ${Media.phone}) {
    border-right: none;
    border-bottom: 2px solid ${props => props.theme.border};
  }
`;

const Label = styled.div`
  display: grid;
  grid-template-columns: ${Dimensions.navigationHeight} 1fr ${Dimensions.navigationHeight};
  grid-template-rows: ${Dimensions.navigationHeight};
  align-items: center;
`;

const Icon = styled.i`
  display: flex;
  justify-content: center;
  font-size: ${Fonts.sizeRegular};
  color: ${props => (props.active ? props.theme.accent : props.theme.color)};
  transition: 200ms ease-in-out;
`;

const interval = value => {
  const from = new Date(value[0]).toLocaleDateString('sv-SV', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
  const to = new Date(value[1]).toLocaleDateString('sv-SV', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
  const ret = `${from} - ${to}`;
  return `${ret}`;
};

const Dates = ({ dates: value, setDates: setValue }) => {
  const { ref, visible, setVisible } = useComponentVisible(false);
  const i = interval(value);

  return (
    <Container ref={ref}>
      <Label onClick={() => setVisible(v => !v)}>
        <Icon active={visible || (value[0] && value[1])} className="icon-calendar" />
        <h4>{value[0] && value[1] ? i : 'Vilka datum?'}</h4>
        <Icon className={visible ? 'icon-angle-up' : 'icon-angle-down'} />
      </Label>
      {visible && <Calendar value={value} onSelect={setValue} />}
    </Container>
  );
};

Dates.propTypes = {
  setDates: PropTypes.func.isRequired,
  dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired
};

export default Dates;
