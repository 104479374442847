/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts } from '../../../../Resources/Stylesheets/Variables';
import { cap } from '../../../../Library/Library';

const weekdays = [
  { id: 1, label: 'M' },
  { id: 2, label: 'T' },
  { id: 3, label: 'O' },
  { id: 4, label: 'T' },
  { id: 5, label: 'F' },
  { id: 6, label: 'L' },
  { id: 7, label: 'S' }
];

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 30px 1fr;
  grid-template-areas: 'month' 'labels' 'days';
  grid-gap: 5px;
`;

const MonthLabel = styled.div`
  grid-area: month;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-template-rows: 30px;
  grid-template-areas: 'prev label next';
  color: ${props => props.theme.color};
  cursor: pointer;
  text-align: center;
  justify-content: space-between;

  i:nth-child(1) {
    grid-area: prev;
    line-height: 30px;
    font-size: ${Fonts.sizeRegular};
  }
  i:nth-child(3) {
    grid-area: next;
    line-height: 30px;
    font-size: ${Fonts.sizeRegular};
  }
  h4 {
    grid-area: label;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const WeekLabels = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
`;

const WeekLabel = styled.i`
  font-size: 0.7rem;
  color: ${props => (props.current ? props.theme.accent : props.theme.color)};
  font-weight: ${Fonts.weightBold};
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
`;

const Days = styled.div`
  grid-area: days;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
`;

const Day = styled.i`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 0.7rem;
  user-select: none;
  cursor: pointer;
  transition: 200ms ease-out;
  border-radius: ${props => (props.selectedAsFrom || props.selectedAsTo ? '50%' : '0')};
  ${props => {
    if (props.selectedAsFrom) {
      return `
        background: ${props.theme.accent};
        border-radius: 50% 0 0 50%;
        color: ${props.theme.color};
      `;
    }
    if (props.selectedAsTo) {
      return `
        background: ${props.theme.accent};
        border-radius: 0 50% 50% 0;
        color: ${props.theme.color};
      `;
    }
    if (props.inInterval) {
      return `
        background: ${props.theme.accent};
        border-radius: 0;
        opacity: 0.5;
        color: ${props.theme.color};
      `;
    }
    if (props.today) {
      return `
        color: ${props.theme.accent};
        font-weight: ${Fonts.weightBold};
      `;
    }
    if (props.beforeCurrentDay) {
      return `
        opacity: 0.2;
        pointer-events: none;
        color: ${props.theme.color};
      `;
    }
    return `
      color: ${props.theme.color};
    `;
  }}
  &:hover {
    color: ${props => props.theme.color};
    background: ${props => props.theme.accent};
    opacity: 1;
  }
`;

const getDaysOfMonth = date => {
  return new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0).getDate();
};

const formatDate = (date, options) => {
  return new Date(date).toLocaleDateString('sv-SV', options);
};

const getOneMonthBefore = () => {
  const d = new Date();
  d.setMonth(d.getMonth() - 12);
  return d;
};

const isSameDate = (d1, d2) =>
  new Date(d1).getDate() === new Date(d2).getDate() &&
  new Date(d1).getMonth() === new Date(d2).getMonth() &&
  new Date(d1).getFullYear() === new Date(d2).getFullYear();

const isInInterval = (start, date, end) =>
  new Date(date).getTime() > new Date(start).getTime() && new Date(date).getTime() < new Date(end).getTime();

const Month = ({ hour, minutes, month, setLeftMonth, setRightMonth, onSelect, from, to }) => {
  const [options, setOptions] = useState({ month: 'short' });
  const [days, setDays] = useState(0);

  useEffect(() => {
    setOptions(
      new Date(month).getFullYear() === new Date().getFullYear()
        ? { month: 'long' }
        : { month: 'short', year: 'numeric' }
    );
    setDays(getDaysOfMonth(month));
  }, [month]);

  return (
    <Container>
      <MonthLabel>
        <i
          className="icon-angle-left"
          onClick={() => {
            setLeftMonth('previous');
            setRightMonth('previous');
          }}
        />
        <h4>{cap(formatDate(month, options))}</h4>
        <i
          className="icon-angle-right"
          onClick={() => {
            setLeftMonth('next');
            setRightMonth('next');
          }}
        />
      </MonthLabel>
      <WeekLabels>
        {weekdays.map(e => (
          <WeekLabel
            key={e.id}
            current={
              e.id === new Date().getDay() &&
              new Date().getMonth() === month.getMonth() &&
              new Date().getFullYear() === month.getFullYear()
            }
          >
            {e.label}
          </WeekLabel>
        ))}
      </WeekLabels>
      <Days>
        {(() => {
          const d = [];
          const firstDay = new Date(new Date(month).getFullYear(), new Date(month).getMonth(), 1, hour, minutes);

          if (firstDay.getDay() === 0) {
            for (let i = 0; i < 6; i += 1) {
              d.push(<i key={Math.random()} />);
            }
          } else {
            for (let i = 0; i < firstDay.getDay() - 1; i += 1) {
              d.push(<i key={Math.random()} />);
            }
          }

          for (let i = 1; i <= days; i += 1) {
            const date = new Date(new Date(month).getFullYear(), new Date(month).getMonth(), i, hour, minutes);

            d.push(
              <Day
                key={i.toString()}
                onClick={() => onSelect(date)}
                selectedAsTo={isSameDate(date, to)}
                selectedAsFrom={isSameDate(date, from)}
                today={isSameDate(date, new Date())}
                inInterval={isInInterval(from, date, to)}
                beforeCurrentDay={
                  new Date(new Date(month).getFullYear(), new Date(month).getMonth(), i + 1).getTime() <
                  getOneMonthBefore().getTime()
                }
              >
                {i}
              </Day>
            );
          }

          return d;
        })()}
      </Days>
    </Container>
  );
};

Month.propTypes = {
  month: PropTypes.instanceOf(Date).isRequired,
  setLeftMonth: PropTypes.func.isRequired,
  setRightMonth: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  hour: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired
};

Month.defaultProps = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date)
};

export default Month;
