export const INIT_PRODUCTS = 'INIT_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DEL_PRODUCT = 'DEL_PRODUCT';

export const initProducts = payload => ({
  type: INIT_PRODUCTS,
  payload
});

export const updateProduct = payload => ({
  type: UPDATE_PRODUCT,
  payload
});

export const addProduct = payload => ({
  type: ADD_PRODUCT,
  payload
});

export const deleteProduct = payload => ({
  type: DEL_PRODUCT,
  payload
});
