import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Main, Container, Initials, Image, Button } from '../../Styles/Styles';
import { Media } from '../../Resources/Stylesheets/Variables';
import ImagePicker from '../../Components/ImagePicker/ImagePicker';
import PopOverForm from '../../Components/PopOver/PopOverForm';
import { cap, formatDate } from '../../Library/Library';
import UserForm from './Components/UserForm/UserForm';
import PasswordForm from './Components/PasswordForm/PasswordForm';
import { useUpdateProfile } from '../../Library/Hooks';

const Component = styled(Main)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Module = styled(Container)`
  position: relative;
  display: grid;
  width: ${Media.phone};
  grid-template-columns: 1fr 45px 45px 1fr;
  grid-template-rows: 120px 1fr 1fr 1fr;
  padding: 30px;
  grid-gap: 30px;
  grid-template-areas:
    '. image image .'
    'name .  . user'
    'role . . date'
    'tool tool tool tool';
  @media (max-width: ${Media.phone}) {
    grid-template-columns: 1fr 120px 1fr;
    grid-template-rows: 120px 45px 45px 45px 1fr;
    grid-template-areas:
      '. image .'
      'name name name'
      'user user user'
      'role role role'
      'date date date'
      'tool tool tool';
  }
`;

const ImageContainer = styled.div`
  grid-area: image;
  display: flex;
  justify-items: center;
  align-items: center;
`;

const Cell = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
`;

const Name = styled(Cell)`
  grid-area: name;
`;

const User = styled(Cell)`
  grid-area: user;
`;

const Role = styled(Cell)`
  grid-area: role;
`;

const Date = styled(Cell)`
  grid-area: date;
`;

const ToolContainer = styled.div`
  grid-area: tool;
  display: flex;
  flex-flow: row;
  @media (max-width: ${Media.phone}) {
    flex-flow: column;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
  @media (max-width: ${Media.phone}) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Profile = () => {
  const profile = useSelector(state => state.profile);
  const [editUser, setEditUser] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  const { request } = useUpdateProfile();

  const updateProfileImage = async image => request({ ...profile, image });
  const deleteProfileImage = async () => request({ ...profile, image: null });

  return (
    <Component>
      <Module>
        <ImageContainer>
          {(() => {
            if (profile.image === null) {
              return (
                <ImagePicker
                  Image={Initials}
                  text={`${profile.firstName.charAt(0).toUpperCase()}${profile.lastName.charAt(0).toUpperCase()}`}
                  onUpdate={updateProfileImage}
                  onDelete={deleteProfileImage}
                />
              );
            }
            return (
              <ImagePicker
                Image={Image}
                src={profile.image}
                onUpdate={updateProfileImage}
                onDelete={deleteProfileImage}
              />
            );
          })()}
        </ImageContainer>
        <Name>
          <p>Namn</p>
          <h4>{cap(`${profile.firstName} ${profile.lastName}`)}</h4>
        </Name>
        <User>
          <p>Användarnamn</p>
          <h4>{profile.username}</h4>
        </User>
        <Role>
          <p>Roll</p>
          <h4>{cap(profile.role)}</h4>
        </Role>
        <Date>
          <p>Skapad</p>
          <h4>{formatDate(profile.createdAt)}</h4>
        </Date>
        <ToolContainer>
          <StyledButton value="Redigera" onClick={() => setEditUser(true)} />
          <StyledButton value="Byt lösenord" onClick={() => setEditPassword(true)} />
        </ToolContainer>
      </Module>
      {editUser && (
        <PopOverForm
          data={{
            id: profile.id,
            name: cap(`${profile.firstName} ${profile.lastName}`),
            image: profile.image,
            initials: `${profile.firstName.charAt(0).toUpperCase()}${profile.lastName.charAt(0).toUpperCase()}`,
            title: 'Redigerar användare',
            text: `Dessa ändringar kommer synas för Administratörer.`,
            submitLabel: 'Spara'
          }}
          Form={UserForm}
          formID="UserForm"
          formData={profile}
          onCancel={() => setEditUser(false)}
        />
      )}
      {editPassword && (
        <PopOverForm
          data={{
            id: profile.id,
            name: cap(`${profile.firstName} ${profile.lastName}`),
            image: profile.image,
            initials: `${profile.firstName.charAt(0).toUpperCase()}${profile.lastName.charAt(0).toUpperCase()}`,
            title: 'Byter lösenord',
            text: `Har du glömt bort ditt gamla lösenord kontakta en Administratör.`,
            submitLabel: 'Spara'
          }}
          Form={PasswordForm}
          formID="PasswordForm"
          formData={profile}
          onCancel={() => setEditPassword(false)}
        />
      )}
    </Component>
  );
};

export default Profile;
