import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Main as MainTemp } from '../../Styles/Styles';
import { Media } from '../../Resources/Stylesheets/Variables';
import Header from '../../Components/Header/Header';
import { cap, formatPrice, getFieldLabel } from '../../Library/Library';
import Card, { CardButton } from '../../Components/Card/Card';

const Main = styled(MainTemp)`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 30px;
`;

const Container = styled.div`
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-template-rows: repeat(auto-fill, 350px);
  grid-auto-columns: 225px;
  grid-auto-rows: 325px;
  grid-gap: 30px;
  margin-bottom: 30px;
  @media (max-width: ${Media.phone}) {
    grid-template-columns: repeat(auto-fill, 100%);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-auto-columns: 100%;
    grid-auto-rows: 1fr;
  }
`;

const Fields = () => {
  const fields = useSelector(state => state.products.fields);
  const lockers = useSelector(state => state.products.lockers);
  const fieldVat = useSelector(state => state.settings.field.vat);
  const lockerVat = useSelector(state => state.settings.locker.vat);
  const [searchFields, setSearchFields] = useState('');
  const [searchLockers, setSearchLockers] = useState('');

  const filteredFields = fields
    .filter(e => e.active && `${e.name.toLowerCase()}`.includes(searchFields))
    .map(e => (
      <Card
        key={e.id}
        image={e.image}
        name={cap(e.name)}
        rows={[
          { icon: 'icon-home', label: 'Storlek', value: getFieldLabel(e.spec.size) },
          { icon: 'icon-tags', label: 'Timpris', value: `${formatPrice(e.spec.fullField, fieldVat)} kr` },
          { icon: 'icon-tags', label: 'Dagspris', value: `${formatPrice(e.spec.fullFieldDay, fieldVat)} kr` }
        ]}
        button={<CardButton value={<i className="icon-angle-right" />} to={`/fields/${e.id}`} />}
      />
    ));

  const filteredLockers = lockers
    .filter(e => e.active && `${e.name.toLowerCase()}`.includes(searchLockers))
    .map(e => (
      <Card
        key={e.id}
        image={e.image}
        name={cap(e.name)}
        rows={[
          { icon: 'icon-users', label: 'Storlek', value: `${e.spec.size} st` },
          { icon: 'icon-tags', label: 'Timpris', value: `${formatPrice(e.spec.hour, lockerVat)} kr` },
          { icon: 'icon-tags', label: 'Dagspris', value: `${formatPrice(e.spec.day, lockerVat)} kr` }
        ]}
        button={<CardButton value={<i className="icon-angle-right" />} to={`/lockers/${e.id}`} />}
      />
    ));

  return (
    <Main>
      <Header title="Ytor" search={setSearchFields} />
      {fields.length > 0 && <Container>{filteredFields}</Container>}
      {filteredFields.length === 0 && searchFields !== '' && <p>{`Hittade inga ytor för: ${searchFields}`}</p>}
      {filteredFields.length === 0 && searchFields === '' && <p>Det finns inga aktiva ytor.</p>}
      <Header title="Omklädningsrum" search={setSearchLockers} />
      {filteredLockers.length > 0 && <Container>{filteredLockers}</Container>}
      {filteredLockers.length === 0 && searchLockers !== '' && (
        <p>{`Hittade inga omklädningsrum för: ${searchLockers}`}</p>
      )}
      {filteredLockers.length === 0 && searchLockers === '' && <p>Det finns inga aktiva omklädningsrum.</p>}
    </Main>
  );
};

export default Fields;
