import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import useForm from '../../../Hooks/useForm';
import { useEditBasketProduct } from '../../../Library/Hooks';
import { BasketType } from '../../../Types/types';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 20px;
  grid-template-rows: 1fr;
  grid-gap: 3px;
  align-items: center;
  grid-auto-flow: column;

  input {
    transition: 300ms ease-out;
    width: ${props => `${props.length * 10 || 20}px`};
    max-width: 100px;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeTiny};
    background: none;
    color: ${props => props.theme.color};
    appearance: none;
    overflow-x: hidden;
    border: none;
  }
`;

const Quantity = ({ data }) => {
  const { quantity } = data;
  const { values, handleChange } = useForm({ quantity });
  const { request } = useEditBasketProduct();

  const handleBlur = async () => {
    const val = parseInt(values.quantity, 10);
    if (!Number.isNaN(val)) {
      const body = data;
      body.quantity = val;
      request(body);
    }
  };

  return (
    <Container length={values.quantity ? values.quantity.toString().length : 0}>
      <input
        type="text"
        name="quantity"
        value={values.quantity}
        onChange={handleChange}
        onBlur={handleBlur}
        pattern="[0-9]+"
        required
      />
      <h5>st</h5>
    </Container>
  );
};

Quantity.propTypes = {
  data: PropTypes.shape(BasketType).isRequired
};

export default Quantity;
