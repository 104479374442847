import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { cap, formatDate, rgba } from '../../../Library/Library';
import { useComponentVisible } from '../../../Hooks';
import PopOver from '../../PopOver/PopOver';
import { Media } from '../../../Resources/Stylesheets/Variables';
import { BOOKING_TYPE } from '../../../Library/Variables';

const Container = styled.div`
  grid-row-start: ${props => (props.start === 0 ? 1 : Math.ceil(props.start * 12))};
  grid-row-end: ${props => (props.end === 0 ? 288 : Math.ceil(props.end * 12))};
  grid-column: ${props => `${props.column} / span ${props.span}`};
  border-left: 2px solid ${props => props.background};
  background-color: ${props => (props.active ? rgba(0.8, props.background) : rgba(0.3, props.background))};
  cursor: pointer;
  margin: 2px;
  padding: 4px;
  z-index: 10;
  position: relative;
  overflow: hidden;
  transition: 300ms ease-in-out;
  &:hover {
    background-color: ${props => rgba(0.8, props.background)};
  }
`;

const Box = styled.div`
  width: 300px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(4, max-content);
  grid-gap: 7px;
  padding: 30px;
  justify-content: space-between;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    width: 90vw;
    padding: 15px;
    grid-template-columns: max-content;
    grid-template-rows: repeat(4, max-content);
  }
`;

const Button = styled(Link)`
  color: ${props => props.theme.color};
`;

const getDesc = b => {
  if (b.type === 'bookings') {
    return (
      <Button to={`/bookingReference/${b.referenceID}`}>
        <i>{b.referenceID.toUpperCase()}</i>
      </Button>
    );
  }
  return 'Varukorg';
};

const pad = t => (t < 10 ? `0${t}` : t);

const Booking = ({ data }) => {
  const loggedIn = useSelector(state => !!state.session.sessionToken);
  const { start, end, date, name, comment } = data;
  const startHour = Math.floor(start);
  const endHour = Math.floor(end);
  const startMinute = Math.round((start - startHour) * 60);
  const endMinute = Math.round((end - endHour) * 60);
  const time = `${pad(startHour)}:${pad(startMinute)} - ${pad(endHour)}:${pad(endMinute)}`;
  const desc = loggedIn && getDesc(data);
  const version = BOOKING_TYPE.find(e => e.type === data.version);
  const { ref, visible, setVisible } = useComponentVisible(false);

  const Information = () => (
    <Box>
      {version ? <i>{version.label}</i> : <i />}
      <i>{desc}</i>
      <p>{formatDate(date)}</p>
      <p>{time}</p>
      <h4>{cap(name)}</h4>
      <div />
      <p>{comment}</p>
    </Box>
  );

  return (
    <Container
      ref={ref}
      start={data.start}
      end={data.end}
      column={data.column}
      span={data.span}
      background={data.background}
      onClick={() => setVisible(true)}
      active={visible}
    >
      <i>{time}</i>
      <h6>{cap(name)}</h6>
      <div>
        <i>{comment}</i>
      </div>
      <i>{desc}</i>
      {visible && <PopOver Content={Information} close={() => setVisible(false)} />}
    </Container>
  );
};

export const BookingProps = {
  id: PropTypes.string,
  name: PropTypes.string,
  comment: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  start: PropTypes.number,
  end: PropTypes.number,
  column: PropTypes.number,
  span: PropTypes.number,
  background: PropTypes.string,
  version: PropTypes.string
};

Booking.propTypes = {
  data: PropTypes.shape(BookingProps).isRequired
};

export default Booking;
