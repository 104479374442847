import { useEffect, useReducer, useCallback } from 'react';
import { encodeImage, ISODateString, logout } from './Library';

export const GET = 'GET';
export const DELETE = 'DELETE';
export const POST = 'POST';
export const PUT = 'PUT';

const sessionToken = localStorage.getItem('sessionToken');

export const BASE_HTTP_URL = process.env.REACT_APP_PROXY_URL || window.location.origin;

const parseValues = o => {
  if (o.bookedProducts) {
    o.bookedProducts = o.bookedProducts.map(e => {
      if (e.startTime) e.startTime = new Date(Date.parse(e.startTime));
      if (e.endTime) e.endTime = new Date(Date.parse(e.endTime));
      return e;
    });
  }
  if (o.createdAt) o.createdAt = new Date(Date.parse(o.createdAt));
  if (o.lastLogin) o.lastLogin = new Date(Date.parse(o.lastLogin));
  if (o.startTime) o.startTime = new Date(Date.parse(o.startTime));
  if (o.endTime) o.endTime = new Date(Date.parse(o.endTime));
  if (o.date) o.date = new Date(Date.parse(o.date));
  if (o.image) o.image = `data:image;base64,${o.image}`;
  return o;
};

export const parseData = data => (Array.isArray(data) ? data.map(parseValues) : parseValues(data));

export const fetchJSON = async (url, options = {}) => {
  options.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers
  };
  if (sessionToken) options.headers.Authorization = `Bearer ${sessionToken}`;

  const resp = await fetch(`${BASE_HTTP_URL}/api/v1${url}`, options);

  if (resp.status >= 400) {
    if (resp.status === 401) logout();
    return { error: resp.status, data: null };
  }
  const data = resp.status === 204 ? null : parseData(await resp.json());

  return { error: null, data };
};

const initialState = {
  loading: true,
  error: null,
  data: null,
  body: null,
  count: 0
};

const BEFORE = 'BEFORE';
const AFTER = 'AFTER';
const REQUEST = 'REQUEST';

const reducer = (state, action) => {
  switch (action.type) {
    case BEFORE:
      return { ...initialState };
    case AFTER:
      return { ...state, ...action.payload, loading: false };
    case REQUEST:
      return { ...state, body: action.payload, count: state.count + 1 };
    default:
      throw new Error('Invalid reducer state');
  }
};

export const useFetchLater = fn => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  useEffect(() => {
    if (state.count) {
      (async () => {
        dispatch({ type: BEFORE });
        const payload = await fn(state.body);
        dispatch({ type: AFTER, payload });
      })();
    }
  }, [fn, state.body, state.count]);

  state.request = useCallback(payload => dispatch({ type: REQUEST, payload }), []);

  return state;
};

export const useFetch = fn => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  useEffect(() => {
    (async () => {
      dispatch({ type: BEFORE });
      const payload = await fn();
      dispatch({ type: AFTER, payload });
    })();
  }, [fn, state.count]);

  state.request = useCallback(payload => dispatch({ type: REQUEST, payload }), []);

  return state;
};

export const formatBody = body => {
  if (body.image) body.image = encodeImage(body.image);
  if (body.startTime) body.startTime = ISODateString(body.startTime);
  if (body.endTime) body.endTime = ISODateString(body.endTime);
  if (body.to) body.to = ISODateString(body.to);
  if (body.from) body.from = ISODateString(body.from);
  if (body.date) body.date = ISODateString(body.date);
  return body;
};

export const getJSON = url => fetchJSON(url, { method: GET });
export const deleteJSON = url => fetchJSON(url, { method: DELETE });
export const postJSON = (url, body) => fetchJSON(url, { method: POST, body: JSON.stringify(formatBody(body)) });
export const putJSON = (url, body) => fetchJSON(url, { method: PUT, body: JSON.stringify(formatBody(body)) });
