import React, { useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Media, Dimensions, Colors } from '../../Resources/Stylesheets/Variables';
import Header from './Components/Header';
import { Button } from '../../Styles/Styles';
import Camping from './Components/Camping';
import Loader from '../../Components/Loader/Loader';
import usePublicData from '../../App/Hooks/usePublicData';
import { CAMPING, CONFERENCE, FIELD } from '../../Library/Variables';
import Fields from '../Fields/Fields';
import Conferences from '../Conference/Conferences';
import ConferenceCalendar from '../Conference/Components/ConferenceCalendar';
import FieldCalendar from '../Fields/Components/FieldCalendar';
import LockerCalendar from '../Fields/Components/LockerCalendar';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${Dimensions.navigationHeight} ${Dimensions.navigationHeight} 1fr;
  grid-template-areas: 'header' 'tabs' 'content';
  background: ${props => props.theme.main};
  color: ${props => props.theme.color};
`;

const Tabs = styled.div`
  grid-area: tabs;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 120px;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  padding: 0 30px;
  border-bottom: 2px solid ${props => props.theme.border};
  background: ${props => props.theme.border};
  position: sticky;
  top: 0;
  @media (max-width: ${Media.phone}) {
    grid-auto-columns: 1fr;
    overflow-x: auto;
    padding: 0 15px;
  }
`;

const Tab = styled(Button)`
  background: transparent;
  color: ${props => !props.active && props.theme.color};
  &.active {
  color: ${Colors.white}
    background: ${props => props.theme.accent};
  }
`;

const Content = styled.div`
  grid-area: content;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-areas: 'main';
  overflow-y: auto;
`;

const routes = [
  { type: CAMPING, value: 'Camping', path: '/' },
  { type: FIELD, value: 'Ytor', path: '/fields' },
  { type: CONFERENCE, value: 'Konferens', path: '/conferences' }
];

const Landing = () => {
  const { theme } = useSelector(state => state.session);
  const [refresh, setRefresh] = useState(false);
  const { loading, error } = usePublicData(refresh);

  if (loading || error) {
    return (
      <Loader
        background={theme.body}
        color={theme.color}
        active
        refresh={error !== null}
        setRefresh={() => setRefresh(e => !e)}
      />
    );
  }

  return (
    <BrowserRouter>
      <Container>
        <Header />
        <Tabs>
          {routes.map(e => (
            <Tab key={e.type} value={e.value} to={e.path} />
          ))}
        </Tabs>
        <Content>
          <Route exact path="/" component={Camping} />
          <Route exact path="/fields" component={Fields} />
          <Route exact path="/fields/:id" component={FieldCalendar} />
          <Route exact path="/lockers/:id" component={LockerCalendar} />
          <Route exact path="/conferences" component={Conferences} />
          <Route exact path="/conferences/:id" component={ConferenceCalendar} />
        </Content>
      </Container>
    </BrowserRouter>
  );
};

export default Landing;
