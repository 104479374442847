import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useForm from '../../../Hooks/useForm';
import { Button, Cancel } from '../../../Styles/Styles';
import { cap } from '../../../Library/Library';
import { useEditProduct, useGetBLArticles } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';
import Select from '../../../Components/Forms/Select';

const Container = styled.form`
  display: grid;
  grid-template-columns: minmax(250px, 300px) minmax(250px, 300px);
  grid-template-rows: max-content 1fr max-content;
  padding: 30px;
  grid-gap: 30px;
  grid-template-areas: 'header header' '. .' 'tools tools';
  height: 350px;
`;

const Header = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

const Column = styled.div`
  display: grid;
  grid-auto-rows: 50px;
  align-items: center;
  grid-gap: 15px;
`;

const Tools = styled.div`
  grid-area: tools;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 15px;
`;

const BLConnect = ({ data, close }) => {
  const { values, handleChange } = useForm({ blID: data.blID });
  const { data: blArticles } = useGetBLArticles();
  const { request } = useAfterSuccess(useEditProduct(), close);

  const ARTICLES = blArticles ? blArticles.map(e => ({ type: e.id, label: e.name })) : [];

  const handleSubmit = async e => {
    e.preventDefault();
    const body = {
      ...data,
      blID: values.blID
    };
    request(body);
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Header>
        <h4>Koppla produkt till artikel i Björn Lundén</h4>
      </Header>
      <Column>
        <h5>Ibex</h5>
        <h4>{cap(data.name)}</h4>
      </Column>
      <Column>
        <h5>Björn Lundén</h5>
        <Select name="blID" value={values.blID} onChange={handleChange} label="Artikel" options={ARTICLES} />
      </Column>
      <Tools>
        <Cancel type="button" value="Avbryt" onClick={close} />
        <Button type="submit" value="Spara" />
      </Tools>
    </Container>
  );
};

BLConnect.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
    .isRequired,
  close: PropTypes.func.isRequired
};

export default BLConnect;
