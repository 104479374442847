import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { addErr } from '../../../Reducers/Error/actions';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import useForm from '../../../Hooks/useForm';
import { useEditBasketProduct } from '../../../Library/Hooks';
import { BasketType } from '../../../Types/types';
import { formatPrice, preparePriceForRequest } from '../../../Library/Library';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 20px;
  grid-template-rows: 1fr;
  grid-gap: 3px;
  align-items: center;
  grid-auto-flow: column;

  input {
    transition: 300ms ease-out;
    width: ${props => `${props.length * 10 || 20}px`};
    max-width: 100px;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeTiny};
    background: none;
    color: ${props => props.theme.color};
    appearance: none;
    border: none;
    overflow-x: hidden;
  }
`;

const Discount = ({ data }) => {
  const { vat, discount, price } = data;
  const { values, handleChange, updateValues } = useForm({ discount: formatPrice(discount, vat) });
  const { request } = useEditBasketProduct();
  const dispatch = useDispatch();

  useEffect(() => {
    updateValues({ discount: formatPrice(discount, vat) });
  }, [discount, updateValues, vat]);

  const handleBlur = async () => {
    const dis = values.discount ? preparePriceForRequest(parseFloat(values.discount), data.vat) : 0;
    if (dis > price) {
      dispatch(addErr(`Rabatten kan inte vara högre än ${formatPrice(price, vat)}.`));
      updateValues({ discount: formatPrice(discount, vat) });
    } else if (dis < 0) {
      dispatch(addErr(`Rabatten kan inte vara mindre än ${0}.`));
      updateValues({ discount: formatPrice(discount, vat) });
    } else if (!values.discount || !Number.isNaN(values.discount)) {
      request({ ...data, discount: dis });
      updateValues({ discount: formatPrice(dis, vat) });
    }
  };

  return (
    <Container length={values.discount.toString().length}>
      <input
        type="text"
        name="discount"
        value={values.discount}
        onChange={handleChange}
        onBlur={handleBlur}
        pattern="\d+[.]\d+"
      />
      <h5>kr</h5>
    </Container>
  );
};

Discount.propTypes = {
  data: PropTypes.shape(BasketType).isRequired
};

export default Discount;
