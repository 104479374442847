import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Dimensions, Media } from '../../Resources/Stylesheets/Variables';
import { Main as MainTemplate, Container, Button } from '../../Styles/Styles';
import General from './Components/General/General';
import Camping from './Components/Camping/Camping';
import Fields from './Components/Fields/Fields';
import Conference from './Components/Conference/Conference';
import Item from './Components/Item/Item';
import Locker from './Components/Locker/Locker';
import { useUpdateSettings } from '../../Library/Hooks';

const Main = styled(MainTemplate)`
  display: grid;
  grid-template-columns: minmax(${Media.phone}, 1175px) max-content;
  grid-template-rows: ${Dimensions.navigationHeight} minmax(min-content, max-content);
  justify-content: center;
  grid-template-areas:
    'tabs menu'
    'content content';
  grid-gap: 30px;
  @media (max-width: ${Media.phone}) {
    grid-template-columns: 1fr;
    grid-template-rows:
      ${Dimensions.navigationHeight} minmax(min-content, max-content)
      ${Dimensions.navigationHeight};
    grid-gap: 15px;
    grid-template-areas:
      'tabs'
      'content'
      'menu';
  }
`;

const Tabs = styled.div`
  grid-area: tabs;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
  @media (max-width: ${Media.phone}) {
    grid-auto-columns: 1fr;
    overflow-x: auto;
  }
`;

const Tab = styled(Button)`
  background: ${props => !props.active && 'transparent'};
  color: ${props => !props.active && props.theme.color};
`;

const Content = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
`;

const Menu = styled.div`
  grid-area: menu;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
`;

const tabs = [
  { name: 'general', type: 'GENERAL', value: 'Allmänt', Component: General },
  { name: 'camping', type: 'CAMPING', value: 'Camping', Component: Camping },
  { name: 'field', type: 'FIELD', value: 'Ytor', Component: Fields },
  { name: 'conference', type: 'CONFERENCE', value: 'Konferenser', Component: Conference },
  { name: 'item', type: 'ITEM', value: 'Artiklar', Component: Item },
  { name: 'locker', type: 'LOCKER', value: 'Omklädningsrum', Component: Locker }
];

const formatValues = values => {
  if (values.vat) values.vat = parseInt(values.vat, 10);
  if (values.basketTime) values.basketTime = parseInt(values.basketTime, 10);
  return values;
};

const Settings = () => {
  const settings = useSelector(state => state.settings);
  const tab = tabs.find(e => e.type === localStorage.getItem('settingsTab'));
  const [activeTab, setActiveTab] = useState(tab || tabs[0]);
  const [values, setValues] = useState(settings);
  const { request } = useUpdateSettings();

  const handleSaveClick = async e => {
    e.preventDefault();
    request({
      name: activeTab.name,
      value: formatValues(values)
    });
  };

  const handleClick = e => {
    localStorage.setItem('settingsTab', e.type);
    setActiveTab(e);
  };

  return (
    <Main>
      <Tabs>
        {tabs.map(e => (
          <Tab key={e.type} value={e.value} active={activeTab === e} onClick={() => handleClick(e)} />
        ))}
      </Tabs>
      <Content>
        <h3>{activeTab.value}</h3>
        <Container style={{ padding: '15px' }}>
          <activeTab.Component settings={settings[activeTab.name]} setValues={setValues} />
        </Container>
      </Content>
      <Menu>
        <Button value="Spara" onClick={handleSaveClick} />
      </Menu>
    </Main>
  );
};

export default Settings;
