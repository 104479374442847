import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dimensions, ZIndex } from '../../Resources/Stylesheets/Variables';

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  ${props => {
    if (props.top && props.left) return `bottom: ${props.height}px; right: ${props.width}px;`;
    if (props.top && props.right) return `bottom: ${props.height}px; left: ${props.width}px;`;
    if (props.top) return `bottom: ${props.height}px;`;
    if (props.bottom && props.left) return `top: ${props.height}px; right: ${props.width}px;`;
    if (props.bottom && props.right) return `top: ${props.height}px; left: ${props.width}px;`;
    if (props.bottom) return `top: ${props.height}px;`;
    if (props.left) return `right: ${props.width}px; transform: translateY(40%);`;
    if (props.right) return `left: ${props.width}px; transform: translateY(40%);`;
    return null;
  }};
  padding: 10px 20px;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.cornerRadius};
  transition: 300ms ease-out;
  display: ${props => (props.hover ? 'grid' : 'none')};
  z-index: ${ZIndex.dropdown};
`;

const Tooltip = ({ text, component, top, left, right, bottom }) => {
  const [hover, setHover] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      if (width === 0) {
        setWidth(ref.current.clientWidth + 3);
      }
      if (height === 0) {
        setHeight(ref.current.clientHeight + 3);
      }
    }
  }, [width, height]);

  return (
    <Wrapper ref={ref} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Container top={top} left={left} right={right} bottom={bottom} hover={hover} width={width} height={height}>
        <p>{text}</p>
      </Container>
      {component}
    </Wrapper>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
  top: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  bottom: PropTypes.bool
};

Tooltip.defaultProps = {
  top: false,
  left: false,
  right: false,
  bottom: false
};

export default Tooltip;
