import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useForm from '../../../Hooks/useForm';
import Input from '../../../Components/Forms/Input';
import { editBookingReferenceProduct } from '../../../Library/api';

export const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 500px;
  grid-gap: 10px;
`;

const EditGroupedBookedProduct = ({ formID, formData, close, refetch }) => {
  const { values, handleChange } = useForm({
    comment: formData.spec && formData.spec.comment
  });

  const handleSubmit = async e => {
    e.preventDefault();
    await Promise.all(
      formData.map(e1 => {
        const body = e1;
        body.spec.comment = values.comment === '' ? null : values.comment;
        editBookingReferenceProduct(body);
        return null;
      })
    );
    close();
    refetch();
  };

  return (
    <Form id={formID} onSubmit={handleSubmit}>
      <Input type="text" label="Kommentar" name="comment" value={values.comment} onChange={handleChange} />
    </Form>
  );
};

EditGroupedBookedProduct.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.bool, PropTypes.number])
    .isRequired,
  close: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

export default EditGroupedBookedProduct;
