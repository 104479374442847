import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts } from '../../Resources/Stylesheets/Variables';

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 50px;
  border-radius: 5px;
  color: ${props => props.theme.color};
  height: 50px;
  align-items: center;
  padding: 0 10px;
`;

const Check = styled.input`
  outline: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: ${Fonts.sizeTiny};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  color: ${props => props.theme.color};
  cursor: pointer;
`;

const Label = styled.label`
  font-size: ${Fonts.sizeTiny};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  transition: 300ms ease-out;
  line-height: 50px;
`;

const Checkbox = forwardRef(({ name, label, value, onChange }, ref) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <Check ref={ref} type="checkbox" name={name} checked={value} onChange={onChange} />
    </Container>
  );
});

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
  value: false
};

export default Checkbox;
