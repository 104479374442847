import {
  ADD_BASKET_ITEM,
  UPDATE_BASKET_ITEM,
  DELETE_BASKET,
  DEL_BASKET_ITEM,
  INIT_BASKET,
  DEL_BASKET_ITEM_BY_PROD_ID,
  UPDATE_BASKET_ITEM_VAT
} from './actions';

const basket = (state = [], action) => {
  switch (action.type) {
    case INIT_BASKET: {
      return action.payload;
    }

    case ADD_BASKET_ITEM: {
      const item = action.payload;
      const found = state.find(e => e.id === item[0].id);
      if (found) return state;
      const parsed = item.map(e => (e.id === item.id ? item : e));
      return [...state, ...parsed];
    }

    case UPDATE_BASKET_ITEM_VAT: {
      const { vat, type } = action.payload;
      return state.map(e => (e.type === type ? { ...e, vat } : e));
    }

    case UPDATE_BASKET_ITEM: {
      const item = action.payload;
      return state.map(e => (e.id === item.id ? { ...e, ...item } : { ...e }));
    }

    case DEL_BASKET_ITEM: {
      const ids = action.payload;
      return state.filter(x => !ids.find(y => x.id === y.id));
    }

    case DEL_BASKET_ITEM_BY_PROD_ID: {
      const { id } = action.payload;
      return state.filter(x => id !== x.productID);
    }

    case DELETE_BASKET:
      return [];
    default:
      return state;
  }
};

export default basket;
