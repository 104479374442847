import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider, useSelector } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import UnauthRouter from './UnauthRouter';
import { loadSession } from './Reducers/Session/actions';
import rootReducer from './Reducers';
import App from './App/App';
import GlobalStyle from './Resources/Stylesheets/Site';
import Errors from './App/Components/Errors/Errors';
import WS from './WS/WS';

const store = createStore(rootReducer, applyMiddleware(thunk));
const { dispatch } = store;

const Index = () => {
  const { sessionToken, theme } = useSelector(state => state.session);

  return (
    <>
      <GlobalStyle theme={theme} />
      <ThemeProvider theme={theme}>
        <>
          {sessionToken ? (
            <>
              <WS />
              <App />
            </>
          ) : (
            <UnauthRouter />
          )}
          <Errors />
        </>
      </ThemeProvider>
    </>
  );
};

const Wrapper = () => {
  dispatch(loadSession());
  return (
    <Provider store={store}>
      <Index />
    </Provider>
  );
};

ReactDOM.render(<Wrapper />, document.getElementById('root'));
