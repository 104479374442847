import React from 'react';
import PropTypes from 'prop-types';
import { useDeleteMark } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';

const DeleteMark = ({ formID, formData, close }) => {
  const { request } = useAfterSuccess(useDeleteMark(), close);

  const onSubmit = e => {
    e.preventDefault();
    request({ id: formData.id });
  };

  return <form id={formID} onSubmit={onSubmit} />;
};

DeleteMark.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.shape({})])
  ).isRequired,
  close: PropTypes.func.isRequired
};

export default DeleteMark;
