import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Fonts, Dimensions, Media } from '../../Resources/Stylesheets/Variables';
import { useComponentVisible } from '../../Hooks';
import { Header, Icon, Label, Text } from './Select';

const selectHeight = '50px';
const itemHeight = '50px';

const Container = styled.div`
  user-select: none;
  position: relative;
  padding: 0 10px;
  background: ${props => props.theme.background};
  border: 2px solid ${props => props.theme.border};
  border-top-left-radius: ${Dimensions.cornerRadius};
  border-top-right-radius: ${Dimensions.cornerRadius};
  border-bottom-left-radius: ${props => (props.active ? 0 : Dimensions.cornerRadius)};
  border-bottom-right-radius: ${props => (props.active ? 0 : Dimensions.cornerRadius)};
  color: ${props => props.theme.color};
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr;
  grid-template-rows: ${selectHeight} 1fr;
  grid-template-areas: 'label' 'dropdown';
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  outline: none;
  width: 100%;
`;

const Dropdown = styled.div`
  background: ${props => props.theme.background};
  display: ${props => (props.active ? 'block' : 'none')};
  position: absolute;
  top: ${selectHeight};
  left: -2px;
  right: -2px;
  box-shadow: 1px 5px 5px -7px ${Colors.black};
  border-bottom-left-radius: ${Dimensions.cornerRadius};
  border-bottom-right-radius: ${Dimensions.cornerRadius};
  border-left: 2px solid ${props => props.theme.border};
  border-right: 2px solid ${props => props.theme.border};
  border-bottom: 2px solid ${props => props.theme.border};

  @media (max-width: ${Media.phone}) {
    grid-area: dropdown;
    top: -2px;
  }
`;

const Item = styled.div`
  line-height: ${itemHeight};
  padding: 0 10px;
  font-size: ${Fonts.sizeTiny};
  font-family: ${Fonts.text};
  background: ${props => (props.selected ? props.theme.main : 'none')};
  color: ${props => props.theme.color};
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  &:hover {
    background: ${props => props.theme.main};
  }
  &:last-of-type {
    border-bottom-left-radius: ${Dimensions.cornerRadius};
    border-bottom-right-radius: ${Dimensions.cornerRadius};
  }
`;

const Option = ({ name, label, options, value, onChange }) => {
  const { ref, visible, setVisible } = useComponentVisible(false);
  const [val, setVal] = useState(value || []);
  const labels = value && value.map(e => e.label).join(', ');

  useEffect(() => {
    if (visible) onChange({ target: { value, name } });
  }, [name, onChange, value, visible]);

  const hide = () => setVisible(v => !v);

  const clickHandler = (event, e) => {
    event.target.name = name;
    event.target.value = e.type;
    event.persist();

    setVal(v => {
      const found = !!v.find(({ type }) => type === e.type);
      let ret;
      if (found) {
        ret = v.filter(({ type }) => type !== e.type);
      } else {
        ret = [...v, { type: e.type, label: e.label }];
      }

      event.target.value = ret;
      onChange(event);

      return ret;
    });
  };

  return (
    <Container active={visible} ref={ref} tabIndex={0}>
      <Label active={visible} onClick={hide}>
        <Header active={(!visible && labels.length === 0) || (visible && labels.length > 0)}>{label}</Header>
        <Text>{!visible && labels}</Text>
        <Icon active={visible} className="icon-angle-down" />
      </Label>
      <Dropdown active={visible}>
        {options.map(e => (
          <Item
            key={e.type}
            selected={val.find(({ type }) => type === e.type)}
            onClick={event => clickHandler(event, e)}
          >
            {e.label}
            <i className={val.find(({ type }) => type === e.type) ? 'icon-cancel-circled' : ''} />
          </Item>
        ))}
      </Dropdown>
    </Container>
  );
};

Option.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired
};

Option.defaultProps = {
  value: null
};

export default Option;
