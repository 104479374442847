import React from 'react';
import PropTypes from 'prop-types';
import { useDeleteBookingReferenceProduct } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';
import { deleteBookingReferenceProduct } from '../../../Library/api';

const DeleteBookedProduct = ({ formID, formData, close, refetch }) => {
  const { request } = useAfterSuccess(useDeleteBookingReferenceProduct(), () => {
    close();
    refetch();
  });

  const handleSubmit = async e => {
    e.preventDefault();

    if (Array.isArray(formData)) {
      await Promise.all(formData.map(e1 => deleteBookingReferenceProduct({ id: e1.id })));
      close();
      refetch();
    } else {
      await request({ id: formData.id });
    }
  };

  return <form id={formID} onSubmit={handleSubmit} />;
};

DeleteBookedProduct.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.bool, PropTypes.number])
    .isRequired,
  close: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

export default DeleteBookedProduct;
