export const SET_USERS = 'SET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER = 'ADD_USER';
export const DEL_USER = 'DEL_USER';

export const setUsers = payload => ({
  type: SET_USERS,
  payload
});

export const updateUser = payload => ({
  type: UPDATE_USER,
  payload
});

export const addUser = payload => ({
  type: ADD_USER,
  payload
});

export const deleteUser = payload => ({
  type: DEL_USER,
  payload
});
