import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ZIndex } from '../../../Resources/Stylesheets/Variables';
import useClock from '../../../Hooks/useTimer';

const Container = styled.div`
  grid-row-start: ${props => (props.position === 0 ? 1 : Math.ceil(props.position * 12))};
  display: grid;
  grid-template-columns: 8px ${props => `${props.width}px`};
  grid-template-rows: 8px;
  z-index: ${ZIndex.dropdown};
  align-items: flex-start;
`;

const Dot = styled.div`
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background: ${props => props.theme.negative};
  transform: translateY(-4px);
`;

const Line = styled.div`
  height: 1px;
  background: ${props => props.theme.negative};
`;

const Timeline = forwardRef(({ width }, ref) => {
  const { hours, minutes } = useClock(new Date(), 1000 * 60 * 5);

  return (
    <Container ref={ref} position={`${hours}.${minutes}`} width={width}>
      <Dot />
      <Line />
    </Container>
  );
});

Timeline.propTypes = {
  width: PropTypes.number.isRequired
};

export default Timeline;
