import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container as Template } from '../../Styles/Styles';
import { Fonts } from '../../Resources/Stylesheets/Variables';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr 250px max-content;
  grid-template-areas: 'title . search extra';
  align-items: center;
`;

const Title = styled.h3`
  grid-area: title;
`;

const Search = styled(Template)`
  grid-area: search;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 40px;
  align-items: center;
  justify-items: center;
  p {
    color: ${props => props.theme.accent};
  }
  input {
    width: 100%;
    outline: none;
    appearance: none;
    border: none;
    background: none;
    font-family: ${Fonts.text};
    font-size: ${Fonts.sizeSmall};
    color: ${props => props.theme.color};
    &::placeholder {
      color: ${props => props.theme.color};
    }
  }
`;

const Extra = styled.div`
  grid-area: extra;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-gap: 15px;
  padding-left: 30px;
`;

const Header = ({ title, search, extra }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {search && (
        <Search>
          <p className="icon-search" />
          <input
            type="text"
            placeholder={`Sök ${title.toLowerCase()}...`}
            onChange={e => {
              search(e.target.value.trim().toLowerCase());
            }}
          />
        </Search>
      )}
      {extra && <Extra>{extra.map(e => e)}</Extra>}
    </Container>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  search: PropTypes.func,
  extra: PropTypes.arrayOf(PropTypes.element)
};

Header.defaultProps = {
  search: null,
  extra: null
};

export default Header;
