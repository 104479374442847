import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Cancel } from '../../Styles/Styles';
import { cap, exportCSVFile, groupBy } from '../../Library/Library';
import { addErr } from '../../Reducers/Error/actions';
import { BOOKING_TYPE, CAMPING, FIELD, FIELD_FULL_DAY, ITEM, LOCKER, PRODUCTS } from '../../Library/Variables';
import Option from '../Forms/Option';
import useForm from '../../Hooks/useForm';

const Container = styled.form`
  display: grid;
  grid-template-columns: minmax(250px, 300px) minmax(250px, 300px);
  grid-template-rows: max-content max-content max-content 1fr max-content;
  grid-gap: 30px;
  grid-template-areas: 'header header' 'filter filter' 'colHead colHead' 'scroll scroll' 'tools tools';
  padding: 30px 50px;
  height: 70vh;
`;

const Header = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

const Filters = styled.div`
  grid-area: filter;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  align-items: center;
  grid-gap: 30px;
`;

const ColumnHeaders = styled.div`
  grid-area: colHead;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  align-items: center;
  grid-gap: 30px;
`;

const ScrollColumn = styled.div`
  grid-area: scroll;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  align-items: center;
  grid-gap: 30px;
  overflow-y: auto;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
`;

const Tools = styled.div`
  grid-area: tools;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 15px;
`;

const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
};

const headers = {
  referenceID: 'Bokning',
  id: 'KundID',
  customer: 'Kund',
  name: 'Namn',
  startTime: 'Start',
  endTime: 'Slut',
  quantity: 'Antal',
  unit: 'Enhet',
  version: 'Andel',
  comment: 'Kommentar'
};

const csv = (items, customerID, customerName) =>
  items.map(e => ({
    referenceID: e.referenceID.toUpperCase(),
    id: customerID,
    customer: customerName,
    name: cap(e.name),
    startTime: e.startTime ? new Date(e.startTime).toLocaleDateString('sv-Sv', options) : '',
    endTime: e.endTime ? new Date(e.endTime).toLocaleDateString('sv-Sv', options) : '',
    quantity: e.quantity,
    unit: e.type === CAMPING || e.type === ITEM ? 'st' : `${e.spec.version === FIELD_FULL_DAY.type ? 'dag' : 'tim'}`,
    version: e.spec && e.type === FIELD ? BOOKING_TYPE.find(x => x.type === e.spec.version).label : '',
    comment: e.spec && (e.type === FIELD || e.type === LOCKER) && e.spec.comment !== null ? e.spec.comment : ''
  }));

const title = data =>
  `ibex-${data.customerID}-${new Date(data.from).toLocaleDateString('sv-Sv', options)}-${new Date(
    data.to
  ).toLocaleDateString('sv-Sv', options)}`;

const ListExport = ({ data, close }) => {
  const { values, handleChange } = useForm({ type: [] });
  const types = values.type && values.type.map(({ type }) => type);
  const filteredProducts = data && data.bookedProducts.filter(e => e && types.includes(e.type));
  const groupedProducts = Object.entries(groupBy(filteredProducts, 'productID')).map(([, value]) => value[0]);
  const dispatch = useDispatch();
  const handleSubmit = async e => {
    e.preventDefault();
    if (filteredProducts.length === 0) {
      dispatch(addErr('Det finns inga produkter att exportera.'));
      return;
    }
    exportCSVFile(headers, csv(filteredProducts, data.customerID, data.customerName), title(data));
  };
  return (
    <Container onSubmit={handleSubmit}>
      <Header>
        <h4>Skapa och exportera lista</h4>
      </Header>
      <Filters>
        <h4>Välj produkter att exportera</h4>
        <Option value={values.type} onChange={handleChange} name="type" label="Produkter" options={PRODUCTS} />
      </Filters>
      <ColumnHeaders>
        <h4>Följande produkter kommer exporteras</h4>
      </ColumnHeaders>
      <ScrollColumn>
        {groupedProducts.length === 0 && (
          <Row>
            <h5>Det finns inga artiklar att exportera.</h5>
          </Row>
        )}
        {groupedProducts.map(e => (
          <Row key={e.id}>
            <h5>{cap(e.name)}</h5>
          </Row>
        ))}
      </ScrollColumn>
      <Tools>
        <Cancel type="button" value="Avbryt" onClick={close} />
        <Button type="submit" value="Exportera" />
      </Tools>
    </Container>
  );
};

ListExport.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
    .isRequired,
  close: PropTypes.func.isRequired
};

export default ListExport;
