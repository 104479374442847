import React, { useReducer } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { cap, formatPrice } from '../../../Library/Library';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import { Avatar, Container as Template, Ripple } from '../../../Styles/Styles';
import { useEditProduct } from '../../../Library/Hooks';
import { ProductType } from '../../../Types/types';
import PopOverForm from '../../../Components/PopOver/PopOverForm';
import DeleteProduct from './DeleteProduct';
import EditProduct from './EditProduct';
import { CAMPING, CONFERENCE, FIELD, HOUSING_TYPE, ITEM, LOCKER } from '../../../Library/Variables';
import PopOver from '../../../Components/PopOver/PopOver';
import BLConnect from './BLConnect';

const Container = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 10px;
`;

const Cells = styled(Template)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: max-content;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 10px;
  padding-left: 15px;
`;

const Cell = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: flex-start;
  align-items: center;
`;

const Manage = styled.div`
  display: grid;
  grid-template-columns: 70px 70px 70px 70px;
  grid-template-rows: 70px;
  justify-content: flex-end;
`;

const Edit = styled(Ripple)`
  font-size: ${Fonts.sizeRegular};
  color: ${props => props.theme.color};
  cursor: pointer;
  border-radius: 0;
  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const Connect = styled(Edit)`
  color: ${props => (props.active ? props.theme.positive : props.theme.negative)};
`;

const Delete = styled(Edit)`
  &:hover {
    color: ${props => props.theme.negative};
  }
`;

const Lock = styled(Edit)`
  color: ${props => (props.active ? props.theme.positive : props.theme.negative)};
`;

const DELETE = 'DELETE';
const EDIT = 'EDIT';
const CONNECT = 'CONNECT';
const IMAGE = 'IMAGE';

const reducer = (state, action) => {
  switch (action.type) {
    case EDIT:
      return { ...state, editing: !state.editing };
    case DELETE:
      return { ...state, deleting: !state.deleting };
    case CONNECT:
      return { ...state, connecting: !state.connecting };
    case IMAGE:
      return { ...state, image: action.payload };
    default:
      throw new Error('Invalid reducer state');
  }
};

const Product = ({ data, format, formatInput, formatSubmit, type }) => {
  const [{ editing, deleting, image, connecting }, dispatch] = useReducer(reducer, {
    deleting: false,
    editing: false,
    connecting: false,
    image: data.image
  });
  const settings = useSelector(state => state.settings);
  const { vat } = settings[type.toLowerCase()];
  const { spec } = data;
  const columns = format(spec, vat);
  const editProduct = useEditProduct();
  const showEdit = () => dispatch({ type: EDIT });
  const showDelete = () => dispatch({ type: DELETE });
  const showConnect = () => dispatch({ type: CONNECT });
  const lockProduct = () => editProduct.request({ ...data, active: !data.active });
  const setImage = payload => dispatch({ type: IMAGE, payload });
  const deleteImage = () => dispatch({ type: IMAGE, payload: null });
  const showPrice = type === CAMPING || type === ITEM;
  const initials = data.name.charAt(0);
  const version = data.type === CAMPING && HOUSING_TYPE.find(e => e.type === data.spec.type);

  data.image = image;
  return (
    <Container>
      <Avatar image={image} initials={initials} size="50px" />
      <Cells>
        <Cell>
          <h4>{cap(data.name)}</h4>
          {version && <i>{version.label}</i>}
        </Cell>
        {showPrice && (
          <Cell>
            <h4>{`${formatPrice(data.price, vat)} kr`}</h4>
          </Cell>
        )}
        {data.type === ITEM && (
          <Cell>
            <p>{data.description}</p>
          </Cell>
        )}
        {columns}
        <Manage>
          <Connect
            value={<i className={data.blID ? 'icon-link' : 'icon-unlink'} />}
            active={data.blID}
            onClick={showConnect}
          />
          <Lock
            value={<i className={data.active ? 'icon-lock-open' : 'icon-lock'} />}
            active={data.active}
            onClick={lockProduct}
          />
          <Edit value={<i className="icon-edit" />} onClick={showEdit} />
          <Delete value={<i className="icon-trash" />} onClick={showDelete} />
        </Manage>
      </Cells>
      {connecting && <PopOver data={data} Content={BLConnect} close={showConnect} />}
      {editing && (
        <PopOverForm
          data={{
            id: data.id,
            name: data.name,
            image: data.image,
            initials,
            title: 'Redigerar produkt',
            text: `Dessa ändringar gäller endast för framtida bokningar. Aktiva bokningar innehållande ${cap(
              data.name
            )} kommer fortfarande gälla.`,
            submitLabel: 'Spara'
          }}
          formatInput={formatInput}
          formatSubmit={formatSubmit}
          Form={EditProduct}
          formID="EditProduct"
          formData={data}
          onCancel={showEdit}
          editImage={setImage}
          deleteImage={deleteImage}
          productType={type}
        />
      )}
      {deleting && (
        <PopOverForm
          data={{
            id: data.id,
            name: data.name,
            image: data.image,
            initials,
            title: 'Vill du verkligen ta bort denna produkt?',
            text: `Proukten går ej att ta bort om det finns bokningar på den. Du kan istället inaktivera den.`,
            submitLabel: 'Ta bort produkt'
          }}
          formatInput={formatInput}
          formatSubmit={formatSubmit}
          Form={DeleteProduct}
          formID="DeleteProduct"
          formData={data}
          onCancel={showDelete}
        />
      )}
    </Container>
  );
};

Product.propTypes = {
  data: PropTypes.shape(ProductType).isRequired,
  format: PropTypes.func.isRequired,
  formatInput: PropTypes.func.isRequired,
  formatSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOf([CAMPING, FIELD, LOCKER, ITEM, CONFERENCE]).isRequired
};

export default Product;
