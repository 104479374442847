import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Container as Template, Ripple } from '../../../Styles/Styles';
import { cap } from '../../../Library/Library';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import PopOverForm from '../../../Components/PopOver/PopOverForm';
import CreateMark from './CreateMark';
import PopOver from '../../../Components/PopOver/PopOver';
import DeleteMark from './DeleteMark';

const Container = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 70px;
  grid-gap: 10px;
  align-items: center;
`;

const Columns = styled(Template)`
  display: grid;
  grid-template-columns: repeat(${props => props.length}, 1fr) 70px 70px;
  grid-template-rows: 70px;
  grid-gap: 10px;
  padding-left: 15px;
  align-items: center;
`;

const Column = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  align-items: center;
`;

const Edit = styled(Ripple)`
  font-size: ${Fonts.sizeRegular};
  color: ${props => props.theme.color};
  cursor: pointer;
  border-radius: 0;
  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const Delete = styled(Ripple)`
  &:hover {
    color: ${props => props.theme.negative};
  }
`;

const EDIT = 'EDIT';
const DELETE = 'DELETE';

const reducer = (state, action) => {
  switch (action.type) {
    case EDIT:
      return { ...state, editing: !state.editing };
    case DELETE:
      return { ...state, deleting: !state.deleting };
    default:
      throw new Error('Invalid reducer state');
  }
};

const Mark = ({ data }) => {
  const { name, value } = data;
  const { split, percent } = value;
  const [{ editing, deleting }, dispatch] = useReducer(reducer, {
    editing: false,
    deleting: false
  });

  const showEdit = () => dispatch({ type: EDIT });
  const showDelete = () => dispatch({ type: DELETE });

  return (
    <Container>
      <Avatar initials={name.charAt(0)} size="46px" />
      <Columns length={2 + split.length}>
        <Column>
          <h4>{cap(name)}</h4>
        </Column>
        <Column>
          <p>Kund</p>
          <h4>{`${percent} %`}</h4>
        </Column>
        {split.map(e => (
          <Column key={e.customerID}>
            <p>{e.customerID.toUpperCase()}</p>
            <h4>{`${e.percent} %`}</h4>
          </Column>
        ))}
        <Edit value={<i className="icon-edit" />} onClick={showEdit} />
        <Delete value={<i className="icon-trash" />} onClick={showDelete} />
      </Columns>
      {editing && (
        <PopOver
          data={{
            title: 'Redigerar märkning',
            submitLabel: 'Spara',
            mark: data
          }}
          Content={CreateMark}
          close={showEdit}
        />
      )}
      {deleting && (
        <PopOverForm
          data={{
            id: data.id,
            name: data.name,
            initials: data.name.charAt(0),
            title: 'Vill du verkligen ta bort denna märkning?',
            text: '',
            submitLabel: 'Radera'
          }}
          Form={DeleteMark}
          formID="DeleteMark"
          formData={{ id: data.id }}
          onCancel={showDelete}
        />
      )}
    </Container>
  );
};

Mark.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.shape({
      percent: PropTypes.number,
      split: PropTypes.arrayOf(
        PropTypes.shape({
          customerNr: PropTypes.number,
          percent: PropTypes.number
        })
      )
    })
  }).isRequired
};

export default Mark;
