import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Input from '../../../Components/Forms/Input';
import { Regexp } from '../../../Resources/Stylesheets/Variables';
import useForm from '../../../Hooks/useForm';
import { useForgotPassword } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';

const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px;
  grid-gap: 10px;
`;

const ForceUser = ({ formID, formData, close }) => {
  const { values, handleChange } = useForm({ id: formData.id });
  const { request } = useAfterSuccess(useForgotPassword(), close);

  const onSubmit = async e => {
    e.preventDefault();
    request(values);
  };

  return (
    <Form id={formID} onSubmit={onSubmit}>
      <Input
        type="password"
        label="Nytt lösenord"
        name="password"
        value={values.password}
        onChange={handleChange}
        pattern={Regexp.password}
        minLength={8}
        maxLength={256}
        required
      />
    </Form>
  );
};

ForceUser.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.instanceOf(Date)])
  ).isRequired,
  close: PropTypes.func.isRequired
};

export default ForceUser;
