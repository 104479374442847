import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Avatar } from '../../../Styles/Styles';
import { calcTotPrice, cap, formatDate, formatPrice } from '../../../Library/Library';

const Container = styled.div`
  display: grid;
  grid-template-columns: 55px 1fr;
  grid-template-rows: 55px;
  align-items: center;
  color: ${props => props.theme.color};
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Item = ({ data }) => (
  <Container>
    <Avatar image={data.image} initials={data.name.charAt(0)} size="35px" />
    <div>
      <h5>{cap(data.name)}</h5>
      <Details>
        <i>{data.startTime ? formatDate(data.startTime) : formatDate(new Date())}</i>
        <i>{`${formatPrice(calcTotPrice(data))} kr`}</i>
      </Details>
    </div>
  </Container>
);

Item.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    itemType: PropTypes.string,
    price: PropTypes.number,
    discount: PropTypes.number,
    housingID: PropTypes.number,
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    userID: PropTypes.number,
    image: PropTypes.string
  }).isRequired
};

export default Item;
