import React, { useState } from 'react';
import styled from 'styled-components';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Main, Button, Grid } from '../../Styles/Styles';
import useForm from '../../Hooks/useForm';
import Header from '../../Components/Header/Header';
import DatePicker from '../../Components/Forms/DatePicker';
import { formatDate, getBookingName } from '../../Library/Library';
import { Media } from '../../Resources/Stylesheets/Variables';
import Input from '../../Components/Forms/Input';
import Customer from './Components/Customer';
import Donut from '../../Components/Statistics/Donut';
import { useGetCompany, useGetCustomerBookingReferences } from '../../Library/Hooks';
import PopOver from '../../Components/PopOver/PopOver';
import InvoiceExport from '../../Components/InvoiceExport/InvoiceExport';
import ListExport from '../../Components/ListExport/ListExport';
import Booking from './Components/Booking';
import { COMPANY } from '../../Library/Variables';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(${Media.phone}, 1175px);
  grid-auto-rows: max-content;
  justify-content: center;
  grid-gap: 20px;
  margin-bottom: 30px;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(3, 275px) max-content;
  grid-auto-flow: column;
  grid-gap: 25px;
  align-items: center;
`;

const Bookings = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

const selectMarks = state => state.marks;

const selectCustomerMark = customerID =>
  createSelector(selectMarks, marks => {
    if (!customerID) return null;

    const x = marks.filter(e => e.value.split.find(e1 => e1.customerID === customerID));
    const y = marks.filter(e => e.customers.find(e1 => e1.customerID === customerID));

    if (x.length > 0) {
      const { type, value } = x[0];
      const { percent } = value.split[0];
      return { type, percent, remove: true };
    }

    if (y.length > 0) {
      const { type, value } = y[0];
      const { percent } = value;
      return { type, percent, remove: false };
    }

    return null;
  });

const Economics = () => {
  const [listExport, showListExport] = useState(false);
  const [invoiceExport, showInvoiceExport] = useState(false);
  const { values, handleChange } = useForm({
    customerID: localStorage.getItem('ecoCustomerID') || '',
    from: localStorage.getItem('ecoFrom') ? new Date(localStorage.getItem('ecoFrom')) : new Date(),
    to: localStorage.getItem('ecoTo') ? new Date(localStorage.getItem('ecoTo')) : new Date()
  });
  const [customerID, setCustomerID] = useState(null);

  const mark = useSelector(selectCustomerMark(customerID));

  const bookingReferences = useGetCustomerBookingReferences();
  const getCustomer = useGetCompany();

  const handleSubmit = async e => {
    e.preventDefault();
    setCustomerID(values.customerID);
    localStorage.setItem('ecoCustomerID', values.customerID);
    localStorage.setItem('ecoFrom', values.from);
    localStorage.setItem('ecoTo', values.to);
    getCustomer.request({ id: values.customerID });
    bookingReferences.request({
      customerID: values.customerID,
      from: values.from,
      to: values.to
    });
  };

  const bookings = bookingReferences.data && [...bookingReferences.data.booked, ...bookingReferences.data.marked];
  const customer = getCustomer.data;

  const items =
    bookings &&
    bookings.flatMap(e =>
      e.bookedProducts.map(x => {
        if (!e.markID) return { ...x, percent: 100, markPrice: x.price, discountPercentage: e.discount };
        const isInMark = mark && mark.type.includes(x.type);
        const markPrice = isInMark ? x.price * (mark.percent / 100) : x.price;
        if (!isInMark && mark && mark.remove) return null;
        return { ...x, price: markPrice, percent: isInMark ? mark.percent : 100, discountPercentage: e.discount };
      })
    );

  const noOfBookings = items && items.filter(e => e && e).length;
  const exportData = {
    customerID: values.customerID,
    customerName: customer && getBookingName(customer, COMPANY),
    markID: null,
    bookedProducts: items,
    from: values.from,
    to: values.to
  };

  const CSV = () => customer && bookings && <Button type="button" value="Lista" onClick={() => showListExport(true)} />;
  const Invoice = () =>
    customer && bookings && <Button type="button" value="Fakturaunderlag" onClick={() => showInvoiceExport(true)} />;
  const Fetch = () => <Button type="submit" form="EcoGet" value="Hämta" />;

  return (
    <Main>
      <Container>
        <Header title="Ekonomi" extra={[<Invoice key="invoice" />, <CSV key="export" />, <Fetch key="fetch" />]} />
        <p>Här kan ni välja en kund och se dess bokningar för ett givet intervall.</p>
        <Form id="EcoGet" onSubmit={handleSubmit}>
          <Input
            key="org"
            label="Kund ID"
            type="text"
            name="customerID"
            value={values.customerID}
            onChange={handleChange}
            required
          />
          <DatePicker label="Från" name="from" value={values.from} onChange={handleChange} disableAfter={values.to} />
          <DatePicker label="Till" name="to" value={values.to} onChange={handleChange} disableBefore={values.from} />
        </Form>
        {customer && <Customer data={customer} />}
        {customer && bookings && (
          <>
            <Header title="Sammanställning" />
            <Grid>
              <Donut title="Antal bokade produkter" value={noOfBookings} unit="st" />
            </Grid>
          </>
        )}
        {customer && bookings && (
          <Bookings>
            <Header
              title={`Visar bokade produkter från den ${formatDate(values.from)} till den ${formatDate(values.to)}`}
            />
            {items.length === 0 ? (
              <p>Inga bokningar hittades.</p>
            ) : (
              items.map(e => e && <Booking key={e.id} data={e} />)
            )}
          </Bookings>
        )}
      </Container>
      {listExport && <PopOver data={exportData} Content={ListExport} close={() => showListExport(false)} />}
      {invoiceExport && <PopOver data={exportData} Content={InvoiceExport} close={() => showInvoiceExport(false)} />}
    </Main>
  );
};

export default Economics;
