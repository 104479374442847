import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  ProductColumn,
  ProductDelete,
  ProductEdit,
  ProductLabel,
  ProductValue,
  SubProductColumns,
  SubProductContainer,
  SubProductWrapper
} from '../../../Styles/Styles';
import { calcTotPrice, calculateHours, formatDate, formatPrice, formatTimeInterval } from '../../../Library/Library';
import { BasketType } from '../../../Types/types';
import PopOverForm from '../../../Components/PopOver/PopOverForm';
import DeleteBookedProduct from './DeleteBookedProduct';
import EditBookedProduct from './EditBookedProduct';

const EDIT = 'EDIT';
const DELETE = 'DELETE';

const reducer = (state, action) => {
  switch (action.type) {
    case EDIT:
      return { ...state, editing: !state.editing };
    case DELETE:
      return { ...state, deleting: !state.deleting };
    default:
      throw new Error('Invalid reducer state');
  }
};

const SubProduct = ({ data, refetch }) => {
  const { vat, discount, addition } = data;
  const [{ editing, deleting }, dispatch] = useReducer(reducer, {
    editing: false,
    deleting: false
  });
  const showEdit = () => dispatch({ type: EDIT });
  const showDelete = () => dispatch({ type: DELETE });

  return (
    <>
      <SubProductContainer>
        <ProductColumn />
        <SubProductWrapper>
          <ProductColumn />
          <SubProductColumns columns={6}>
            <ProductColumn>
              <ProductLabel>{formatDate(data.startTime)}</ProductLabel>
              <ProductValue>{formatTimeInterval(data.startTime, data.endTime)}</ProductValue>
            </ProductColumn>
            <ProductColumn>
              <ProductLabel>Timmar</ProductLabel>
              <ProductValue>{`${calculateHours(data.startTime, data.endTime)} h`}</ProductValue>
            </ProductColumn>
            <ProductColumn>
              <ProductLabel>Kommentar</ProductLabel>
              <ProductValue>{data.spec.comment || ` `}</ProductValue>
            </ProductColumn>
            <ProductColumn>
              <ProductLabel>Ensk. Rabatt</ProductLabel>
              <ProductValue>{`${formatPrice(discount, vat)} kr`}</ProductValue>
            </ProductColumn>
            <ProductColumn />
            <ProductColumn>
              <ProductLabel>Moms</ProductLabel>
              <ProductValue>{`${formatPrice(vat)} kr`}</ProductValue>
            </ProductColumn>
            <ProductColumn>
              <ProductLabel>Tillägg</ProductLabel>
              <ProductValue>{`${formatPrice(addition, vat)} kr`}</ProductValue>
            </ProductColumn>
            <ProductColumn>
              <ProductLabel>Pris</ProductLabel>
              <ProductValue>{`${formatPrice(calcTotPrice(data))} kr`}</ProductValue>
            </ProductColumn>
            <ProductEdit value={<i className="icon-edit" />} onClick={showEdit} />
            <ProductDelete value={<i className="icon-trash" />} onClick={showDelete} />
          </SubProductColumns>
        </SubProductWrapper>
      </SubProductContainer>
      {editing && (
        <PopOverForm
          data={{
            id: data.id,
            name: data.name,
            image: data.image,
            initials: data.name.charAt(0),
            title: 'Redigerar produkt',
            text: '',
            submitLabel: 'Spara'
          }}
          Form={EditBookedProduct}
          formID="EditBookedProduct"
          formData={data}
          onCancel={showEdit}
          refetch={refetch}
        />
      )}
      {deleting && (
        <PopOverForm
          data={{
            id: data.id,
            name: data.name,
            image: data.image,
            initials: data.name.charAt(0),
            title: 'Vill du verkligen ta bort denna produkt från bokningen?',
            text: `Denna åtgärd går inte att ångra.`,
            submitLabel: 'Radera produkt'
          }}
          Form={DeleteBookedProduct}
          formID="DeleteBookedProduct"
          formData={data}
          onCancel={showDelete}
          refetch={refetch}
        />
      )}
    </>
  );
};

SubProduct.propTypes = {
  data: PropTypes.shape(BasketType).isRequired,
  refetch: PropTypes.func.isRequired
};

export default SubProduct;
