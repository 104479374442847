import { useState, useCallback } from 'react';

const useForm = initialState => {
  const [values, setValues] = useState(initialState || {});

  const updateValues = useCallback(newVal => {
    setValues(v => ({ ...v, ...newVal }));
  }, []);

  const deleteValue = useCallback(key => {
    setValues(v => {
      delete v[key];
      return { ...v };
    });
  }, []);

  const handleChange = useCallback(e => {
    if (e.persist) e.persist();
    const { value, type, checked, name } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setValues(v => ({ ...v, [name]: val }));
  }, []);

  return {
    handleChange,
    values,
    updateValues,
    deleteValue
  };
};

export default useForm;
