import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts, ZIndex } from '../../Resources/Stylesheets/Variables';
import { Main } from '../../Styles/Styles';

const Container = styled.div`
  z-index: ${ZIndex.dropdown};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${props => props.theme.color};
  background: ${props => props.theme.background};
  display: ${props => (props.active ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

const Symbol = styled.i`
  font-size: 4rem;
  animation: spin 700ms ease infinite;
  @keyframes spin {
    0% {
      transform: rotateZ(0);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

const Box = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
  p {
    font-size: ${Fonts.sizeSuperTiny};
    margin: 10px 0;
  }
`;

const Refresh = styled.button`
  background: transparent;
  outline: none;
  appearance: none;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  margin: 10px 0;
  color: ${props => props.theme.color};
`;

const Loader = ({ active, refresh, setRefresh }) => {
  return (
    <Container active={active}>
      {refresh ? (
        <Box>
          <Refresh className="icon-reload" onClick={setRefresh} />
          <h5>Något gick fel!</h5>
          <p>Kontrollera nätverksanslutningen och ladda om sidan.</p>
        </Box>
      ) : (
        <Symbol className="icon-fowlit" />
      )}
    </Container>
  );
};

Loader.propTypes = {
  active: PropTypes.bool,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

Loader.defaultProps = {
  active: true,
  refresh: false,
  setRefresh: () => {}
};

export const MainLoader = () => (
  <Main>
    <Loader />
  </Main>
);

export default Loader;
