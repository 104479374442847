import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from '../../../Components/Forms/Select';
import { USER_TYPE } from '../../../Library/Variables';
import useForm from '../../../Hooks/useForm';
import { useCreateUser } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';
import Input from '../../../Components/Forms/Input';
import { Regexp } from '../../../Resources/Stylesheets/Variables';

const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px 250px;
  grid-gap: 10px;
`;

const CreateUser = ({ formID, close }) => {
  const { values, handleChange } = useForm();
  const { request } = useAfterSuccess(useCreateUser(), close);

  const onSubmit = async e => {
    e.preventDefault();
    request(values);
  };

  return (
    <Form id={formID} onSubmit={onSubmit}>
      <Input
        onChange={handleChange}
        value={values.firstName}
        type="text"
        label="Förnamn"
        name="firstName"
        required
        pattern={Regexp.name}
        minLength={1}
        maxLength={50}
      />
      <Input
        onChange={handleChange}
        value={values.lastName}
        type="text"
        label="Efternamn"
        name="lastName"
        required
        pattern={Regexp.name}
        minLength={1}
        maxLength={50}
      />
      <Select onChange={handleChange} value={values.role} name="role" label="Roll" options={USER_TYPE} />
      <Input
        onChange={handleChange}
        value={values.username}
        type="text"
        label="Användarnamn"
        name="username"
        required
        pattern={Regexp.username}
        minLength={5}
        maxLength={30}
      />
      <Input
        onChange={handleChange}
        value={values.password}
        type="password"
        label="Nytt lösenord"
        name="password"
        required
        pattern={Regexp.password}
        minLength={8}
        maxLength={256}
      />
      <Input
        onChange={handleChange}
        value={values.confirmPassword}
        type="password"
        label="Bekräfta nytt lösenord"
        name="confirmPassword"
        required
        pattern={Regexp.password}
        minLength={8}
        maxLength={256}
      />
    </Form>
  );
};

CreateUser.propTypes = {
  formID: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default CreateUser;
