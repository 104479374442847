import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Avatar,
  ProductColumn,
  ProductColumns,
  ProductContainer,
  ProductDelete,
  ProductEdit,
  ProductLabel,
  ProductValue
} from '../../../Styles/Styles';
import {
  calcTotPrice,
  calculateHours,
  cap,
  formatDate,
  formatPrice,
  formatTimeInterval
} from '../../../Library/Library';
import { BOOKING_TYPE, CAMPING, CONFERENCE, FIELD, ITEM, LOCKER } from '../../../Library/Variables';
import { BookedProductType } from '../../../Types/types';
import { getName } from '../../../Reducers/Products';
import PopOverForm from '../../../Components/PopOver/PopOverForm';
import DeleteBookedProduct from './DeleteBookedProduct';
import EditBookedProduct from './EditBookedProduct';

const camping = data => [
  <ProductColumn key={1}>
    <ProductLabel>{formatDate(data.startTime)}</ProductLabel>
    <ProductValue>{formatDate(data.endTime)}</ProductValue>
  </ProductColumn>,
  <ProductColumn key={2}>
    <ProductLabel>Nätter</ProductLabel>
    <ProductValue>{`${data.quantity} st`}</ProductValue>
  </ProductColumn>,
  <ProductColumn key={3}>
    <ProductLabel>Gäster</ProductLabel>
    <ProductValue>{`${data.spec.guests} st`}</ProductValue>
  </ProductColumn>
];

const item = data => [
  <ProductColumn key={1}>
    <ProductLabel>Antal</ProductLabel>
    <ProductValue>{`${data.quantity} st`}</ProductValue>
  </ProductColumn>
];

const hourBased = data => [
  <ProductColumn key={1}>
    <ProductLabel>{formatDate(data.startTime)}</ProductLabel>
    <ProductValue>{formatTimeInterval(data.startTime, data.endTime)}</ProductValue>
  </ProductColumn>,
  <ProductColumn key={2}>
    <ProductLabel>Timmar</ProductLabel>
    <ProductValue>{`${calculateHours(data.startTime, data.endTime)} h`}</ProductValue>
  </ProductColumn>,
  <ProductColumn key={3}>
    <ProductLabel>Kommentar</ProductLabel>
    <ProductValue>{data.spec.comment || ` `}</ProductValue>
  </ProductColumn>
];

const getColumns = data => {
  switch (data.type) {
    case CAMPING:
      return camping(data);
    case ITEM:
      return item(data);
    case FIELD:
      return hourBased(data);
    case LOCKER:
      return hourBased(data);
    case CONFERENCE:
      return hourBased(data);
    default:
      throw Error('Invalid type');
  }
};

const EDIT = 'EDIT';
const DELETE = 'DELETE';

const reducer = (state, action) => {
  switch (action.type) {
    case EDIT:
      return { ...state, editing: !state.editing };
    case DELETE:
      return { ...state, deleting: !state.deleting };
    default:
      throw new Error('Invalid reducer state');
  }
};

const Product = ({ data, refetch }) => {
  const { productID, vat, name, type, discount, addition } = data;
  const image = useSelector(state => {
    const p = state.products[getName(type)].find(e => e.id === productID);
    return p ? p.image : null;
  });
  const [{ editing, deleting }, dispatch] = useReducer(reducer, {
    editing: false,
    deleting: false
  });

  const showEdit = () => dispatch({ type: EDIT });
  const showDelete = () => dispatch({ type: DELETE });

  const columns = getColumns(data);
  const version = data.type === FIELD && BOOKING_TYPE.find(e => e.type === data.spec.version);
  return (
    <ProductContainer>
      <Avatar image={image} initials={name.charAt(0)} size="46px" />
      <ProductColumns columns={6}>
        <ProductColumn>
          <ProductValue>{cap(name)}</ProductValue>
          {version && <ProductLabel>{version.label}</ProductLabel>}
        </ProductColumn>
        {columns}
        {columns.length <= 3 &&
          [...Array(3 - columns.length).keys()].map((_, i) => <ProductColumn key={i.toString()} />)}
        <ProductColumn>
          <ProductLabel>Ensk. Rabatt</ProductLabel>
          <ProductValue>{`${formatPrice(discount, vat)} kr`}</ProductValue>
        </ProductColumn>
        <ProductColumn />
        <ProductColumn>
          <ProductLabel>Moms</ProductLabel>
          <ProductValue>{`${vat} %`}</ProductValue>
        </ProductColumn>
        <ProductColumn>
          <ProductLabel>Tillägg</ProductLabel>
          <ProductValue>{`${formatPrice(addition, vat)} kr`}</ProductValue>
        </ProductColumn>
        <ProductColumn>
          <ProductLabel>Pris</ProductLabel>
          <ProductValue>{`${formatPrice(calcTotPrice(data))} kr`}</ProductValue>
        </ProductColumn>
        <ProductEdit value={<i className="icon-edit" />} onClick={showEdit} />
        <ProductDelete value={<i className="icon-trash" />} onClick={showDelete} />
      </ProductColumns>
      {editing && (
        <PopOverForm
          data={{
            id: data.id,
            name: data.name,
            image: data.image,
            initials: data.name.charAt(0),
            title: 'Redigerar produkt',
            text: '',
            submitLabel: 'Spara'
          }}
          Form={EditBookedProduct}
          formID="EditBookedProduct"
          formData={data}
          onCancel={showEdit}
          refetch={refetch}
        />
      )}
      {deleting && (
        <PopOverForm
          data={{
            id: data.id,
            name: data.name,
            image: data.image,
            initials: data.name.charAt(0),
            title: 'Vill du verkligen ta bort denna produkt från bokningen?',
            text: `Denna åtgärd går inte att ångra.`,
            submitLabel: 'Radera produkt'
          }}
          Form={DeleteBookedProduct}
          formID="DeleteBookedProduct"
          formData={data}
          onCancel={showDelete}
          refetch={refetch}
        />
      )}
    </ProductContainer>
  );
};

Product.propTypes = {
  data: PropTypes.shape(BookedProductType).isRequired,
  refetch: PropTypes.func.isRequired
};

export default Product;
