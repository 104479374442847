import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEditBookingReferenceProduct, useGetOccupiedProducts } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';
import useForm from '../../../Hooks/useForm';
import DatePicker from '../../../Components/Forms/DatePicker';
import Input from '../../../Components/Forms/Input';
import { CAMPING, ITEM } from '../../../Library/Variables';
import { formatPrice, getDateInterval, preparePriceForRequest } from '../../../Library/Library';
import Price from '../../../Components/Forms/Price';

export const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px 250px;
  grid-gap: 10px;
`;

const getCampingInputs = (values, handleChange, occupiedDates, checkInSplit, checkOutSplit, invalidInterval) => (
  <>
    <DatePicker
      label="Från"
      name="startTime"
      value={values.startTime}
      onChange={handleChange}
      disabledDates={occupiedDates}
      disableAfter={values.endTime}
      hour={parseInt(checkInSplit[0], 10)}
      minute={parseInt(checkInSplit[1], 10)}
      invalid={invalidInterval}
    />
    <DatePicker
      label="Till"
      name="endTime"
      value={values.endTime}
      onChange={handleChange}
      disabledDates={occupiedDates}
      disableBefore={values.startTime}
      hour={parseInt(checkOutSplit[0], 10)}
      minutes={parseInt(checkOutSplit[1], 10)}
      invalid={invalidInterval}
    />
    <Input
      label="Moms"
      type="text"
      name="vat"
      value={values.vat}
      onChange={handleChange}
      required
      minLength={1}
      maxLength={3}
    />
    <Input label="Gäster" type="text" name="guests" value={values.guests} onChange={handleChange} required />
    <Input label="Rabatt" type="text" name="discount" value={values.discount} onChange={handleChange} />
    <Input label="Tillägg" type="text" name="addition" value={values.addition} onChange={handleChange} />
  </>
);

const getProductInputs = (values, handleChange) => (
  <>
    <Input
      label="Moms"
      type="text"
      name="vat"
      value={values.vat}
      onChange={handleChange}
      required
      pattern="[0-9]+"
      minLength={1}
      maxLength={3}
    />
    <Price
      label="Rabatt"
      type="text"
      name="discount"
      value={values.discount}
      onChange={handleChange}
      vat={values.vat}
    />
    <Price
      label="Tillägg"
      type="text"
      name="addition"
      value={values.addition}
      onChange={handleChange}
      vat={values.vat}
    />
    {values.type === ITEM && (
      <Input label="Antal" type="text" name="quantity" value={values.quantity} onChange={handleChange} />
    )}
    <Input label="Kommentar" type="text" name="comment" value={values.comment} onChange={handleChange} />
  </>
);

const EditBookedProduct = ({ formID, formData, close, refetch }) => {
  const { values, handleChange } = useForm({
    ...formData,
    startTime: formData.startTime && new Date(formData.startTime),
    endTime: formData.endTime && new Date(formData.endTime),
    vat: formData.vat,
    discount: formatPrice(formData.discount, formData.vat),
    addition: formatPrice(formData.addition, formData.vat),
    guests: formData.spec && formData.spec.guests,
    comment: formData.spec && formData.spec.comment
  });
  const [invalidInterval, setInvalidInterval] = useState(false);
  const camping = useSelector(state => state.settings.camping);
  const checkInSplit = camping.checkIn.split(':');
  const checkOutSplit = camping.checkOut.split(':');
  const { request: edit } = useAfterSuccess(useEditBookingReferenceProduct(), () => {
    close();
    refetch();
  });
  const { request: getOccupiedDates, data: occupied } = useGetOccupiedProducts();
  const [occupiedDates, setOccupiedDates] = useState(null);

  useEffect(() => {
    if (occupied) {
      setOccupiedDates([...occupied.basket, ...occupied.bookings].filter(e => e.referenceID !== formData.referenceID));
    }
  }, [formData.referenceID, occupied]);

  const sortDates = (x, y) => {
    if (x > y) return 1;
    if (x === 0) return 0;
    return -1;
  };

  useEffect(() => {
    if (occupiedDates) {
      const i = getDateInterval(values.startTime, values.endTime);
      const o = occupiedDates.flatMap(e => getDateInterval(e.startTime, e.endTime)).sort(sortDates);
      setInvalidInterval(o.some(e => i.includes(e)));
    }
  }, [occupiedDates, values.endTime, values.startTime]);

  useEffect(() => {
    const firstDayOfMonth = d => new Date(d.getFullYear(), d.getMonth(), 1);
    const lastDayOfMonth = d => new Date(d.getFullYear(), d.getMonth() + 1, 0);

    if (values.startTime && values.endTime)
      getOccupiedDates({
        id: values.productID,
        type: values.type,
        startTime: firstDayOfMonth(values.startTime),
        endTime: lastDayOfMonth(values.endTime)
      });
  }, [getOccupiedDates, values.endTime, values.id, values.productID, values.startTime, values.type]);

  const inputs =
    formData.type === CAMPING
      ? getCampingInputs(values, handleChange, occupiedDates, checkInSplit, checkOutSplit, invalidInterval)
      : getProductInputs(values, handleChange);

  const handleSubmit = e => {
    e.preventDefault();
    edit({
      ...formData,
      startTime: values.startTime,
      endTime: values.endTime,
      vat: parseInt(values.vat, 10),
      discount: preparePriceForRequest(values.discount, values.vat),
      addition: preparePriceForRequest(values.addition, values.vat),
      quantity: parseInt(values.quantity, 10),
      spec: {
        ...(formData.spec || {}),
        guests: parseInt(values.guests, 10),
        comment: values.comment ? values.comment : null
      }
    });
  };

  return (
    <Form id={formID} onSubmit={handleSubmit}>
      {inputs}
    </Form>
  );
};

EditBookedProduct.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.bool, PropTypes.number])
    .isRequired,
  close: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

export default EditBookedProduct;
