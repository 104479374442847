import { createGlobalStyle } from 'styled-components';
import { Colors, Fonts } from './Variables';

import '../Fonts/Fontello/css/fontello.css';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Roboto+Mono:300,400,500,700|Roboto:300,400,500,700&display=swap');

body {
    margin: 0;
    padding: 0;
}

i {
    margin: 0;
    font-style: normal;
    font-family: ${Fonts.text};
    font-weight: ${Fonts.weightRegular};
    font-size: ${Fonts.sizeSuperTiny};
}

p {
    margin: 0;
    font-family: ${Fonts.text};
    font-weight: ${Fonts.weightRegular};
    font-size: ${Fonts.sizeTiny};
}

h1 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightSemiBold};
    font-size: ${Fonts.sizeXLarge};
}

h2 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightSemiBold};
    font-size: ${Fonts.sizeLarge};
}

h3 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightSemiBold};
    font-size: ${Fonts.sizeRegular};
}

h4 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightSemiBold};
    font-size: ${Fonts.sizeSmall};
}

h5 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightSemiBold};
    font-size: ${Fonts.sizeTiny};
}

h6 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightSemiBold};
    font-size: ${Fonts.sizeSuperTiny};
}

a {
    margin: 0;
    text-decoration: none;
    font-family: ${Fonts.text};
    font-weight: ${Fonts.weightRegular};
    font-size: ${Fonts.sizeTiny};
    color: ${Colors.black};
}
`;

export default GlobalStyle;
