import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Main as MainTemp } from '../../Styles/Styles';
import { Media } from '../../Resources/Stylesheets/Variables';
import Header from '../../Components/Header/Header';
import { cap, formatPrice } from '../../Library/Library';
import Card from '../../Components/Card/Card';
import ActionButton from '../Camping/Components/ActionButton';

const Main = styled(MainTemp)`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 30px;
`;

const Container = styled.div`
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-template-rows: repeat(auto-fill, 350px);
  grid-auto-columns: 225px;
  grid-auto-rows: 325px;
  grid-gap: 30px;
  @media (max-width: ${Media.phone}) {
    grid-template-columns: repeat(auto-fill, 100%);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-auto-columns: 100%;
    grid-auto-rows: 1fr;
  }
`;

const Items = () => {
  const items = useSelector(state => state.products.items);
  const vat = useSelector(state => state.settings.item.vat);
  const [search, setSearch] = useState('');

  return (
    <Main>
      <Header title="Artiklar" search={setSearch} />
      <Container>
        {items
          .filter(e => e.active && `${e.name.toLowerCase()}`.includes(search))
          .map(e => (
            <Card
              key={e.id}
              image={e.image}
              name={cap(e.name)}
              rows={[{ icon: 'icon-tags', label: 'Pris', value: `${formatPrice(e.price, vat)} kr/st` }]}
              button={<ActionButton data={e} />}
            />
          ))}
      </Container>
    </Main>
  );
};

export default Items;
