import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { addErr } from '../../../Reducers/Error/actions';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import useForm from '../../../Hooks/useForm';
import { useEditBasketProduct } from '../../../Library/Hooks';
import { BasketType } from '../../../Types/types';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 20px;
  grid-template-rows: 1fr;
  grid-gap: 3px;
  align-items: center;
  grid-auto-flow: column;

  input {
    transition: 300ms ease-out;
    width: ${props => `${props.length * 10 || 20}px`};
    max-width: 100px;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeTiny};
    background: none;
    color: ${props => props.theme.color};
    appearance: none;
    border: none;
    overflow-x: hidden;
  }
`;

const Vat = ({ data }) => {
  const { values, handleChange } = useForm({ vat: data.vat || 0 });
  const dispatch = useDispatch();
  const { request } = useEditBasketProduct();

  const handleBlur = async () => {
    const vat = parseInt(values.vat, 10);
    if (vat > 100) {
      dispatch(addErr(`Momsen kan inte vara högre än 100%.`));
    } else if (vat < 0) {
      dispatch(addErr(`Momsen kan inte vara mindre än 0%.`));
    } else if (!Number.isNaN(vat)) {
      request({ ...data, vat });
    }
  };

  return (
    <Container length={values.vat.toString().length}>
      <input type="text" value={values.vat} name="vat" onChange={handleChange} onBlur={handleBlur} pattern="[0-9]+" />
      <h5>%</h5>
    </Container>
  );
};

Vat.propTypes = {
  data: PropTypes.shape(BasketType).isRequired
};

export default Vat;
