import React from 'react';
import styled from 'styled-components';
import useReactRouter from 'use-react-router';
import { Fonts, Media, ZIndex } from '../../../Resources/Stylesheets/Variables';
import Basket from './Basket/Basket';
import Search from './Search/Search';
import Profile from './Profile/Profile';
import routes from '../../../Library/Routes';

const Container = styled.div`
  background: ${props => props.theme.background};
  border-bottom: 2px solid ${props => props.theme.border};
  grid-area: top;
  display: grid;
  grid-template-columns: 70px 150px 1fr 70px 250px 70px;
  grid-template-rows: 70px;
  grid-template-areas: 'icon location . basket search profile';
  grid-gap: 15px;
  justify-items: center;
  align-items: center;
  z-index: ${ZIndex.topBar};
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-template-columns: 35px 35px 1fr 70px 1fr 70px;
    grid-template-areas: 'basket search . icon . profile';
    padding: 0 15px;
  }
`;

const Icon = styled.i`
  grid-area: icon;
  color: ${props => props.theme.accent};
  font-size: ${Fonts.sizeLarge};
`;

const Location = styled.i`
  grid-area: location;
  color: ${props => props.theme.color};
  font-size: ${Fonts.sizeSmall};
  width: 100%;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    display: none;
  }
`;

const findRoute = path =>
  routes.find(e => {
    const r1 = e.path.replace(/^\/([^/]*).*$/, '$1');
    const r2 = path.replace(/^\/([^/]*).*$/, '$1');
    return r1 === r2;
  });

const TopBar = () => {
  const { location } = useReactRouter();
  const route = findRoute(location.pathname);

  return (
    <Container>
      <Icon className="icon-fowlit" />
      <Location className="icon-angle-right">{route ? route.title : '404'}</Location>
      <Basket />
      <Search />
      <Profile />
    </Container>
  );
};

export default TopBar;
