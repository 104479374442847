import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useComponentVisible } from './ComponentVisible';
import { calcTotPrice, formatPrice, groupBy2, groupByLC } from '../Library/Library';
import { CONFERENCE, FIELD, LOCKER } from '../Library/Variables';

export { useComponentVisible };

export const useAfterSuccess = (hook, fn) => {
  const { loading, error, data } = hook;

  const f = useCallback(fn, []);

  useEffect(() => {
    if (!loading && !error) f(data);
  }, [f, error, loading, data]);
  return hook;
};

const basketSelector = state => state.basket;
const filterGrouped = (e, type) => e.spec && e.spec.groupID && e.type === type;
const sortByTime = (e1, e2) => (e1.startTime > e2.startTime ? 1 : -1);

export const selectBasket = createSelector(basketSelector, state => {
  const b = groupByLC(state, 'type');
  if (b.field) b.field = b.field.filter(e => !e.spec.groupID);
  if (b.locker) b.locker = b.locker.filter(e => !e.spec.groupID);
  if (b.conference) b.conference = b.conference.filter(e => !e.spec.groupID);
  return b;
});

export const selectGrouped = type =>
  createSelector(basketSelector, state =>
    groupBy2(state.filter(e => filterGrouped(e, type)).sort(sortByTime), 'spec', 'groupID')
  );

export const selectBasketPrice = createSelector(basketSelector, state =>
  formatPrice(state.reduce((s, e) => s + calcTotPrice(e), 0))
);

export const useSelectBasket = () => {
  const basket = useSelector(selectBasket);
  const basketFieldsGrouped = useSelector(selectGrouped(FIELD));
  const basketLockersGrouped = useSelector(selectGrouped(LOCKER));
  const basketConferencesGrouped = useSelector(selectGrouped(CONFERENCE));
  const totPrice = useSelector(selectBasketPrice);
  const basketSize = useSelector(state => state.basket.length);
  return { basket, basketFieldsGrouped, basketLockersGrouped, basketConferencesGrouped, totPrice, basketSize };
};
