import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useComponentVisible } from '../../../../Hooks';
import Themes from '../../../../Resources/Stylesheets/Themes';
import { Shadows } from '../../../../Resources/Stylesheets/Variables';
import { setTheme } from '../../../../Reducers/Session/actions';
import { Initials, Dropdown, Image } from '../../../../Styles/Styles';
import { logout, cap } from '../../../../Library/Library';
import { ADMIN } from '../../../../Library/Variables';

const Container = styled.div`
  grid-area: profile;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  z-index: 100;
  position: relative;
  cursor: pointer;
`;

const List = styled.div`
  display: flex;
  flex-flow: column;
  width: 200px;
  user-select: none;
`;

const User = styled.div`
  padding: 20px;
`;

const ThemeContainer = styled.div`
  display: grid;
  grid-auto-columns: 30px;
  grid-auto-rows: 30px;
  grid-auto-flow: column;
  grid-gap: 10px;
  padding: 20px;
`;

const Theme = styled.div`
  background: ${props => props.color};
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color};
  height: 30px;
  width: 30px;
  box-shadow: ${Shadows.regular};
`;

const Button = styled(Link)`
  color: ${props => props.theme.color};
  padding: 20px;
  display: grid;
  align-items: center;
  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const Border = styled.div`
  border-bottom: 1px solid ${props => props.theme.border};
`;

const Profile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const { ref, visible, setVisible } = useComponentVisible(false);

  if (!profile) {
    return null;
  }

  return (
    <Container ref={ref} visible={visible}>
      {profile.image === null ? (
        <Initials onClick={() => setVisible(e => !e)}>
          {`${profile.firstName.charAt(0).toUpperCase()}${profile.lastName.charAt(0).toUpperCase()}`}
        </Initials>
      ) : (
        <Image src={profile.image} onClick={() => setVisible(e => !e)} />
      )}
      {visible && (
        <Dropdown right>
          <List>
            <Border>
              <User>
                <h4>{cap(`${profile.firstName} ${profile.lastName}`)}</h4>
                <p>{profile.username}</p>
              </User>
            </Border>
            <Border>
              <Button to="/profile" onClick={() => setVisible(false)}>
                Profil
              </Button>
              {[ADMIN.type].includes(profile.role) && (
                <Button to="/settings" onClick={() => setVisible(false)}>
                  Inställningar
                </Button>
              )}
            </Border>
            <Border>
              <ThemeContainer>
                <Theme
                  color={Themes.light.background}
                  onClick={() => {
                    dispatch(setTheme(Themes.light));
                    setVisible(false);
                  }}
                />
                <Theme
                  color={Themes.dark.background}
                  onClick={() => {
                    dispatch(setTheme(Themes.dark));
                    setVisible(false);
                  }}
                />
              </ThemeContainer>
            </Border>
            <Button
              to="/"
              onClick={() => {
                logout();
                setVisible(false);
              }}
            >
              Logga ut
            </Button>
          </List>
        </Dropdown>
      )}
    </Container>
  );
};

Initials.displayName = 'Initials';
Image.displayName = 'Image';
Button.displayName = 'Button';
Theme.displayName = 'Theme';

export default Profile;
