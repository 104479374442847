import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useComponentVisible } from '../../../Hooks';
import { Fonts, Media, Dimensions } from '../../../Resources/Stylesheets/Variables';
import { Dropdown } from '../../../Styles/Styles';
import { HOUSING_TYPE } from '../../../Library/Variables';

const Container = styled.div`
  grid-area: type;
  border-right: 2px solid ${props => props.theme.border};
  user-select: none;
  position: relative;
  width: 100%;
  @media (max-width: ${Media.phone}) {
    border-right: none;
    border-bottom: 2px solid ${props => props.theme.border};
  }
`;

const Label = styled.div`
  display: grid;
  grid-template-columns: ${Dimensions.navigationHeight} 1fr ${Dimensions.navigationHeight};
  grid-template-rows: ${Dimensions.navigationHeight};
  align-items: center;
`;

const Icon = styled.i`
  display: flex;
  justify-content: center;
  font-size: ${Fonts.sizeRegular};
  color: ${props => (props.active ? props.theme.accent : props.theme.color)};
  transition: 200ms ease-in-out;
`;

const Picker = styled(Dropdown)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-auto-rows: ${Dimensions.navigationHeight};
  padding: 0 15px;
`;

const Item = styled.div`
  padding: 0 10px;
  font-size: ${Fonts.sizeSmall};
  font-family: ${Fonts.text};
  color: ${props => (props.selected ? props.theme.accent : props.theme.color)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const Type = ({ type: value, setType: setValue }) => {
  const { ref, visible, setVisible } = useComponentVisible(false);

  return (
    <Container ref={ref}>
      <Label onClick={() => setVisible(v => !v)}>
        <Icon active={visible || value.type !== ''} className="icon-home" />
        <h4>{value.type === '' ? 'Typ av boende?' : value.label}</h4>
        <Icon className={visible ? 'icon-angle-up' : 'icon-angle-down'} />
      </Label>
      {visible && (
        <Picker>
          {HOUSING_TYPE.map(e => (
            <Item
              key={e.type}
              selected={e.type === value.type}
              onClick={() => {
                if (e.type !== value.type) {
                  setValue(e);
                  setVisible(false);
                } else {
                  setValue({ type: '', label: '' });
                }
              }}
            >
              {e.label}
              <i className={e.type === value.type ? 'icon-cancel-circled' : ''} />
            </Item>
          ))}
        </Picker>
      )}
    </Container>
  );
};

Type.propTypes = {
  type: PropTypes.shape({ type: PropTypes.string, label: PropTypes.string }).isRequired,
  setType: PropTypes.func.isRequired
};

export default Type;
