import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Fonts, Media } from '../../Resources/Stylesheets/Variables';
import { Button, Main } from '../../Styles/Styles';
import CreateUser from './Components/CreateUser';
import User from './Components/User';
import PopOverForm from '../../Components/PopOver/PopOverForm';
import Header from '../../Components/Header/Header';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px max-content max-content max-content;
  grid-template-areas: '.' 'create' 'labels' ' items';
  grid-gap: 30px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-gap: 15px;
    grid-template-columns: 1fr;
    grid-template-rows: 50px max-content max-content;
    grid-template-areas:
      'search'
      'tools'
      'create'
      'items';
  }
`;

const Labels = styled.div`
  grid-area: labels;
  display: grid;
  grid-template-columns: 60px repeat(auto-fit, minmax(100px, 1fr)) 210px;
  grid-auto-flow: column;
  grid-gap: 10px;
  padding-left: 15px;
`;

const Label = styled.p`
  font-size: ${Fonts.sizeSuperTiny};
  font-weight: ${Fonts.weightSemiBold};
  font-family: ${Fonts.head};
  text-transform: uppercase;
`;

const Items = styled.div`
  grid-area: items;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 70px;
  grid-gap: 10px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-auto-rows: max-content;
  }
`;

const CREATE = 'CREATE';
const IMAGE = 'IMAGE';

const reducer = (state, action) => {
  switch (action.type) {
    case CREATE:
      return { ...state, creating: !state.creating };
    case IMAGE:
      return { ...state, image: action.payload };
    default:
      throw new Error('Invalid reducer state');
  }
};

const Users = () => {
  const users = useSelector(state => state.users);
  const [search, setSearch] = useState('');
  const [{ creating, image }, dispatch] = useReducer(reducer, {
    deleting: false,
    editing: false,
    image: null
  });
  const showCreating = () => dispatch({ type: CREATE });

  return (
    <Main>
      <Container>
        <Header
          title="Användare"
          search={setSearch}
          extra={[<Button key="create" value="Skapa användare" onClick={showCreating} />]}
        />
        <Labels>
          <div />
          <Label>Användare</Label>
          <Label>Roll</Label>
          <Label>Senast inloggad</Label>
        </Labels>
        <Items>
          {users
            .filter(
              d =>
                `${d.firstName.toLowerCase()} ${d.lastName.toLowerCase()}`.includes(search) ||
                d.firstName.toLowerCase().includes(search) ||
                d.lastName.toLowerCase().includes(search) ||
                d.username.toLowerCase().includes(search)
            )
            .map(d => (
              <User key={d.id} data={d} />
            ))}
        </Items>
        {creating && (
          <PopOverForm
            data={{
              image,
              initials: '',
              title: 'Skapar användare',
              text: 'Var noga med att fylla i korrekta uppgifter.',
              submitLabel: 'Spara'
            }}
            Form={CreateUser}
            formID="CreateUser"
            onCancel={showCreating}
          />
        )}
      </Container>
    </Main>
  );
};

export default Users;
