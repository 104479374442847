import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Container as Template, Avatar } from '../../../Styles/Styles';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import { cap, formatPrice, getBookingName, intDiv } from '../../../Library/Library';
import { BookingReferenceType } from '../../../Types/types';
import { useGetUser } from '../../../Library/Hooks';
import Loader from '../../../Components/Loader/Loader';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  grid-gap: 15px;
  align-items: center;
`;

const Cells = styled(Template)`
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr 0.5fr 1fr 1.5fr max-content;
  grid-template-rows: 70px;
  padding: 0 0 0 15px;
  grid-gap: 15px;
  align-items: center;
`;

const Cell = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const Link = styled(Button)`
  background: none;
  color: ${props => props.theme.accent};
  display: grid;
  height: 100%;
  width: 70px;
  min-width: auto;
  border-radius: 0;
  font-size: ${Fonts.sizeRegular};
`;

const Booking = ({ data }) => {
  const { customer } = data;
  const name = getBookingName(customer, data.customerType);
  const price = formatPrice(intDiv(data.priceVat * (100 - data.discount), 100));
  const user = useGetUser({ id: data.userID });
  const bookedBy = user && user.data ? `${user.data.firstName} ${user.data.lastName}` : 'Ibex';

  if (!bookedBy) return <Loader />;

  return (
    <Container>
      <Avatar initials={data.referenceID.charAt(0)} size="45px" />
      <Cells>
        <Cell>
          <h5>{cap(name)}</h5>
          <p>{data.referenceID.toUpperCase()}</p>
        </Cell>
        <Cell>
          {customer.email && (
            <>
              <i>E.Mail</i>
              <h5>{customer.email}</h5>
            </>
          )}
        </Cell>
        <Cell>
          {customer.phone && (
            <>
              <i>Telefon</i>
              <h5>{customer.phone}</h5>
            </>
          )}
        </Cell>
        <Cell>
          <i>Antal</i>
          <h5>{data.bookedProducts ? data.bookedProducts.length : 0}</h5>
        </Cell>
        <Cell>
          <i>Pris</i>
          <h5>{`${price} kr`}</h5>
        </Cell>
        <Cell>
          <i>Bokad av</i>
          <h5>{cap(bookedBy)}</h5>
        </Cell>
        <Link value="" className="icon-angle-right" to={`bookingReference/${data.referenceID}`} />
      </Cells>
    </Container>
  );
};

Booking.propTypes = {
  data: PropTypes.shape(BookingReferenceType).isRequired
};

export default Booking;
