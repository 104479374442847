import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container } from '../../Styles/Styles';
import { ZIndex } from '../../Resources/Stylesheets/Variables';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(34, 34, 34, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${ZIndex.dropdown};
`;

const PopOver = ({ Content, data, close }) => {
  return createPortal(
    <Background>
      <Container>
        <Content data={data} close={close} />
      </Container>
    </Background>,
    document.querySelector('#modal')
  );
};

PopOver.propTypes = {
  Content: PropTypes.elementType.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
};

PopOver.defaultProps = {
  data: null
};

export default PopOver;
