import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container as Template } from '../../Styles/Styles';

const Container = styled(Template)`
  padding: 15px;
  grid-column: span 6;
  grid-row: span 5;
  display: grid;
  grid-template-rows: max-content 1fr;
`;

const Box = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`;

const Border = styled.div`
  height: 125px;
  width: 125px;
  border-radius: 50%;
  padding: 3px;
  transition: all 500ms ease-in;
  ${props => {
    if (props.loading === 'true') {
      return `background: ${props.theme.border}`;
    }
    return `background: linear-gradient(to bottom, ${props.theme.accent}, ${props.theme.negative}, ${props.theme.positive})`;
  }};

  &:hover {
    ${props => {
      if (props.loading === 'true') {
        return `background: ${props.theme.border}`;
      }
      return `background: linear-gradient(to top, ${props.theme.accent}, ${props.theme.negative}, ${props.theme.positive})`;
    }};
  }
`;

const Title = styled.h5`
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Info = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  user-select: none;
`;

const Donut = ({ title, value, unit, loading }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Box>
        <Border loading={loading.toString()}>
          <Info>
            {value.length > 5 ? <h4>{!loading && value ? value : 0}</h4> : <h2>{!loading && value ? value : 0}</h2>}
            <p>{unit}</p>
          </Info>
        </Border>
      </Box>
    </Container>
  );
};

Donut.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  unit: PropTypes.string.isRequired,
  loading: PropTypes.bool
};

Donut.defaultProps = {
  loading: false
};

export default Donut;
