import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Fonts, Media, Dimensions } from '../../../../Resources/Stylesheets/Variables';
import { Dropdown } from '../../../../Styles/Styles';
import Month from '../Month/Month';

const Component = styled(Dropdown)`
  display: grid;
  width: 100%;
  grid-template-columns: 210px 210px;
  grid-template-rows: 1fr 60px;
  grid-template-areas: 'left right' 'interval interval';
  font-family: ${Fonts.text};
  justify-content: space-around;
  padding: 30px;
  @media (max-width: ${Media.phone}) {
    grid-template-areas: 'left' 'right' 'interval';
    grid-template-columns: 210px;
    grid-template-rows: 1fr 1fr ${Dimensions.navigationHeight};
    justify-content: center;
    padding: 15px;
  }
`;

const Interval = styled.div`
  grid-area: interval;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  i {
    color: ${props => props.theme.color};
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightSemiBold};
    font-size: 0.8rem;
    margin: 0 10px;
    &:last-of-type {
      font-family: ${Fonts.text};
      font-weight: ${Fonts.weightRegular};
    }
  }
`;

const formatDate = (date, options) => {
  return new Date(date).toLocaleDateString('sv-SV', options);
};

const calculateNights = (from, to) => {
  if (from !== null && to !== null) {
    const f = new Date(from).getTime();
    const t = new Date(to).getTime();
    const diff = Math.round((t - f) / (1000 * 60 * 60 * 24));

    if (diff === 1) {
      return <i>{`(${diff} natt)`}</i>;
    }

    return <i>{`(${diff} nätter)`}</i>;
  }
  return <></>;
};

const monthReducer = (state, action) => {
  switch (action) {
    case 'previous':
      return new Date(state).setMonth(new Date(state).getMonth() - 1);
    case 'next':
      return new Date(state).setMonth(new Date(state).getMonth() + 1);
    default:
      return new Date(state);
  }
};

const Calendar = ({ onSelect, value }) => {
  const [leftMonth, setLeftMonth] = useReducer(monthReducer, new Date());
  const [rightMonth, setRightMonth] = useReducer(monthReducer, new Date().setMonth(new Date().getMonth() + 1));
  const campingSettings = useSelector(state => state.settings.camping);
  const checkInSplit = campingSettings.checkIn.split(':');
  const checkOutSplit = campingSettings.checkOut.split(':');

  const [from, setFrom] = useState(value[0]);
  const [to, setTo] = useState(value[1]);

  useEffect(() => {
    if (new Date(from).getTime() === new Date(to).getTime()) {
      setFrom(null);
      setTo(null);
      onSelect([null, null]);
    } else if (from && to && new Date(from).getTime() >= new Date(to).getTime()) {
      setFrom(to);
      setTo(null);
      onSelect([to, null]);
    } else if (from !== null && to !== null) {
      const newFrom = new Date(from);
      newFrom.setHours(parseInt(checkInSplit[0], 10));
      newFrom.setMinutes(parseInt(checkInSplit[1], 10));

      const newTo = new Date(to);
      newTo.setHours(parseInt(checkOutSplit[0], 10));
      newTo.setMinutes(parseInt(checkOutSplit[1], 10));

      onSelect([newFrom, newTo]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, onSelect]);

  return (
    <Component>
      <Month
        hour={parseInt(checkInSplit[0], 10)}
        minutes={parseInt(checkInSplit[1], 10)}
        month={new Date(leftMonth)}
        setLeftMonth={setLeftMonth}
        setRightMonth={setRightMonth}
        onSelect={from === null ? setFrom : setTo}
        from={from}
        to={to}
      />
      <Month
        hour={parseInt(checkOutSplit[0], 10)}
        minutes={parseInt(checkOutSplit[1], 10)}
        month={new Date(rightMonth)}
        setLeftMonth={setLeftMonth}
        setRightMonth={setRightMonth}
        onSelect={from === null ? setFrom : setTo}
        from={from}
        to={to}
      />
      <Interval hidden={from === null}>
        <i>
          {formatDate(from, {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })}
        </i>
        <i>-</i>
        <i>
          {to !== null
            ? formatDate(to, {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
              })
            : 'Utcheckning'}
        </i>
        {calculateNights(from, to)}
      </Interval>
    </Component>
  );
};

Calendar.propTypes = {
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default Calendar;
