import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Avatar,
  ProductColumn,
  ProductColumns,
  ProductContainer,
  ProductDelete,
  ProductLabel,
  ProductValue
} from '../../../Styles/Styles';
import { useDeleteBasketProduct } from '../../../Library/Hooks';
import {
  calcTotPrice,
  calculateHours,
  cap,
  formatDate,
  formatPrice,
  formatTimeInterval
} from '../../../Library/Library';
import Discount from './Discount';
import Vat from './Vat';
import Guests from './Guests';
import Quantity from './Quantity';
import { BOOKING_TYPE, CAMPING, CONFERENCE, FIELD, ITEM, LOCKER } from '../../../Library/Variables';
import { BasketType } from '../../../Types/types';
import { getName } from '../../../Reducers/Products';
import Comment from './Comment';

const camping = data => [
  <ProductColumn key={1}>
    <ProductLabel>{formatDate(data.startTime)}</ProductLabel>
    <ProductValue>{formatDate(data.endTime)}</ProductValue>
  </ProductColumn>,
  <ProductColumn key={2}>
    <ProductLabel>Nätter</ProductLabel>
    <ProductValue>{data.quantity}</ProductValue>
  </ProductColumn>,
  <ProductColumn key={3}>
    <ProductLabel>Gäster</ProductLabel>
    <Guests data={data} />
  </ProductColumn>
];

const item = data => [
  <ProductColumn key={1}>
    <ProductLabel>Antal</ProductLabel>
    <Quantity data={data} />
  </ProductColumn>
];

const hourBased = data => [
  <ProductColumn key={1}>
    <ProductLabel>{formatDate(data.startTime)}</ProductLabel>
    <ProductValue>{formatTimeInterval(data.startTime, data.endTime)}</ProductValue>
  </ProductColumn>,
  <ProductColumn key={2}>
    <ProductLabel>Timmar</ProductLabel>
    <ProductValue>{`${calculateHours(data.startTime, data.endTime)} h`}</ProductValue>
  </ProductColumn>,
  <ProductColumn key={3}>
    <ProductLabel>Kommentar</ProductLabel>
    <Comment data={data} />
  </ProductColumn>
];

const getColumns = data => {
  switch (data.type) {
    case CAMPING:
      return camping(data);
    case ITEM:
      return item(data);
    case FIELD:
      return hourBased(data);
    case LOCKER:
      return hourBased(data);
    case CONFERENCE:
      return hourBased(data);
    default:
      throw Error('Invalid type');
  }
};

const Product = ({ data }) => {
  const { id, productID, vat, name, type } = data;
  const image = useSelector(state => {
    const p = state.products[getName(type)].find(e => e.id === productID);
    return p ? p.image : null;
  });
  const { request } = useDeleteBasketProduct();
  const version = data.type === FIELD && BOOKING_TYPE.find(e => e.type === data.spec.version);

  const handleDeleteClick = async () => request({ id });

  const columns = getColumns(data);
  return (
    <ProductContainer>
      <Avatar image={image} initials={name.charAt(0)} size="46px" />
      <ProductColumns columns={5}>
        <ProductColumn>
          <ProductValue>{cap(name)}</ProductValue>
          {version && <ProductLabel>{version.label}</ProductLabel>}
        </ProductColumn>
        {columns}
        {columns.length <= 3 &&
          [...Array(3 - columns.length).keys()].map((_, i) => <ProductColumn key={i.toString()} />)}
        <ProductColumn>
          <ProductLabel>Rabatt</ProductLabel>
          <Discount data={data} />
        </ProductColumn>
        <ProductColumn>
          <ProductLabel>Moms</ProductLabel>
          <Vat data={data} value={vat} />
        </ProductColumn>
        <ProductColumn>
          <ProductLabel>Pris</ProductLabel>
          <ProductValue>{`${formatPrice(calcTotPrice(data))} kr`}</ProductValue>
        </ProductColumn>
        <ProductColumn />
        <ProductColumn />
        <ProductDelete value={<i className="icon-trash" />} onClick={handleDeleteClick} />
      </ProductColumns>
    </ProductContainer>
  );
};

Product.propTypes = {
  data: PropTypes.shape(BasketType).isRequired
};

export default Product;
