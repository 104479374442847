import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Input from '../../../../Components/Forms/Input';
import { vatPattern, useSettings, hhmmPattern } from '../../Validate';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, 50px);
  grid-gap: 20px;
`;

const Camping = ({ settings, setValues }) => {
  const { values, handleChange } = useSettings(settings, setValues);
  return (
    <Container>
      <Input
        label="Incheckning (HH:MM)"
        type="text"
        name="checkIn"
        value={values.checkIn}
        onChange={handleChange}
        pattern={hhmmPattern}
        required
      />
      <Input
        label="Utcheckning (HH:MM)"
        type="text"
        name="checkOut"
        value={values.checkOut}
        onChange={handleChange}
        pattern={hhmmPattern}
        required
      />
      <Input
        label="Moms (%)"
        type="text"
        name="vat"
        value={values.vat}
        onChange={handleChange}
        pattern={vatPattern}
        required
      />
    </Container>
  );
};

Camping.propTypes = {
  settings: PropTypes.shape({
    vat: PropTypes.string,
    checkIn: PropTypes.string,
    checkOut: PropTypes.string
  }).isRequired,
  setValues: PropTypes.func.isRequired
};

export default Camping;
