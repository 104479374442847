import PropTypes from 'prop-types';
import {
  CABIN,
  CAMPING,
  CARAVAN,
  COMPANY,
  CONFERENCE,
  DAILY,
  FIELD,
  HOSTEL,
  ITEM,
  LOCKER,
  PERSON,
  ROOM,
  TENT,
  WEEKLY
} from '../Library/Variables';

const CampingSpec = {
  type: PropTypes.oneOf([CABIN, ROOM, HOSTEL, CARAVAN, TENT]),
  beds: PropTypes.number,
  size: PropTypes.number,
  wifi: PropTypes.bool.isRequired,
  kitchen: PropTypes.bool.isRequired,
  electricity: PropTypes.bool.isRequired,
  tv: PropTypes.bool.isRequired
};

const FieldSpec = {
  fullField: PropTypes.number,
  oneThirdField: PropTypes.number,
  twoThirdsField: PropTypes.number,
  halfField: PropTypes.number,
  match: PropTypes.number,

  fullFieldDay: PropTypes.number,
  oneThirdFieldDay: PropTypes.number,
  twoThirdsFieldDay: PropTypes.number,
  halfFieldDay: PropTypes.number,
  matchDay: PropTypes.number,

  size: PropTypes.number.isRequired
};

const DayHourSpec = {
  size: PropTypes.number,
  day: PropTypes.number,
  hour: PropTypes.number
};

const ConferenceSpec = {
  wifi: PropTypes.bool.isRequired,
  tv: PropTypes.bool.isRequired,
  ...DayHourSpec
};

export const ProductType = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  price: PropTypes.number,
  type: PropTypes.oneOf([CAMPING, FIELD, CONFERENCE, LOCKER, ITEM]),
  active: PropTypes.bool.isRequired,
  spec: PropTypes.oneOfType([
    PropTypes.shape(CampingSpec),
    PropTypes.shape(FieldSpec),
    PropTypes.shape(DayHourSpec),
    PropTypes.shape(ConferenceSpec)
  ])
};

const DayHourBasketSpec = {
  repeatUntil: PropTypes.instanceOf(Date),
  repeatEvery: PropTypes.oneOf([WEEKLY, DAILY]),
  groupID: PropTypes.number,
  version: PropTypes.string.isRequired
};

const CampingBasketItemSpec = {
  guests: PropTypes.number
};

const FieldBasketItemSpec = {
  ...DayHourBasketSpec,
  comment: PropTypes.string
};

const ReservedProductType = {
  id: PropTypes.number.isRequired,
  productID: PropTypes.number.isRequired,
  type: PropTypes.oneOf([CAMPING, FIELD, CONFERENCE, LOCKER, ITEM]),
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  discount: PropTypes.number.isRequired,
  vat: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  addition: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  spec: PropTypes.oneOfType([
    PropTypes.shape(CampingBasketItemSpec),
    PropTypes.shape(FieldBasketItemSpec),
    PropTypes.shape(DayHourBasketSpec)
  ])
};

export const BasketType = {
  ...ReservedProductType
};

export const UserType = {
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  lastLogin: PropTypes.instanceOf(Date),
  image: PropTypes.string,
  changePassword: PropTypes.bool.isRequired
};

export const BookedProductType = {
  ...ReservedProductType,
  referenceID: PropTypes.string.isRequired
};

export const CustomerType = {
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired
};

export const PersonType = {
  ...CustomerType,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
};

export const CompanyType = {
  ...CustomerType,
  name: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  customerID: PropTypes.string.isRequired
};

export const BookingReferenceType = {
  // id: PropTypes.number.isRequired,
  userID: PropTypes.number.isRequired,
  referenceID: PropTypes.string.isRequired,

  price: PropTypes.number.isRequired,
  priceVat: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,

  customerType: PropTypes.oneOf([PERSON, COMPANY]).isRequired,
  customer: PropTypes.oneOfType([PropTypes.shape(PersonType), PropTypes.shape(CompanyType)]).isRequired,
  comment: PropTypes.string
};
