import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Main, Grid } from '../../Styles/Styles';
import { Media, Dimensions } from '../../Resources/Stylesheets/Variables';
import Search from '../../Components/Search/Search';
import { cap, formatPrice } from '../../Library/Library';
import { addErr } from '../../Reducers/Error/actions';
import Donut from '../../Components/Statistics/Donut';
import { CAMPING } from '../../Library/Variables';
import { useGetAvailableCamping } from '../../Library/Hooks';
import Card from '../../Components/Card/Card';
import ActionButton from './Components/ActionButton';
import Loader from '../../Components/Loader/Loader';

const Component = styled(Main)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${Dimensions.navigationHeight} 1fr;
  grid-gap: 30px;
  grid-template-areas:
    'search'
    'content';
  @media (max-width: ${Media.phone}) {
    grid-template-rows: calc(4 * ${Dimensions.navigationHeight}) 1fr;
  }
`;

const Content = styled.div`
  grid-area: content;
  display: grid;
  grid-gap: 30px;
`;

const Result = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${Dimensions.navigationHeight} 1fr;
  grid-gap: 10px;
  grid-template-areas:
    'title'
    '.';
`;

const EmptySearch = styled(Result)``;

const Recommended = styled(Result)``;

const Available = styled(Result)``;

const Statistics = styled(Result)`
  margin-bottom: 30px;
`;

const Box = styled.div`
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-template-rows: repeat(auto-fill, 350px);
  grid-auto-columns: 225px;
  grid-auto-rows: 325px;
  grid-gap: 30px;
  @media (max-width: ${Media.phone}) {
    grid-template-columns: repeat(auto-fill, 100%);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-auto-columns: 100%;
    grid-auto-rows: 1fr;
  }
`;

const Title = styled.h3`
  grid-area: title;
  display: flex;
  align-items: center;
`;

const countTypes = bookings =>
  bookings.reduce(
    (e, e1) => {
      switch (e1.type) {
        case 'CABIN':
          e.cabin += 1;
          break;
        case 'HOSTEL':
          e.hostel += 1;
          break;
        case 'CARAVAN':
          e.caravan += 1;
          break;
        case 'TENT':
          e.tent += 1;
          break;
        case 'ROOM':
          e.room += 1;
          break;
        default:
          throw Error('HOUSING TYPE NOT YET IMPLEMENTED');
      }
      return e;
    },
    {
      cabin: 0,
      hostel: 0,
      caravan: 0,
      tent: 0,
      room: 0
    }
  );

const Camping = () => {
  const [circles, setCircles] = useState([]);
  const [dates, setDates] = useState([null, null]);
  const [guests, setGuests] = useState(0);
  const [type, setType] = useState({ type: '', label: '' });
  const dispatch = useDispatch();
  const camping = useSelector(state => state.products.camping);
  const current = useSelector(state => state.bookings.current);
  const vat = useSelector(state => state.settings.camping.vat);
  const { data, request } = useGetAvailableCamping();
  useEffect(() => {
    if (current.length > 0 && camping.length > 0) {
      const now = new Date();

      let flat = [];
      current
        .map(e => e.bookedProducts.filter(e1 => e1.startTime < now && e1.endTime > now && e1.itemType === CAMPING))
        .forEach(e => e.forEach(e1 => flat.push(e1)));
      flat = flat.map(e => camping.find(e1 => e.itemID === e1.id));

      const vals = countTypes(flat.map(e => e));
      setCircles([
        { title: 'Upptagna stugor', value: vals.cabin, unit: 'st' },
        { title: 'Upptagna rum', value: vals.room, unit: 'st' },
        { title: 'Upptagna husvagnsplatser', value: vals.caravan, unit: 'st' },
        { title: 'Upptagna tältplatser', value: vals.tent, unit: 'st' }
      ]);
    } else {
      setCircles([
        { title: 'Upptagna stugor', value: 0, unit: 'st' },
        { title: 'Upptagna rum', value: 0, unit: 'st' },
        { title: 'Upptagna husvagnsplatser', value: 0, unit: 'st' },
        { title: 'Upptagna tältplatser', value: 0, unit: 'st' }
      ]);
    }
  }, [current, camping]);

  const onSubmit = async e => {
    e.preventDefault();

    if (!type.type) {
      dispatch(addErr('Välj typ av bostad.'));
      return;
    }
    if (dates.length < 2) {
      dispatch(addErr('Välj ett datumintervall.'));
      return;
    }
    if (guests === 0) {
      dispatch(addErr('Ange antal gäster.'));
      return;
    }

    request({
      guests,
      type: type.type,
      startTime: dates[0],
      endTime: dates[1]
    });
  };

  const getHousingByID = id => {
    const house = camping.find(e => e.id === id);
    const { spec } = house;
    return (
      <Card
        key={house.id}
        image={house.image}
        name={cap(house.name)}
        rows={[
          { icon: 'icon-home', label: 'Storlek', value: `${spec.size ? spec.size : 0} m\u00b2` },
          { icon: 'icon-bed', label: 'Bäddar', value: `${spec.beds ? spec.beds : 0} st` },
          { icon: 'icon-tags', label: 'Pris', value: `${formatPrice(house.price, vat)} kr` }
        ]}
        details={[
          spec.tv ? 'icon-tv' : null,
          spec.wifi ? 'icon-wifi' : null,
          spec.kitchen ? 'icon-kitchen' : null,
          spec.electricity ? 'icon-electricity' : null
        ]}
        button={<ActionButton data={house} startTime={dates[0]} endTime={dates[1]} />}
      />
    );
  };

  const rec = data ? data.recommended : null;
  const all = data ? data.housing : null;

  const empty = data && rec.length === 0 && all.length === 0;

  if (!vat) return <Loader />;

  return (
    <Component>
      <Search
        onSubmit={onSubmit}
        setDates={setDates}
        dates={dates}
        setGuests={setGuests}
        guests={guests}
        type={type}
        setType={setType}
      />
      <Content>
        {empty && (
          <EmptySearch>
            <Title>Kunde inte hitta någon bostad.</Title>
            <p>Testa ändra typ, datum eller antalet gäster och sök igen.</p>
          </EmptySearch>
        )}
        {rec && rec.length > 0 && (
          <Recommended>
            <Title>Rekommenderad bokning</Title>
            <Box>{rec.map(getHousingByID)}</Box>
          </Recommended>
        )}

        {all && all.length > 0 && (
          <Available>
            <Title>{`Tillgängliga bostäder (${all.length})`}</Title>
            <Box>{all.map(getHousingByID)}</Box>
          </Available>
        )}

        <Statistics>
          <Title>Statistik</Title>
          <Grid>
            {circles.map(e => (
              <Donut key={e.title} title={e.title} value={e.value} unit={e.unit} />
            ))}
          </Grid>
        </Statistics>
      </Content>
    </Component>
  );
};

export default Camping;
