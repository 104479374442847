import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFetch, useFetchLater } from './Fetch';
import * as api from './api';
import { addErr, addMsg } from '../Reducers/Error/actions';
import { addUser, deleteUser, setUsers, updateUser } from '../Reducers/Users/actions';
import { setProfile } from '../Reducers/Profile/actions';
import {
  addBasketItem,
  deleteBasket,
  deleteBasketItem,
  initBasket,
  updateBasketItem
} from '../Reducers/Basket/actions';
import { addProduct, deleteProduct, initProducts, updateProduct } from '../Reducers/Products/actions';
import {
  addBooking,
  deleteBooking,
  setCurrentBookings,
  setLatestBookings,
  setTodayBookings,
  updateBooking
} from '../Reducers/Bookings/actions';
import { setSettings } from '../Reducers/Settings/actions';
import { addCoupon, deleteCoupon, initCoupons, updateCoupon } from '../Reducers/Coupons/actions';
import { addMark, deleteMark, initMarks, updateMark } from '../Reducers/Marks/actions';

const useHooks = ({ fn, err, msg, onSuccess, dis, f }) => {
  const dispatch = useDispatch();
  const resp = f(useCallback(fn, []));

  const { loading, error, data } = resp;

  useEffect(() => {
    if (!loading) {
      if (error) {
        if (err) dispatch(addErr(err));
      } else {
        if (dis) dispatch(dis(data));
        if (onSuccess) onSuccess(data);
        if (msg) dispatch(addMsg(msg));
      }
    }
  }, [loading, error, data, dispatch, err, dis, onSuccess, msg]);

  return resp;
};

const useHook = b => useHooks({ ...b, f: useFetch });
const useLaterHook = b => useHooks({ ...b, f: useFetchLater });

const ERROR = {
  GET_USERS: 'hämta användarna',
  GET_USER: 'hämta användaren',
  CREATE_USER: 'skapa användaren',
  DELETE_USER: 'ta bort användaren',
  GET_PROFILE: 'hämta profilen',
  UPDATE_PROFILE: 'uppdatera profilen',
  PASSWORD: 'byta lösenord',
  SAVE_CHANGES: 'spara ändringarna',
  GET_BASKET: 'hämta varukorgen',
  DELETE_BASKET: 'ta bort varukorgen',
  ADD_BASKET_PRODUCT: 'lägga till varan i varukorgen',
  EDIT_BASKET_PRODUCT: 'redigera varan',
  DELETE_BASKET_PRODUCT: 'ta bort varan från varukorgen',
  GET_PRODUCTS: 'hämta varorna',
  ADD_PRODUCT: 'lägga till varan',
  EDIT_PRODUCT: 'redigera varan',
  GET_PRODUCT: 'hämta varan',
  DELETE_PRODUCT: 'ta bort produkten då den redan är reserverad',
  SEARCH: 'utföra sökningen',
  GET_BOOKINGS: 'hämta bokningarna',
  GET_BOOKING_REFERENCE: 'hämta bokningsreferensen',
  CREATE_BOOKING_REFERENCE: 'skapa bokningsreferensen',
  DELETE_BOOKING_REFERENCE: 'ta bort bokningsreferensen',
  ADD_BASKET_TO_BOOKING_REFERENCE: 'lägga till varukorgen till bokningsreferensen',
  EDIT_BOOKED_PRODUCT: 'redigera den bokade varan',
  DELETE_BOOKED_PRODUCT: 'ta bort den bokade varan',
  GET_COMPANY: 'hitta något företag med det kundnumret',
  GET_SETTINGS: 'hämta inställningarna',
  UPDATE_SETTINGS: 'spara inställningarna',
  GET_COUPONS: 'hämta kupongerna',
  ADD_COUPON: 'spara kupongen',
  EDIT_COUPON: 'redigera kupongen',
  DELETE_COUPON: 'ta bort kupongen',
  GET_STATISTICS: 'hämta statistiken',
  GET_MARKS: 'hämta markeringarna',
  ADD_MARK: 'spara markeringen',
  EDIT_MARK: 'redigera markeringen',
  DELETE_MARK: 'ta bort markeringen',
  CREATE_INVOICE: 'skapa fakturaunderlaget'
};

const MSG = {
  CREATE_USER: 'Användaren skapades',
  DELETE_USER: 'Användaren togs bort',
  SAVE_CHANGES: 'Ändringarna sparades',
  PASSWORD: 'Böt lösenord',
  DELETE_BASKET: 'Varukorgen togs bort',
  ADD_PRODUCT: 'Produkten skapades',
  DELETE_PRODUCT: 'Produkten togs bort',
  CREATE_BOOKING_REFERENCE: 'Bokningsreferensen skapades',
  DELETE_BOOKING_REFERENCE: 'Tog bort bokningsreferensen',
  ADD_BASKET_TO_BOOKING_REFERENCE: 'Varukorgen lades till',
  DELETE_BOOKED_PRODUCT: 'Den bokade produkten togs bort',
  ADD_COUPON: 'Kupongen skapades',
  DELETE_COUPON: 'Kupongen togs bort',
  ADD_MARK: 'Markeringen skapades',
  DELETE_MARK: 'Markeringen togs bort',
  CREATE_INVOICE: 'Fakturaunderlaget skapades'
};

Object.keys(ERROR).forEach(k => {
  ERROR[k] = `Kunde inte ${ERROR[k]}.`;
});

Object.keys(MSG).forEach(k => {
  MSG[k] = `${MSG[k]}.`;
});

export const useGetUsers = () =>
  useHook({
    fn: api.getUsers,
    err: ERROR.GET_USERS,
    dis: setUsers
  });

export const useGetUser = o =>
  useHook({
    fn: useCallback(() => api.getUser(o), [o])
  });
export const useCreateUser = () =>
  useLaterHook({
    fn: api.createUser,
    err: ERROR.CREATE_USER,
    msg: MSG.CREATE_USER,
    dis: addUser
  });
export const useDeleteUser = () =>
  useLaterHook({
    fn: api.deleteUser,
    err: ERROR.DELETE_USER,
    msg: MSG.DELETE_USER,
    dis: deleteUser
  });
export const useSetUserRole = () =>
  useLaterHook({
    fn: api.setUserRole,
    err: ERROR.UPDATE_PROFILE,
    msg: MSG.SAVE_CHANGES,
    dis: updateUser
  });
export const useGetProfile = () =>
  useHook({
    fn: api.getProfile,
    err: ERROR.GET_PROFILE,
    dis: setProfile
  });
export const useUpdateProfile = () =>
  useLaterHook({
    fn: api.updateProfile,
    err: ERROR.UPDATE_PROFILE,
    msg: MSG.SAVE_CHANGES,
    dis: setProfile
  });
export const useChangePassword = () =>
  useLaterHook({
    fn: api.changePassword,
    err: ERROR.PASSWORD,
    msg: MSG.PASSWORD
  });
export const useSetPassword = () =>
  useLaterHook({
    fn: api.setPassword,
    err: ERROR.PASSWORD,
    msg: MSG.PASSWORD
  });
export const useForgotPassword = () =>
  useLaterHook({
    fn: api.forgotPassword,
    err: ERROR.PASSWORD,
    msg: MSG.PASSWORD
  });
export const useGetBasket = () =>
  useHook({
    fn: api.getBasket,
    err: ERROR.GET_BASKET,
    dis: initBasket
  });
export const useDeleteBasket = () =>
  useLaterHook({
    fn: api.deleteBasket,
    err: ERROR.DELETE_BASKET,
    msg: MSG.DELETE_BASKET,
    dis: deleteBasket
  });
export const useAddBasketProduct = () =>
  useLaterHook({
    fn: api.addBasketProduct,
    err: ERROR.ADD_BASKET_PRODUCT,
    dis: addBasketItem
  });
export const useEditBasketProduct = () =>
  useLaterHook({
    fn: api.editBasketProduct,
    err: ERROR.EDIT_BASKET_PRODUCT,
    dis: updateBasketItem
  });
export const useDeleteBasketProduct = () =>
  useLaterHook({
    fn: api.deleteBasketProduct,
    err: ERROR.DELETE_BASKET_PRODUCT,
    dis: deleteBasketItem
  });
export const useDeleteBasketProductByGroupID = () =>
  useLaterHook({
    fn: api.deleteBasketProductByGroupID,
    err: ERROR.DELETE_BASKET_PRODUCT,
    dis: deleteBasketItem
  });
export const useGetProducts = () =>
  useHook({
    fn: api.getProducts,
    err: ERROR.GET_PRODUCTS,
    dis: initProducts
  });
export const useAddProduct = () =>
  useLaterHook({
    fn: api.addProduct,
    err: ERROR.ADD_PRODUCT,
    msg: MSG.ADD_PRODUCT,
    dis: addProduct
  });
export const useGetProduct = () =>
  useHook({
    fn: api.getProduct,
    err: ERROR.GET_PRODUCT,
    dis: updateProduct
  });
export const useEditProduct = () =>
  useLaterHook({
    fn: api.editProduct,
    err: ERROR.EDIT_PRODUCT,
    msg: MSG.SAVE_CHANGES,
    dis: updateProduct
  });
export const useDeleteProduct = () =>
  useLaterHook({
    fn: api.deleteProduct,
    err: ERROR.DELETE_PRODUCT,
    msg: MSG.DELETE_PRODUCT,
    dis: deleteProduct
  });
export const useGetBLArticles = () =>
  useHook({
    fn: api.getBLArticles,
    err: ERROR.GET_PRODUCTS
  });
export const useGetUnoccupiedProducts = () =>
  useLaterHook({
    fn: api.getUnoccupiedProducts,
    err: ERROR.SEARCH
  });
export const useGetOccupiedProducts = () =>
  useLaterHook({
    fn: api.getOccupiedProducts,
    err: ERROR.SEARCH
  });
export const useGetBookingReferences = ({ page, size }) =>
  useHook({
    fn: useCallback(() => api.getBookingReferences({ page, size }), [page, size]),
    err: ERROR.GET_BOOKINGS
  });
export const useGetBookingReference = o =>
  useHook({
    fn: useCallback(() => api.getBookingReference(o), [o]),
    err: ERROR.GET_BOOKING_REFERENCE
  });
export const useCreateBookingReference = () =>
  useLaterHook({
    fn: api.createBookingReference,
    err: ERROR.CREATE_BOOKING_REFERENCE,
    msg: MSG.CREATE_BOOKING_REFERENCE,
    dis: addBooking
  });
export const useEditBookingReference = () =>
  useLaterHook({
    fn: api.editBookingReference,
    err: ERROR.SAVE_CHANGES,
    msg: MSG.SAVE_CHANGES,
    dis: updateBooking
  });
export const useDeleteBookingReference = () =>
  useLaterHook({
    fn: api.deleteBookingReference,
    err: ERROR.DELETE_BOOKING_REFERENCE,
    msg: MSG.DELETE_BOOKING_REFERENCE,
    dis: deleteBooking
  });
export const useAddBasketToBookingReference = () =>
  useLaterHook({
    fn: api.addBasketToBookingReference,
    err: ERROR.ADD_BASKET_TO_BOOKING_REFERENCE,
    msg: MSG.ADD_BASKET_TO_BOOKING_REFERENCE,
    dis: updateBooking
  });
export const useSearchBookingReferences = () =>
  useLaterHook({
    fn: api.searchBookingReferences,
    err: ERROR.SEARCH
  });
export const useGetTodayBookings = () =>
  useHook({
    fn: api.getTodaysNewBookings,
    err: ERROR.GET_BOOKINGS,
    dis: setTodayBookings
  });
export const useGetCurrentBookings = () =>
  useHook({
    fn: api.getCurrentBookings,
    err: ERROR.GET_BOOKINGS,
    dis: setCurrentBookings
  });
export const useGetLatestBookings = () =>
  useHook({
    fn: api.getLatestBookings,
    err: ERROR.GET_BOOKINGS,
    dis: ({ bookingReferences }) => setLatestBookings(bookingReferences)
  });
export const useEditBookingReferenceProduct = () =>
  useLaterHook({
    fn: api.editBookingReferenceProduct,
    err: ERROR.EDIT_BOOKED_PRODUCT,
    msg: MSG.SAVE_CHANGES
  });
export const useDeleteBookingReferenceProduct = () =>
  useLaterHook({
    fn: api.deleteBookingReferenceProduct,
    err: ERROR.DELETE_BOOKED_PRODUCT,
    msg: MSG.DELETE_BOOKED_PRODUCT,
    dis: updateBooking
  });
export const useGetAvailableCamping = () =>
  useLaterHook({
    fn: api.getAvailableCamping,
    err: ERROR.SEARCH
  });
export const useGetCompany = () =>
  useLaterHook({
    fn: api.getCompany,
    err: ERROR.GET_COMPANY
  });

export const useGetPublicSettings = () =>
  useHook({
    fn: api.getPublicSettings,
    err: ERROR.GET_SETTINGS,
    dis: setSettings
  });

export const useGetSettings = () =>
  useHook({
    fn: api.getSettings,
    err: ERROR.GET_SETTINGS,
    dis: setSettings
  });
export const useUpdateSettings = () =>
  useLaterHook({
    fn: api.updateSettings,
    err: ERROR.UPDATE_SETTINGS,
    msg: MSG.SAVE_CHANGES
  });
export const useGetCoupons = () =>
  useHook({
    fn: api.getCoupons,
    err: ERROR.GET_COUPONS,
    dis: initCoupons
  });
export const useAddCoupon = () =>
  useLaterHook({
    fn: api.addCoupon,
    err: ERROR.ADD_COUPON,
    msg: MSG.ADD_COUPON,
    dis: addCoupon
  });
export const useEditCoupon = () =>
  useLaterHook({
    fn: api.editCoupon,
    err: ERROR.EDIT_COUPON,
    msg: MSG.SAVE_CHANGES,
    dis: updateCoupon
  });
export const useDeleteCoupon = () =>
  useLaterHook({
    fn: api.deleteCoupon,
    err: ERROR.DELETE_COUPON,
    msg: MSG.DELETE_COUPON,
    dis: deleteCoupon
  });
export const useGetStatistics = () =>
  useLaterHook({
    fn: api.getStatistics,
    err: ERROR.GET_STATISTICS
  });

export const useGetMarks = () =>
  useHook({
    fn: api.getMarks,
    err: ERROR.GET_MARKS,
    dis: initMarks
  });
export const useAddMark = () =>
  useLaterHook({
    fn: api.addMark,
    err: ERROR.ADD_MARK,
    msg: MSG.ADD_MARK,
    dis: addMark
  });
export const useEditMark = () =>
  useLaterHook({
    fn: api.editMark,
    err: ERROR.EDIT_MARK,
    msg: MSG.SAVE_CHANGES,
    dis: updateMark
  });
export const useDeleteMark = () =>
  useLaterHook({
    fn: api.deleteMark,
    err: ERROR.DELETE_MARK,
    msg: MSG.DELETE_MARK,
    dis: deleteMark
  });
export const useGetCustomerBookingReferences = () =>
  useLaterHook({
    fn: api.getCustomerBookingReferences,
    err: ERROR.GET_BOOKING_REFERENCE
  });
export const useCreateInvoice = () =>
  useLaterHook({
    fn: api.createInvoice,
    err: ERROR.CREATE_INVOICE,
    msg: MSG.CREATE_INVOICE
  });
