import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from '../../../Components/Forms/Select';
import { USER_TYPE } from '../../../Library/Variables';
import useForm from '../../../Hooks/useForm';
import { useSetUserRole } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';

const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px;
  grid-gap: 10px;
`;

const EditUser = ({ formID, formData, close }) => {
  const { values, handleChange } = useForm({ role: formData.role, id: formData.id });
  const { request } = useAfterSuccess(useSetUserRole(), close);

  const onSubmit = async e => {
    e.preventDefault();
    request(values);
  };

  return (
    <Form id={formID} onSubmit={onSubmit}>
      <Select
        value={values.role}
        onChange={handleChange}
        name="role"
        label="Roll"
        options={USER_TYPE}
        defaultValue={formData.role}
      />
    </Form>
  );
};

EditUser.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.instanceOf(Date)])
  ).isRequired,
  close: PropTypes.func.isRequired
};

export default EditUser;
