import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container as Template } from '../../../Styles/Styles';
import { COMPANY } from '../../../Library/Variables';
import { cap, getBookingName } from '../../../Library/Library';
import { CompanyType, PersonType } from '../../../Types/types';

export const Rows = styled(Template)`
  display: grid;
  grid-auto-rows: max-content;
  align-items: center;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  border-bottom: 1px solid ${props => props.theme.border};
  &:last-of-type {
    border-bottom: none;
  }
`;

export const Label = styled.p`
  display: grid;
  border-right: 1px solid ${props => props.theme.border};
  padding: 15px;
`;

export const Data = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  padding: 15px;
`;

const Customer = ({ data, type }) => {
  const name = getBookingName(data, type);

  const Company = () => (
    <>
      <Row>
        <Label>Kund ID</Label>
        <Data>
          <p>{data.customerID}</p>
        </Data>
      </Row>
      {data.reference && (
        <Row>
          <Label>Referens</Label>
          <Data>
            <p>{cap(data.reference)}</p>
          </Data>
        </Row>
      )}
    </>
  );

  return (
    <Rows>
      <Row>
        <Label>Namn</Label>
        <Data>
          <p>{cap(name)}</p>
        </Data>
      </Row>
      {type === COMPANY && <Company />}
      {data.email && (
        <Row>
          <Label>E-Mail</Label>
          <Data>
            <p>{data.email}</p>
          </Data>
        </Row>
      )}
      {data.phone && (
        <Row>
          <Label>Telefon</Label>
          <Data>
            <p>{data.phone}</p>
          </Data>
        </Row>
      )}
      <Row>
        <Label>Adress</Label>
        <Data>
          <p>{cap(data.address)}</p>
          <p>{`${data.zipCode} ${cap(data.city)}`}</p>
          <p>{cap(data.country)}</p>
        </Data>
      </Row>
      {data.comment && (
        <Row>
          <Label>Kommentar</Label>
          <Data>
            <p>{data.comment}</p>
          </Data>
        </Row>
      )}
    </Rows>
  );
};

Customer.propTypes = {
  data: PropTypes.oneOfType([PropTypes.shape(PersonType), PropTypes.shape(CompanyType)]).isRequired,
  type: PropTypes.string.isRequired
};

export default Customer;
