import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { cap, isSameDate, rgba } from '../../../Library/Library';
import Booking, { BookingProps } from './Booking';
import { Ripple } from '../../../Styles/Styles';
import { Media, ZIndex } from '../../../Resources/Stylesheets/Variables';

const Day = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(288, 8px);
  border-left: 1px solid ${props => props.theme.border};
  border-right: 1px solid ${props => props.theme.border};
  position: relative;
`;

const Grid = styled.div`
  grid-row-start: 1;
  grid-row-end: 288;
  grid-column: 1 / span 6;
  display: grid;
  grid-template-rows: repeat(24, 1fr);
  z-index: 0;
  grid-gap: 1px;
`;

const Cell = styled(Ripple)`
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.border};
  border-radius: 0;
  z-index: 0;
  cursor: pointer;
  &:hover {
    background: ${props => rgba(0.2, props.theme.color)};
  }
`;

const Label = styled.div`
  display: none;
  width: 100%;
  grid-template-rows: max-content max-content;
  align-content: center;
  justify-content: center;
  color: ${props => (props.active ? props.theme.accent : props.theme.color)};
  position: sticky;
  top: 0;
  z-index: ${ZIndex.dropdown};
  background: ${props => rgba(0.5, props.theme.background)};
  @media (max-width: ${Media.phone}) {
    display: grid;
  }
`;

const WeekDay = ({ cellsLength, setCell, viewForm, week, bookings }) => {
  const handleClick = i => {
    setCell(week.setHours(i, 0, 0, 0));
    viewForm(v => !v);
  };

  return (
    <Day>
      <Grid>
        <Label key={week.toLocaleString()} active={isSameDate(new Date(), week)}>
          <i>{cap(week.toLocaleDateString('sv-SV', { weekday: 'long' }))}</i>
          <h3>{week.toLocaleDateString('sv-SV', { day: 'numeric' })}</h3>
        </Label>
        {Array(cellsLength)
          .fill(0)
          .map((_, i) => (
            <Cell key={Math.random()} onClick={() => handleClick(i)} />
          ))}
      </Grid>
      {bookings.map(e => (
        <Booking key={e.id} data={e} />
      ))}
    </Day>
  );
};

WeekDay.propTypes = {
  cellsLength: PropTypes.number.isRequired,
  setCell: PropTypes.func.isRequired,
  viewForm: PropTypes.func.isRequired,
  week: PropTypes.instanceOf(Date).isRequired,
  bookings: PropTypes.arrayOf(PropTypes.shape(BookingProps)).isRequired
};

export default WeekDay;
