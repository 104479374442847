import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { Main as MainTemp, Container, Button } from '../../Styles/Styles';
import { Media } from '../../Resources/Stylesheets/Variables';
import Grouped from './Components/Grouped';
import { addErr } from '../../Reducers/Error/actions';
import Product from './Components/Product';
import { useSelectBasket } from '../../Hooks';

const Main = styled(MainTemp)`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'content summary';
  grid-gap: 30px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'content' 'summary';
  }
`;

const Content = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 30px;
  overflow-y: auto;
`;

const Summary = styled.div`
  grid-area: summary;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  grid-gap: 10px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-template-rows: 50px 1fr;
  }
`;

const Rows = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 20px;
  background: ${props => props.theme.body};
  border-left: 2px solid ${props => props.theme.border};
  padding: 30px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-template-rows: 40px 40px 60px;
  }
`;

const Title = styled.h3`
  color: ${props => props.theme.color};
  position: sticky;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
`;

const Items = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

const Basket = () => {
  const { history } = useReactRouter();
  const dispatch = useDispatch();
  const {
    basket,
    basketFieldsGrouped,
    basketLockersGrouped,
    basketConferencesGrouped,
    totPrice,
    basketSize
  } = useSelectBasket();
  const { camping, field: fields, item: items, conference: conferences, locker: lockers } = basket;

  const handleCheckoutClick = () => {
    const valid = camping ? !camping.find(({ spec }) => !spec.guests) : true;
    if (valid) {
      history.push('/checkout');
      return;
    }
    dispatch(addErr('Fyll i antal gäster.'));
  };

  if (!basketSize) {
    return (
      <Main>
        <Content>
          <Title>Varukorg</Title>
          <p>Din varukorg är tom.</p>
        </Content>
      </Main>
    );
  }

  return (
    <Main>
      <Content>
        {camping && (
          <Items>
            <Title>Bostäder</Title>
            {camping.map(e => (
              <Product key={e.id} data={e} />
            ))}
          </Items>
        )}
        {fields && (
          <Items>
            <Title>Ytor</Title>
            {Object.entries(basketFieldsGrouped).map(([key, e]) => {
              if (key === 'null') return e.map(e1 => <Product key={e1.id} data={e1} />);
              return <Grouped key={key} data={e} />;
            })}
            {fields.map(e => (
              <Product key={e.id} data={e} />
            ))}
          </Items>
        )}
        {lockers && (
          <Items>
            <Title>Omklädningsrum</Title>
            {Object.entries(basketLockersGrouped).map(([key, e]) => {
              if (key === 'null') return e.map(e1 => <Product key={e1.id} data={e1} />);
              return <Grouped key={key} data={e} />;
            })}
            {lockers.map(e => (
              <Product key={e.id} data={e} />
            ))}
          </Items>
        )}
        {conferences && (
          <Items>
            <Title>Konferens</Title>
            {Object.entries(basketConferencesGrouped).map(([key, e]) => {
              if (key === 'null') return e.map(e1 => <Product key={e1.id} data={e1} />);
              return <Grouped key={key} data={e} />;
            })}
            {conferences.map(e => (
              <Product key={e.id} data={e} />
            ))}
          </Items>
        )}
        {items && (
          <Items>
            <Title>Artiklar</Title>
            {items.map(e => (
              <Product key={e.id} data={e} />
            ))}
          </Items>
        )}
      </Content>
      <Summary>
        <Title>Sammanställning</Title>
        <Rows>
          <Row>
            <p>Antal</p>
            <h4>{basketSize}</h4>
          </Row>
          <Row>
            <p>Totalt</p>
            <h4>{`${totPrice} kr`}</h4>
          </Row>
          <Button value="Boka nu" onClick={handleCheckoutClick} />
        </Rows>
      </Summary>
    </Main>
  );
};

export default Basket;
