import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts, Colors, Dimensions } from '../../Resources/Stylesheets/Variables';

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${props => (props.button ? '1fr' : '1fr 50px')};
  grid-template-rows: 50px;
  border-radius: ${Dimensions.cornerRadius};
  background: ${props => props.theme.background};
  border: 2px solid ${props => props.theme.border};
  color: ${props => props.theme.color};
  height: 50px;
`;

const Enter = styled.input`
  position: relative;
  appearance: none;
  outline: none;
  border: none;
  background: none;
  line-height: 50px;
  padding: 8px 10px 0 10px;
  font-size: ${Fonts.sizeSmall};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  z-index: 1;
  color: ${props => props.theme.color};

  &:focus {
    outline: none;
  }
`;

const Header = styled.i`
  position: absolute;
  top: ${props => (props.active ? '2px' : '-13px')};
  left: 10px;
  font-size: ${props => (props.active ? Fonts.sizeTiny : '0.7rem')};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  transition: 300ms ease-out;
  line-height: 50px;
  z-index: 0;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.blueberry};
  cursor: pointer;
`;

const Input = forwardRef(
  ({ label, type, name, value, onChange, required, pattern, minLength, maxLength, button }, ref) => {
    const [isEmpty, setIsEmpty] = useState(!value);

    return (
      <Container button={button === null}>
        <Header active={!value && isEmpty}>{label}</Header>
        <Enter
          ref={ref}
          type={type}
          name={name}
          required={required}
          pattern={pattern}
          minLength={minLength}
          maxLength={maxLength}
          onChange={onChange}
          value={value || ''}
          onFocus={() => setIsEmpty(false)}
          onBlur={e => setIsEmpty(!e.target.value)}
        />
        {button && (
          <Button onClick={button}>
            <p className="icon-search" />
          </Button>
        )}
      </Container>
    );
  }
);

Input.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  button: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Input.defaultProps = {
  name: null,
  required: false,
  pattern: null,
  minLength: 0,
  maxLength: PropTypes.value,
  button: null,
  value: ''
};

export default Input;
