import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Cancel, Main } from '../../Styles/Styles';
import Loader from '../../Components/Loader/Loader';
import PageNotFound from '../PageNotFound/PageNotFound';
import Header from '../../Components/Header/Header';
import Customer from './Components/Customer';
import Summary from './Components/Summary';
import { Media } from '../../Resources/Stylesheets/Variables';
import { CONFERENCE, FIELD, CAMPING, ITEM, LOCKER, COMPANY, ADMIN } from '../../Library/Variables';
import { groupBy2 } from '../../Library/Library';
import PopOverForm from '../../Components/PopOver/PopOverForm';
import EditReference from './Components/EditReference';
import DeleteReference from './Components/DeleteReference';
import { useAddBasketToBookingReference, useGetBookingReference } from '../../Library/Hooks';
import Product from './Components/Product';
import Grouped from './Components/Grouped';
import { useAfterSuccess } from '../../Hooks';
import PopOver from '../../Components/PopOver/PopOver';
import InvoiceExport from '../../Components/InvoiceExport/InvoiceExport';
import { deleteBasket } from '../../Reducers/Basket/actions';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(${Media.phone}, 1200px);
  grid-auto-rows: max-content;
  justify-content: center;
  grid-gap: 30px;
  margin-bottom: 30px;
`;

const Items = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

const Reference = () => {
  const dispatch = useDispatch();
  const { match } = useReactRouter();
  const { loading, error, data, request } = useGetBookingReference({ id: match.params.referenceID });
  const customer = data ? { ...data.customer, comment: data.comment } : null;
  const [search, setSearch] = useState('');
  const [edit, viewEdit] = useState(false);
  const [remove, viewRemove] = useState(false);
  const [exporting, showExport] = useState(false);
  const { request: addFromBasket } = useAfterSuccess(useAddBasketToBookingReference(), () => {
    request();
    dispatch(deleteBasket());
  });
  const { role } = useSelector(state => state.profile);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <PageNotFound />;
  }

  const containsBookings = data.bookedProducts && data.bookedProducts.length > 0;

  const housings =
    containsBookings &&
    data.bookedProducts.filter(e => e.type === CAMPING && `${e.name.toLowerCase()}`.includes(search));

  const fields =
    containsBookings && data.bookedProducts.filter(e => e.type === FIELD && `${e.name.toLowerCase()}`.includes(search));
  const fieldsSorted = fields && fields.sort((e1, e2) => (e1.startTime > e2.startTime ? 1 : -1));
  const fieldsGrouped = fieldsSorted && groupBy2(fieldsSorted, 'spec', 'groupID');

  const lockers =
    containsBookings &&
    data.bookedProducts.filter(e => e.type === LOCKER && `${e.name.toLowerCase()}`.includes(search));
  const lockersSorted = lockers && lockers.sort((e1, e2) => (e1.startTime > e2.startTime ? 1 : -1));
  const lockersGrouped = lockersSorted && groupBy2(lockersSorted, 'spec', 'groupID');

  const conferences =
    containsBookings &&
    data.bookedProducts.filter(e => e.type === CONFERENCE && `${e.name.toLowerCase()}`.includes(search));
  const conferencesSorted = conferences && conferences.sort((e1, e2) => (e1.startTime > e2.startTime ? 1 : -1));
  const conferencesGrouped = conferencesSorted && groupBy2(conferencesSorted, 'spec', 'groupID');

  const items =
    containsBookings && data.bookedProducts.filter(e => e.type === ITEM && `${e.name.toLowerCase()}`.includes(search));

  const addBasket = () => addFromBasket({ referenceID: match.params.referenceID });

  const invoice = data.customerType === COMPANY && role === ADMIN.type && (
    <Button key="export" value="Fakturaunderlag" onClick={() => showExport(true)} />
  );

  return (
    <Main>
      <Container>
        <Header
          title={`Bokning ${data.referenceID.toUpperCase()}`}
          extra={[
            invoice,
            <Button key="add" value="Lägg till från varukorg" onClick={addBasket} />,
            <Button key="edit" value="Redigera" onClick={() => viewEdit(true)} />,
            <Cancel key="remove" value="Radera" onClick={() => viewRemove(true)} />
          ]}
        />
        <Customer data={customer} type={data.customerType} />
        <Header title="Sammanställning" />
        <Summary data={data} />
        <Header title="Bokade objekt" search={setSearch} />
        <Items>
          {housings && housings.length > 0 && (
            <>
              <h4>Boende</h4>
              {housings.map(e => (
                <Product key={e.id} data={e} refetch={request} />
              ))}
            </>
          )}
          {fieldsGrouped && fields.length > 0 && (
            <>
              <h4>Ytor</h4>
              {Object.entries(fieldsGrouped).map(([key, e]) => {
                if (key === 'null') {
                  return e.map(e1 => <Product key={e1.id} data={e1} refetch={request} />);
                }
                return <Grouped key={key} data={e} refetch={request} />;
              })}
            </>
          )}
          {lockersGrouped && lockers.length > 0 && (
            <>
              <h4>Omklädningsrum</h4>
              {Object.entries(lockersGrouped).map(([key, e]) => {
                if (key === 'null' || key === 'undefined') {
                  return e.map(e1 => <Product key={e1.id} data={e1} refetch={request} />);
                }
                return <Grouped key={key} data={e} refetch={request} />;
              })}
            </>
          )}
          {conferencesGrouped && conferences.length > 0 && (
            <>
              <h4>Konferensrum</h4>
              {Object.entries(conferencesGrouped).map(([key, e]) => {
                if (key === 'null' || key === 'undefined') {
                  return e.map(e1 => <Product key={e1.id} data={e1} refetch={request} />);
                }
                return <Grouped key={key} data={e} refetch={request} />;
              })}
            </>
          )}
          {items && items.length > 0 && (
            <>
              <h4>Artiklar</h4>
              {items.map(e => (
                <Product key={e.id} data={e} refetch={request} />
              ))}
            </>
          )}
        </Items>
      </Container>
      {edit && (
        <PopOverForm
          data={{
            id: data.referenceID,
            name: <h4>{data.referenceID.toUpperCase()}</h4>,
            image: data.image,
            initials: 'B',
            title: 'Redigerar bokning',
            text: '',
            submitLabel: 'Spara'
          }}
          Form={EditReference}
          formID="EditReference"
          formData={data}
          onCancel={() => viewEdit(false)}
          refetch={request}
        />
      )}
      {remove && (
        <PopOverForm
          data={{
            id: data.referenceID,
            name: <h4>{data.referenceID.toUpperCase()}</h4>,
            image: data.image,
            initials: 'B',
            title: 'Vill du verkligen ta bort denna bokning?',
            text: `Denna åtgärd går inte att ångra.`,
            submitLabel: 'Radera bokning'
          }}
          Form={DeleteReference}
          formID="DeleteReference"
          formData={data}
          onCancel={() => viewRemove(false)}
        />
      )}
      {exporting && (
        <PopOver
          data={{ ...data, customerID: data.customer.customerID }}
          Content={InvoiceExport}
          close={() => showExport(false)}
        />
      )}
    </Main>
  );
};

export default Reference;
