import { BOOKING_REFERENCE, PRODUCT } from '../../Library/Variables';
import { ADD_COUPON, DEL_COUPON, INIT_COUPONS, UPDATE_COUPON } from './actions';

const init = {
  product: [],
  bookingReference: []
};

export const getName = type => {
  switch (type) {
    case PRODUCT:
      return 'product';
    case BOOKING_REFERENCE:
      return 'bookingReference';
    default:
      throw Error('Okänd product type');
  }
};

const products = (state = init, action) => {
  switch (action.type) {
    case INIT_COUPONS: {
      const h = action.payload;
      return {
        ...h.reduce((s, e) => {
          const name = getName(e.type);
          state[name] = [...state[name], e];
          return state;
        }, init)
      };
    }

    case UPDATE_COUPON: {
      const product = action.payload;
      const name = getName(product.type);
      state[name] = state[name].map(e => (e.id === product.id ? { ...e, ...product } : e));
      return { ...state };
    }

    case ADD_COUPON: {
      const product = action.payload;

      const name = getName(product.type);
      const dup = state[name].find(e => e.id === product.id);
      if (dup) {
        return state;
      }
      state[name] = [...state[name], product];
      return { ...state };
    }

    case DEL_COUPON: {
      const product = action.payload;
      Object.keys(state).forEach(name => {
        state[name] = state[name].filter(e => e.id !== product.id);
      });

      return { ...state };
    }

    default:
      return state;
  }
};

export default products;
