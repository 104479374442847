import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Dimensions, Colors, Fonts } from '../../Resources/Stylesheets/Variables';
import { rgba } from '../../Library/Library';

const FormButton = styled.button`
  position: relative;
  outline: none;
  appearance: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.accent};
  color: ${Colors.white};
  padding: 0 10px;
  height: 40px;
  min-width: 100px;
  border-radius: ${Dimensions.cornerRadius};
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSmall};
  font-weight: ${Fonts.weightRegular};
  cursor: pointer;
`;

const LinkButton = styled(NavLink)`
  position: relative;
  outline: none;
  appearance: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.accent};
  color: ${Colors.white};
  padding: 0 10px;
  height: 40px;
  min-width: 100px;
  border-radius: ${Dimensions.cornerRadius};
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSmall};
  font-weight: ${Fonts.weightRegular};
  cursor: pointer;
`;

const Ripple = styled.svg`
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  fill: ${props => rgba(0.4, props.theme.ripple)};
  border-radius: ${Dimensions.cornerRadius};
`;

const dur = '350ms';

/**
 * Styled button with ripple effect.
 * @param {string} value text on the button.
 * @param {func} onClick function to be run on click.
 * @param {string} type usually submit, use with form if not within form tag.
 * @param {string} form id of the form to submit.
 * @param {string} to if the button should be a link.
 * @param {string} className to enable styled components.
 */
const Button = ({ value, onClick, type, form, to, className }) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [dx, setDx] = useState(1);
  const [dy, setDy] = useState(1);
  const [update, setUpdate] = useState(false);

  const doRipple = async e => {
    setDx(e.nativeEvent.offsetX);
    setDy(e.nativeEvent.offsetY);
    setHeight(e.target.offsetHeight);
    setWidth(e.target.offsetWidth);
    setUpdate(u => !u);
  };

  const content = (
    <>
      {value}
      <Ripple viewBox={`0 0 ${width} ${height}`} key={update}>
        <circle cx={dx} cy={dy} r="0%" opacity="1">
          <animate attributeName="r" begin="0s" dur={dur} repeatCount="1" from="0%" to="100%" />
          <animate attributeName="opacity" begin="0s" dur={dur} repeatCount="1" from="1" to="0" />
        </circle>
      </Ripple>
    </>
  );

  if (to) {
    if (to === '/') {
      return (
        <LinkButton className={className} exact to={to} onMouseDown={doRipple}>
          {content}
        </LinkButton>
      );
    }
    return (
      <LinkButton className={className} to={to} onMouseDown={doRipple}>
        {content}
      </LinkButton>
    );
  }

  if (type && form) {
    return (
      <FormButton className={className} type={type} form={form} onMouseDown={doRipple}>
        {content}
      </FormButton>
    );
  }

  if (type) {
    return (
      <FormButton className={className} type={type} onClick={onClick} onMouseDown={doRipple}>
        {content}
      </FormButton>
    );
  }

  return (
    <FormButton className={className} onMouseDown={doRipple} onClick={onClick}>
      {content}
    </FormButton>
  );
};

Button.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  form: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

Button.defaultProps = {
  value: '',
  onClick: null,
  type: null,
  form: null,
  to: null,
  className: null
};

export default Button;
