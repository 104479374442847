import { useState, useRef, useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useComponentVisible = init => {
  const [visible, setVisible] = useState(init);
  const ref = useRef(null);

  const handleHideDropdown = event => {
    if (event.key === 'Escape') {
      setVisible(false);
    }
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, visible, setVisible };
};
