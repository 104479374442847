import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dimensions, Fonts } from '../../Resources/Stylesheets/Variables';
import { Ripple } from '../../Styles/Styles';

const Component = styled.div`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.cornerRadius};
  transition: 300ms ease-out;
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 125px max-content 1fr 50px;
  grid-template-areas: ${props =>
    props.haveButton
      ? `'image image' 'name name' 'rows rows' 'details button'`
      : `'image image' 'name name' 'rows rows' 'details details'`};
`;

const Image = styled.div`
  grid-area: image;
  background: ${props => `url(${props.src})`} center;
  background-size: cover;
  border-radius: ${Dimensions.cornerRadius} ${Dimensions.cornerRadius} 0 0;
  color: ${props => props.theme.color};
`;

const Initials = styled.div`
  grid-area: image;
  display: grid;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.accent};
  font-size: ${Fonts.sizeLarge};
  font-family: ${Fonts.head};
  font-weight: ${Fonts.weightSemiBold};
  border-radius: ${Dimensions.cornerRadius} ${Dimensions.cornerRadius} 0 0;
  color: ${props => props.theme.color};
`;

const Name = styled.h4`
  grid-area: name;
  padding: 15px;
`;

const Rows = styled.div`
  grid-area: rows;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 10px;
  padding: 0 15px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 10px;
`;

const Details = styled.div`
  grid-area: details;
  align-items: center;
  display: grid;
  grid-auto-columns: 30px;
  grid-auto-flow: column;
  padding: 0 15px;
  color: ${props => props.theme.color};
  border-top: 2px solid ${props => props.theme.border};
  border-right: ${props => (props.haveButton ? `2px solid ${props.theme.border}` : 'none')};
`;

export const CardButton = styled(Ripple)`
  grid-area: button;
  border-top: 2px solid ${props => props.theme.border};
  border-radius: 0;
  color: ${props => (props.isNegative ? props.theme.negative : props.theme.accent)};
  i {
    font-size: ${Fonts.sizeRegular};
  }
`;

const Card = ({ image, name, rows, details, button }) => {
  const initials = name.charAt(0).toUpperCase();
  return (
    <Component haveButton={button}>
      {image ? <Image src={image} /> : <Initials>{initials}</Initials>}
      <Name>{name}</Name>
      <Rows>
        {rows.map(e => (
          <Row key={e.label}>
            <p className={e.icon} />
            <p>{e.label}</p>
            <p>{e.value}</p>
          </Row>
        ))}
      </Rows>
      <Details haveButton={button}>
        {details.map((e, i) => {
          return e && <p key={i.toString()} className={e} />;
        })}
      </Details>
      {button}
    </Component>
  );
};

Card.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  details: PropTypes.arrayOf(PropTypes.string),
  button: PropTypes.element
};

Card.defaultProps = {
  image: null,
  details: [],
  button: null
};

export default Card;
