import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useReactRouter from 'use-react-router';
import { Colors, Fonts, Media } from '../../Resources/Stylesheets/Variables';
import { cap, getBookingName, rgba } from '../../Library/Library';
import { Container as Template, ProductColumn } from '../../Styles/Styles';

const Container = styled(Template)`
  grid-column: span 6;
  grid-row: span ${props => props.length + 3};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr;
  padding: 20px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-column-start: 1;
    grid-column-end: end;
  }
`;

const Items = styled.div`
  display: flex;
  flex-flow: column;
`;

const Value = styled.div`
  height: 50px;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  cursor: pointer;
  grid-gap: 10px;
`;

const Icon = styled.i`
  color: ${props => props.color};
  font-size: 20px;
  background: ${props => rgba(0.3, props.color)};
  border-radius: 50%;
  border: 2px solid ${props => rgba(0.1, props.color)};
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms ease-in-out;
`;

const Name = styled.i`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: ${Fonts.weightBold};
`;

const List = ({ spec }) => {
  const { history } = useReactRouter();

  const getIconColor = type => {
    switch (type) {
      case 'camp':
        return Colors.kiwi;
      case 'conf':
        return Colors.blueberry;
      case 'field':
        return Colors.physalis;
      default:
        return Colors.blueberry;
    }
  };

  return (
    <Container length={spec.data.length}>
      <h5>{spec.label}</h5>
      <Items length={spec.data.length}>
        {spec.data.map(b => (
          <Value
            key={Math.random()}
            onClick={() => {
              history.push(`/bookingReference/${b.referenceID}`);
            }}
          >
            <Icon className="icon-book" color={getIconColor(b.type)} />
            <ProductColumn>
              <Name>{cap(getBookingName(b.customer, b.customerType))}</Name>
              <i>{b.referenceID.toUpperCase()}</i>
            </ProductColumn>
          </Value>
        ))}
      </Items>
    </Container>
  );
};

List.propTypes = {
  spec: PropTypes.shape({
    label: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['camp', 'conf', 'field']),
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        referenceID: PropTypes.string
      })
    ).isRequired
  }).isRequired
};

export default List;
