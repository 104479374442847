import {
  SET_TODAY_BOOKINGS,
  SET_CURRENT_BOOKINGS,
  SET_LATEST_BOOKINGS,
  UPDATE_BOOKING,
  ADD_BOOKING,
  DEL_BOOKING,
  DEL_BOOKINGS
} from './actions';

const initialState = {
  today: [],
  current: [],
  latest: []
};

function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

const bookingClassifier = b => {
  const { bookedProducts } = b;

  let current = false;
  let today = false;
  const now = new Date();
  if (bookedProducts)
    bookedProducts.forEach(({ startTime, endTime }) => {
      if (startTime && endTime) {
        const st = new Date(Date.parse(startTime));
        const et = new Date(Date.parse(endTime));
        if (sameDay(now, st)) today = true;
        if (now.getTime() >= st.getTime() && now.getTime() <= et.getTime()) current = true;
      }
    });
  return { current, today };
};

const updateBooking = (oldBookings, newBooking) => [
  newBooking,
  ...oldBookings.filter(({ referenceID }) => referenceID !== newBooking.referenceID)
];

function bookings(state = initialState, action) {
  switch (action.type) {
    case SET_TODAY_BOOKINGS:
      state.today = action.payload;
      return { ...state };
    case SET_CURRENT_BOOKINGS:
      state.current = action.payload;
      return { ...state };
    case SET_LATEST_BOOKINGS:
      state.latest = action.payload;
      return { ...state };
    case ADD_BOOKING:
    case UPDATE_BOOKING: {
      const { current, today } = bookingClassifier(action.payload);
      state.latest = updateBooking(state.latest, action.payload);
      if (current) state.current = updateBooking(state.current, action.payload);
      if (today) state.today = updateBooking(state.today, action.payload);
      return { ...state };
    }
    case DEL_BOOKING:
      state.current = state.current.filter(({ id }) => id !== action.payload.id);
      state.latest = state.latest.filter(({ id }) => id !== action.payload.id);
      state.today = state.today.filter(({ id }) => id !== action.payload.id);
      return { ...state };
    case DEL_BOOKINGS:
      return initialState;
    default:
      return { ...state };
  }
}

export default bookings;
