import React from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { popErr } from '../../../Reducers/Error/actions';
import { Colors, Fonts, Dimensions, ZIndex } from '../../../Resources/Stylesheets/Variables';

const MyError = styled.div`
  position: fixed;
  bottom: -50px;
  left: calc(50vw - 150px);
  transition: all 700ms ease-out;
  width: 300px;
  box-sizing: border-box;
  opacity: 0;
  z-index: ${ZIndex.dropdown};
  background: ${props => (props.type === 'error' ? Colors.strawberry : Colors.kiwi)};
  color: ${Colors.white};
  min-height: ${Dimensions.navigationHeight};
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeTiny};
  text-align: center;
  border-radius: ${Dimensions.cornerRadius};
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade 3s;
  box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
  @keyframes fade {
    0% {
      opacity: 0;
      bottom: -50px;
    }

    20% {
      opacity: 1;
      bottom: 50px;
    }

    80% {
      opacity: 1;
      bottom: 50px;
    }

    100% {
      opacity: 0;
      bottom: -50px;
    }
  }
`;

const Errors = () => {
  const dispatch = useDispatch();
  const errors = useSelector(state => state.errors);
  if (errors.length === 0) {
    return <></>;
  }
  const err = errors[0];
  return createPortal(
    <MyError type={err.type} key={err.id} onAnimationEnd={() => dispatch(popErr())}>
      {err.text}
    </MyError>,
    document.querySelector('#modal')
  );
};

export default Errors;
