import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useForm from '../../../Hooks/useForm';
import Input from '../../../Components/Forms/Input';
import { Button, Cancel, Ripple } from '../../../Styles/Styles';
import { cap, getRandomString } from '../../../Library/Library';
import { useAddMark, useEditMark } from '../../../Library/Hooks';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import { addErr } from '../../../Reducers/Error/actions';
import { PRODUCTS } from '../../../Library/Variables';
import Option from '../../../Components/Forms/Option';
import { useAfterSuccess } from '../../../Hooks';

const Container = styled.form`
  display: grid;
  grid-template-columns: minmax(250px, 300px) minmax(250px, 300px) minmax(250px, 300px);
  grid-template-rows: max-content 1fr max-content;
  padding: 30px;
  grid-gap: 30px;
  grid-template-areas: 'header header header' '. . .' 'tools tools tools';
  height: 500px;
`;

const Header = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

const Column = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

const ScrollColumn = styled(Column)`
  overflow-y: auto;
`;

const Split = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  grid-gap: 5px;
  input {
    width: auto;
    min-width: auto;
    max-width: 100px;
  }
`;

const Customer = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-gap: 5px;
`;

const Add = styled(Ripple)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  height: 55px;
  color: ${props => props.theme.accent};
  i {
    font-size: ${Fonts.sizeRegular};
  }
`;

const Delete = styled(Ripple)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.negative};
  i {
    font-size: ${Fonts.sizeRegular};
  }
`;

const Tools = styled.div`
  grid-area: tools;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 15px;
`;

const getValues = (o, str) =>
  Object.entries(o)
    .filter(([key]) => key.includes(str))
    .map(([, value]) => value);

const getInitSplit = mark => {
  if (!mark) return [getRandomString(10)];
  return mark.value.split.map(() => getRandomString(10));
};
const getInitCustomers = mark => {
  if (!mark) return [getRandomString(10)];
  return mark.customers.map(() => getRandomString(10));
};

const CreateMark = ({ data, close }) => {
  const [split, setSplit] = useState(getInitSplit(data.mark));
  const [customers, setCustomers] = useState(getInitCustomers(data.mark));
  const mark = data.mark || {};
  const getDefaultForm = () => {
    const body = {
      name: mark.name,
      type: mark.type.map(e => PRODUCTS.find(x => e === x.type)).filter(e => e),
      percent: mark.value.percent,
      customers: mark.customers
    };
    mark.value.split.forEach((e, i) => {
      body[`splitRowPercent${i}`] = e.percent;
      body[`splitRowCustomerID${i}`] = e.customerID;
    });
    mark.customers.forEach((e, i) => {
      body[`customerRowCustomerID${i}`] = e.customerID;
    });

    return body;
  };

  const { values, handleChange, deleteValue } = useForm(data.mark ? getDefaultForm() : { type: [] });
  const add = useAfterSuccess(useAddMark(), close);
  const edit = useAfterSuccess(useEditMark(), close);
  const { request } = data.mark ? edit : add;
  const dispatch = useDispatch();

  const handleSubmit = async e => {
    e.preventDefault();

    const splitRowPercents = getValues(values, 'splitRowPercent');
    const splitRowCustomerIDs = getValues(values, 'splitRowCustomerID');
    const customerRowCustomerIDs = getValues(values, 'customerRowCustomerID');

    const s = splitRowPercents.map((x, i) => ({ customerID: splitRowCustomerIDs[i], percent: parseInt(x, 10) }));
    const p = 100 - s.reduce((x, y) => x + y.percent, 0);
    const c = customerRowCustomerIDs.map((x, i) => {
      const body = {
        customerID: x
      };

      if (mark && mark.customers && mark.customers[i]) body.id = mark.customers[i].id;
      return body;
    });

    if (c.length === 0) {
      dispatch(addErr('Kunder som det gäller för måste fyllas i.'));
      return;
    }

    if (p < 0) {
      dispatch(addErr('Summan av alla andelar måste vara max 100.'));
      return;
    }

    request({
      ...mark,
      name: values.name,
      type: values.type.map(({ type }) => type),
      value: {
        percent: p,
        split: s
      },
      customers: c
    });
  };

  const addRow = fun => {
    fun(v => [...v, getRandomString(10)]);
  };

  const deleteRow = (del, i, id, percent) => {
    del(v => {
      v[i] = undefined;
      return [...v];
    });
    deleteValue(id);
    deleteValue(percent);
  };

  const splitRowPercents = getValues(values, 'splitRowPercent');
  const s = splitRowPercents.map(x => ({ percent: parseInt(x, 10) }));
  const p = 100 - s.reduce((x, y) => x + y.percent, 0);

  return (
    <Container onSubmit={handleSubmit}>
      <Header>
        <h4>{cap(data.title)}</h4>
      </Header>
      <Column>
        <h5>Allmänt</h5>
        <Input
          label="Namn"
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          required
          minLength={1}
          maxLength={50}
        />
        <Option value={values.type} onChange={handleChange} name="type" label="Produkter" options={PRODUCTS} />
        {p !== 100 && (
          <>
            <h5>Information</h5>
            <p>{`Kunden betalar ${p} %.`}</p>
          </>
        )}
      </Column>
      <ScrollColumn>
        <h5>Fördelas på</h5>
        {split.map(
          (e, i) =>
            e && (
              <Split key={e}>
                <Input
                  label="Kund ID"
                  type="text"
                  name={`splitRowCustomerID${i}`}
                  value={values[`splitRowCustomerID${i}`]}
                  onChange={handleChange}
                  required
                  minLength={1}
                  maxLength={50}
                />
                <Input
                  label="Andel"
                  type="text"
                  name={`splitRowPercent${i}`}
                  value={values[`splitRowPercent${i}`]}
                  onChange={handleChange}
                  required
                  minLength={1}
                  maxLength={100}
                />
                <Delete
                  type="button"
                  value={<i className="icon-trash" />}
                  onClick={() => deleteRow(setSplit, i, `splitRowCustomerID${i}`, `splitRowPercent${i}`)}
                />
              </Split>
            )
        )}
        <Add type="button" value={<i className="icon-plus" />} onClick={() => addRow(setSplit)} />
      </ScrollColumn>
      <ScrollColumn>
        <h5>Gäller för</h5>
        {customers.map(
          (e, i) =>
            e && (
              <Customer key={e}>
                <Input
                  label="Kund ID"
                  type="text"
                  name={`customerRowCustomerID${i}`}
                  value={values[`customerRowCustomerID${i}`]}
                  onChange={handleChange}
                  required
                  minLength={1}
                  maxLength={50}
                />
                <Delete
                  type="button"
                  value={<i className="icon-trash" />}
                  onClick={() => deleteRow(setCustomers, i, `customerRowCustomerID${i}`)}
                />
              </Customer>
            )
        )}
        <Add type="button" value={<i className="icon-plus" />} onClick={() => addRow(setCustomers)} />
      </ScrollColumn>
      <Tools>
        <Cancel type="button" value="Avbryt" onClick={close} />
        <Button type="submit" value={data.submitLabel} />
      </Tools>
    </Container>
  );
};

CreateMark.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
    .isRequired,
  close: PropTypes.func.isRequired
};

export default CreateMark;
