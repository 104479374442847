export const ADD_BASKET_ITEM = 'ADD_BASKET_ITEM';
export const DEL_BASKET_ITEM = 'DEL_BASKET_ITEM';
export const DEL_BASKET_ITEM_BY_PROD_ID = 'DEL_BASKET_ITEM_BY_PROD_ID';
export const UPDATE_BASKET_ITEM_VAT = 'UPDATE_BASKET_ITEM_VAT';
export const UPDATE_BASKET_ITEM = 'UPDATE_BASKET_ITEM';
export const INIT_BASKET = 'INIT_BASKET';
export const DELETE_BASKET = 'DELETE_BASKET';

export const addBasketItem = payload => ({
  type: ADD_BASKET_ITEM,
  payload
});

export const updateBasketItem = payload => ({
  type: UPDATE_BASKET_ITEM,
  payload
});

export const updateBasketItemVat = payload => ({
  type: UPDATE_BASKET_ITEM_VAT,
  payload
});

export const deleteBasketItem = id => ({
  type: DEL_BASKET_ITEM,
  payload: id
});

export const deleteBasketItemByProductID = id => ({
  type: DEL_BASKET_ITEM_BY_PROD_ID,
  payload: id
});

export const initBasket = payload => ({
  type: INIT_BASKET,
  payload
});

export const deleteBasket = () => ({
  type: DELETE_BASKET
});
