import React, { useReducer, useEffect, useRef } from 'react';
import useReactRouter from 'use-react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import {
  CONFERENCE,
  LOCKER,
  FIELD,
  FIELD_FULL,
  FIELD_MATCH,
  FIELD_HALF,
  FIELD_ONE_THIRD,
  FIELD_TWO_THIRD,
  HOUR_LABELS
} from '../../Library/Variables';
import { Avatar, Button, Main as Template, Ripple } from '../../Styles/Styles';
import { Colors, Dimensions, Fonts, Media, ZIndex } from '../../Resources/Stylesheets/Variables';
import { useGetOccupiedProducts } from '../../Library/Hooks';
import { cap, getWeekDates, getWeekNumber, isSameDate } from '../../Library/Library';
import Loader from '../Loader/Loader';
import Timeline from './Components/Timeline';
import WeekDay from './Components/Weekday';
import PopOver from '../PopOver/PopOver';
import FieldBookingForm from './Components/FieldBookingForm';
import CalendarBookingForm from './Components/CalendarBookingForm';

const Main = styled(Template)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${Dimensions.navigationHeight} 30px max-content 1fr;
  grid-template-areas: 'menu' '.' 'labels' 'content';
  overflow-y: hidden;
  @media (max-width: ${Media.phone}) {
    grid-template-rows: max-content 30px max-content 1fr;
  }
`;

const Menu = styled.div`
  grid-area: menu;
  display: grid;
  grid-template-columns: max-content max-content max-content 1fr max-content 1fr max-content;
  grid-template-rows: 1fr;
  grid-template-areas: 'back image name . title . navigation';
  align-items: center;
  grid-gap: 10px;
  @media (max-width: ${Media.phone}) {
    grid-template-columns: max-content max-content 1fr;
    grid-template-rows: max-content max-content max-content;
    grid-template-areas: 'back image name' 'title title title' 'navigation navigation navigation';
  }
`;

const Labels = styled.div`
  grid-area: labels;
  display: grid;
  grid-template-columns: 55px repeat(7, 1fr);
  grid-template-rows: 1fr;
  border-bottom: 2px solid ${props => props.theme.border};
  text-align: center;
`;

const Content = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 40px 15px repeat(7, 1fr);
  grid-template-rows: 1fr;
  overflow-y: auto;
  @media (max-width: ${Media.phone}) {
    grid-template-columns: 40px 15px repeat(7, calc(100% - 55px));
  }
`;

const Back = styled(Ripple)`
  grid-area: back;
  color: ${props => props.theme.color};
  margin: 0;
  width: 20px;
`;

const Name = styled.h3`
  grid-area: name;
`;

const Title = styled.h3`
  grid-area: title;
`;

const Navigation = styled.div`
  grid-area: navigation;
  display: grid;
  grid-template-columns: max-content 40px 40px;
  grid-gap: 5px;
`;

const Today = styled(Button)``;

const Previous = styled(Button)`
  height: 100%;
  width: 100%;
  min-width: auto;
  padding: 0;
  i {
    font-size: 1.3rem;
  }
`;

const Next = styled(Button)`
  height: 100%;
  width: 100%;
  min-width: auto;
  padding: 0;
  i {
    font-size: 1.3rem;
  }
`;

const Label = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  color: ${props => (props.active ? props.theme.accent : props.theme.color)};
  @media (max-width: ${Media.phone}) {
    display: none;
  }
`;

const Ticks = styled.div`
  background: ${props => props.theme.main};
  display: grid;
  grid-template-rows: repeat(288, 8px);
  grid-auto-flow: row;
  z-index: 20;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    position: sticky;
    left: 0;
  }
`;

const Tick = styled.h6`
  font-family: ${Fonts.mono};
  grid-row-start: ${props => (props.start === 0 ? 1 : Math.ceil(props.start * 12) - 1)};
  font-size: 12px;
`;

const Time = styled.div`
  background: ${props => props.theme.main};
  display: grid;
  grid-template-rows: repeat(288, 8px);
  grid-auto-flow: row;
  z-index: ${ZIndex.dropdown};
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    position: sticky;
    left: 40px;
  }
`;

const fieldSelector = id =>
  createSelector(
    state => state.products.fields,
    state => state.find(e => e.id === id)
  );
const lockerSelector = id =>
  createSelector(
    state => state.products.lockers,
    state => state.find(e => e.id === id)
  );
const conferenceSelector = id =>
  createSelector(
    state => state.products.conferences,
    state => state.find(e => e.id === id)
  );

const getSelector = type => {
  switch (type) {
    case FIELD:
      return fieldSelector;
    case LOCKER:
      return lockerSelector;
    case CONFERENCE:
      return conferenceSelector;
    default:
      return null;
  }
};

const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const cells = [...Array(24).keys()];

const getSize = version => {
  switch (version) {
    case FIELD_FULL.type:
    case FIELD_MATCH.type:
      return 6;
    case FIELD_HALF.type:
      return 3;
    case FIELD_ONE_THIRD.type:
      return 2;
    case FIELD_TWO_THIRD.type:
      return 4;
    default:
      return 6;
  }
};

const getColor = version => {
  switch (version) {
    case FIELD_FULL.type:
    case FIELD_MATCH.type:
      return Colors.blueberry;
    case FIELD_HALF.type:
      return Colors.kiwi;
    case FIELD_ONE_THIRD.type:
      return Colors.physalis;
    case FIELD_TWO_THIRD.type:
      return Colors.strawberry;
    default:
      return Colors.gray;
  }
};

let ind = 0;
const format = (b, type) => {
  const startTime = new Date(Date.parse(b.startTime));
  const endTime = new Date(Date.parse(b.endTime));
  return {
    id: (ind++).toString(),
    name: b.name,
    start: startTime.getHours() + (0.01 * startTime.getMinutes() * 100) / 60,
    end: endTime.getHours() + (0.01 * endTime.getMinutes() * 100) / 60,
    date: startTime,
    span: getSize(b.version),
    background: getColor(b.version),
    type,
    referenceID: b.referenceID,
    version: b.version,
    comment: b.comment && b.comment.toUpperCase()
  };
};

const countConcurrent = (li, elem, time, half) =>
  li.reduce(
    (s, e) =>
      e.column && e.id !== elem.id && e.end > time && e.start < time && (!half || e.span === 3) ? s + e.span : s,
    0
  );

const formatSize = xs => {
  const li = xs.sort((x, y) => (x.span > y.span ? 1 : -1));
  for (let i = 0; i < li.length; i++) {
    li[i].column = 1;
    if (li[i].span !== 6) {
      let largest = 0;
      for (let j = li[i].start; j < li[i].end; j += 0.1) {
        let count = 0;
        if (li[i].span === 3) {
          count = countConcurrent(li, li[i], j, true);
        } else {
          count = countConcurrent(li, li[i], j);
        }
        largest = count > largest ? count : largest;
      }
      if (li[i].span === 3) {
        li[i].column = 6 - largest + 1 - 3;
      } else {
        li[i].column = largest + 1;
      }
    }
  }
  return li;
};

const formatByDate = li => {
  const obj = { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] };
  li.forEach(e => {
    const day = new Date(Date.parse(e.date)).getDay() - 1;
    const weekDay = day === -1 ? 6 : day;
    return obj[weekDays[weekDay]].push(e);
  });
  weekDays.forEach(e => {
    obj[e] = formatSize(obj[e]);
  });
  return obj;
};

const formatOccupied = occupied => {
  const { basket, bookings } = occupied;
  const formattedBasket = basket.map(e => format(e, 'basket'));
  const formattedBookings = bookings.map(e => format(e, 'bookings'));

  return [...formattedBasket, ...formattedBookings];
};

const NEXT = 'NEXT';
const PREV = 'PREV';

const dateReducer = (state, action) => {
  switch (action) {
    case PREV:
      return new Date(state).setDate(new Date(state).getDate() - 7);
    case NEXT:
      return new Date(state).setDate(new Date(state).getDate() + 7);
    default:
      return new Date();
  }
};

const WEEK = 'WEEK';
const CELL = 'CELL';
const CREATE = 'CREATE';
const OCCUPIED = 'OCCUPIED';
const WIDTH = 'WIDTH';
const REFETCH = 'REFETCH';

const reducer = (state, action) => {
  switch (action.type) {
    case WEEK:
      return { ...state, week: action.payload };
    case CELL:
      return { ...state, cell: action.payload };
    case CREATE:
      return { ...state, creating: !state.creating };
    case OCCUPIED:
      return { ...state, occupiedDates: action.payload };
    case WIDTH:
      return { ...state, width: action.payload };
    case REFETCH:
      return { ...state, refetch: !state.refetch };
    default:
      throw new Error('Invalid reducer state');
  }
};

const Calendar = ({ type }) => {
  const { match, history } = useReactRouter();
  const { request, data } = useGetOccupiedProducts();
  const id = parseInt(match.params.id, 10);
  const selector = getSelector(type);
  const item = useSelector(selector(id));
  const { sessionToken } = useSelector(state => state.session);
  const form = type === FIELD ? FieldBookingForm : CalendarBookingForm;

  const [date, setDate] = useReducer(dateReducer, new Date());
  const prevDate = () => setDate(PREV);
  const nextDate = () => setDate(NEXT);

  const [{ week, cell, creating, occupiedDates, width, refetch }, dispatch] = useReducer(reducer, {
    week: [],
    cell: null,
    creating: false,
    occupiedDates: null,
    width: 0,
    refetch: false
  });

  const setWeek = payload => dispatch({ type: WEEK, payload });
  const setCell = payload => dispatch({ type: CELL, payload });
  const showCreate = () => dispatch({ type: CREATE });
  const setOccupiedDates = payload => dispatch({ type: OCCUPIED, payload });
  const setWidth = payload => dispatch({ type: WIDTH, payload });
  const setRefetch = () => dispatch({ type: REFETCH });

  const contentRef = useRef(null);
  const timeRef = useRef(null);

  window.onresize = () => {
    if (contentRef.current && timeRef.current) {
      setWidth(contentRef.current.clientWidth - 50);
    }
  };

  useEffect(() => {
    if (contentRef.current && timeRef.current) {
      if (width === 0) {
        setWidth(contentRef.current.clientWidth - 50);
      }
      contentRef.current.scrollTo(0, timeRef.current.offsetTop - contentRef.current.clientHeight / 2);
    }
  });

  useEffect(() => {
    setWeek(getWeekDates(date));
  }, [date]);

  useEffect(() => {
    if (data) setOccupiedDates(formatByDate(formatOccupied(data)));
  }, [data]);

  useEffect(() => {
    if (request && week.length > 0) {
      const startTime = new Date(week[0]);
      const endTime = new Date(week[6]);
      startTime.setHours(0, 0, 0, 0);
      endTime.setDate(endTime.getDate() + 1);
      endTime.setHours(0, 0, 0, 0);
      setOccupiedDates(null);
      request({
        id,
        startTime,
        endTime,
        type
      });
    }
  }, [dispatch, id, week, refetch, request, type]);

  if (!item || !occupiedDates)
    return (
      <Main>
        <Loader />
      </Main>
    );

  return (
    <Main>
      <Menu>
        <Back value="" className="icon-angle-left" onClick={() => history.goBack()} />
        <Avatar image={item.image} initials={item.name.charAt(0).toUpperCase()} size="50px" />
        <Name>{cap(item.name)}</Name>
        <Title>
          {`${cap(
            new Date(date).toLocaleDateString('sv-SV', { month: 'long', year: 'numeric' })
          )} Vecka ${getWeekNumber(week[0])}`}
        </Title>
        <Navigation>
          <Today value="Idag" onClick={() => setDate()} />
          <Previous value={<i className="icon-angle-left" />} onClick={prevDate} />
          <Next value={<i className="icon-angle-right" />} onClick={nextDate} />
        </Navigation>
      </Menu>
      <Labels>
        <Label>
          <i>Vecka</i>
          <h3>{getWeekNumber(week[0])}</h3>
        </Label>
        {week.map(v => (
          <Label key={v.toLocaleString()} active={isSameDate(new Date(), v)}>
            <i>{cap(v.toLocaleDateString('sv-SV', { weekday: 'long' }))}</i>
            <h3>{v.toLocaleDateString('sv-SV', { day: 'numeric' })}</h3>
          </Label>
        ))}
      </Labels>
      <Content ref={contentRef}>
        <Ticks>
          {HOUR_LABELS.map((h, i) => (
            <Tick key={i.toString()} start={i}>
              {h}
            </Tick>
          ))}
        </Ticks>
        <Time>
          <Timeline ref={timeRef} width={width} />
        </Time>
        {weekDays.map((name, i) => (
          <WeekDay
            key={name}
            cellsLength={cells.length}
            setCell={setCell}
            viewForm={showCreate}
            week={week[i]}
            bookings={occupiedDates[name]}
          />
        ))}
      </Content>
      {creating && sessionToken && (
        <PopOver
          Content={form}
          data={{ data: item, cell }}
          close={() => {
            showCreate();
            setRefetch(f => !f);
          }}
        />
      )}
    </Main>
  );
};

Calendar.propTypes = {
  type: PropTypes.oneOf([FIELD, CONFERENCE, LOCKER]).isRequired
};

export default Calendar;
