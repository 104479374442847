import styled from 'styled-components';
import Avatar, { Image, Initials } from './Avatar/Avatar';
import Button from './Button/Button';
import Tooltip from './Tooltip/Tooltip';
import { ZIndex, Shadows, Dimensions, Media, Fonts } from '../Resources/Stylesheets/Variables';
import { rgba } from '../Library/Library';

export { Button, Avatar, Tooltip, Image, Initials };

/**
 * Styled main container. Use this when creating a new container.
 */
export const Main = styled.div`
  grid-area: main;
  display: grid;
  background: ${props => props.theme.main};
  color: ${props => props.theme.color};
  overflow-y: auto;
  z-index: ${ZIndex.main};
  padding: 30px;
  position: relative;
  transition: 200ms ease-out;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    padding: 15px;
  }
`;

/**
 * Standard container style. Use when creating new content in main container.
 */
export const Container = styled.div`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.cornerRadius};
  transition: 300ms ease-out;
`;

/**
 * Styled dropdown container. Place your content within this.
 * @requires useComponentVisible from Hooks
 * @param reverse true if dropdown should align right else false
 */
export const Dropdown = styled(Container)`
  display: grid;
  position: absolute;
  ${props => {
    if (props.right) {
      return `right: 0;`;
    }
    if (props.left) {
      return `left: 0;`;
    }
    return `left: 0;`;
  }};
  ${props => {
    if (props.bottom) {
      return `bottom: 0;`;
    }
    if (props.top) {
      return `top: 0;`;
    }
    return ``;
  }};
  z-index: ${ZIndex.dropdown};
  box-shadow: ${Shadows.regular};
  box-sizing: border-box;
  margin-top: 5px;
`;

export const Submit = styled(Button)``;

export const Cancel = styled(Button)`
  background: ${props => props.theme.negative};
`;

export const Ripple = styled(Button)`
  display: grid;
  height: 100%;
  width: 100%;
  min-width: auto;
  padding: 0;
  font-size: ${Fonts.sizeRegular};
  background: none;
  color: ${props => props.theme.color};
  i {
    font-size: ${Fonts.sizeRegular};
  }
`;

/**
 * Styled circle icon with background of your chioce.
 * @param color of background
 */
export const Icon = styled.i`
  color: ${props => props.color};
  font-size: 30px;
  background: ${props => rgba(0.3, props.color)};
  border-radius: 50%;
  border: 2px solid ${props => rgba(0.1, props.color)};
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms ease-in-out;
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(auto-fill, 25px);
  grid-template-rows: repeat(auto-fill, 25px);
  grid-auto-columns: 25px;
  grid-auto-rows: 25px;

  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-auto-flow: row;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 25px);
    grid-template-rows: repeat(auto-fit, 25px);
    padding: 0 15px;
  }
`;

export const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 70px;
  align-items: center;
  grid-gap: 15px;
`;

export const ProductColumns = styled(Container)`
  display: grid;
  grid-template-columns: 150px 1.5fr 1.3fr repeat(${props => props.columns || 8}, 1fr) 70px 70px;
  grid-template-rows: 70px;
  padding-left: 15px;
  align-items: center;
  grid-gap: 10px;
`;

export const ProductColumn = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  align-items: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
`;

export const ProductLabel = styled.i``;

export const ProductValue = styled.h5``;

export const ProductEdit = styled(Ripple)`
  font-size: ${Fonts.sizeRegular};
  color: ${props => props.theme.color};
  cursor: pointer;
  border-radius: 0;
  &:hover {
    color: ${props => props.theme.accent};
  }
`;

export const ProductDelete = styled(Ripple)`
  &:hover {
    color: ${props => props.theme.negative};
  }
`;

export const SubProductContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 70px;
  align-items: center;
  grid-gap: 15px;
`;

export const SubProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-rows: 70px;
  align-items: center;
  grid-gap: 10px;
`;

export const SubProductColumns = styled(ProductColumns)`
  grid-template-columns: 1.5fr 1.3fr repeat(${props => props.columns || 8}, 1fr) 70px 70px;
`;
