import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Colors, Fonts } from '../../../../Resources/Stylesheets/Variables';

const Button = styled(NavLink)`
  grid-area: basket;
  position: relative;
  color: ${props => props.theme.color};
  font-size: ${Fonts.sizeRegular};

  &:hover,
  &:active {
    color: ${props => props.theme.accent};
  }
`;

const Dot = styled.div`
  background: ${Colors.strawberry};
  position: absolute;
  top: -1px;
  right: -1px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.background};
`;

const Basket = () => {
  const basket = useSelector(state => state.basket);
  return (
    <Button exact to="/basket" className="icon-basket">
      {basket.length > 0 && <Dot />}
    </Button>
  );
};

Button.displayName = 'Button';

export default Basket;
