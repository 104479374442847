import React, { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Main, Button } from '../../Styles/Styles';
import { Fonts, Media } from '../../Resources/Stylesheets/Variables';
import { CONFERENCE, FIELD, CAMPING, ITEM, LOCKER } from '../../Library/Variables';
import Header from '../../Components/Header/Header';
import Product from './Components/Product';
import PopOverForm from '../../Components/PopOver/PopOverForm';
import EditProduct from './Components/EditProduct';
import { camping, conference, field, item, locker } from './Components/Format';

const Component = styled(Main)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, max-content) 1fr;
  grid-template-areas: 'menu' 'tools' 'form' 'labels' 'content';
  grid-gap: 30px;
`;

const Menu = styled.div`
  grid-area: menu;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    overflow-x: auto;
  }
`;

const Tab = styled(Button)`
  background: ${props => !props.active && 'transparent'};
  color: ${props => !props.active && props.theme.color};
`;

const Labels = styled.div`
  grid-area: labels;
  display: grid;
  grid-template-columns: 60px repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-flow: column;
  grid-gap: 10px;
  padding-left: 15px;
`;

const Label = styled.p`
  font-size: ${Fonts.sizeSuperTiny};
  font-weight: ${Fonts.weightSemiBold};
  font-family: ${Fonts.head};
  text-transform: uppercase;
`;

const Content = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 10px;
  margin-bottom: 30px;
  overflow-y: auto;
`;

const formatSpec = type => {
  switch (type) {
    case CAMPING:
      return camping;
    case FIELD:
      return field;
    case CONFERENCE:
      return conference;
    case ITEM:
      return item;
    case LOCKER:
      return locker;
    default:
      return null;
  }
};

const CREATE = 'CREATE';
const IMAGE = 'IMAGE';

const reducer = (state, action) => {
  switch (action.type) {
    case CREATE:
      return { ...state, creating: !state.creating };
    case IMAGE:
      return { ...state, image: action.payload };
    default:
      throw new Error('Invalid reducer state');
  }
};

const Products = () => {
  const items = useSelector(state => state.products);
  const [products, setProducts] = useState(null);
  const [type, setType] = useState(localStorage.getItem('productsTab') || CAMPING);
  const [searchString, setSearchString] = useState('');
  const { labels, formatData, formatInput, formatSubmit } = formatSpec(type);
  const showPrice = type === CAMPING || type === ITEM;

  const [{ creating, image }, dispatch] = useReducer(reducer, {
    creating: false,
    image: null
  });
  const showCreate = () => dispatch({ type: CREATE });

  const setImage = payload => dispatch({ type: IMAGE, payload });
  const deleteImage = () => dispatch({ type: IMAGE, payload: null });

  useEffect(() => {
    localStorage.setItem('productsTab', type);
    switch (type) {
      case CAMPING:
        setProducts(items.camping);
        break;
      case FIELD:
        setProducts(items.fields);
        break;
      case CONFERENCE:
        setProducts(items.conferences);
        break;
      case ITEM:
        setProducts(items.items);
        break;
      case LOCKER:
        setProducts(items.lockers);
        break;
      default:
        setProducts(null);
    }
  }, [type, items.camping, items.conferences, items.fields, items.items, items.lockers]);

  return (
    <Component>
      <Menu>
        <Tab value="Bostäder" active={type === CAMPING} onClick={() => setType(CAMPING)} />
        <Tab value="Ytor" active={type === FIELD} onClick={() => setType(FIELD)} />
        <Tab value="Omklädningsrum" active={type === LOCKER} onClick={() => setType(LOCKER)} />
        <Tab value="Konferens" active={type === CONFERENCE} onClick={() => setType(CONFERENCE)} />
        <Tab value="Artiklar" active={type === ITEM} onClick={() => setType(ITEM)} />
      </Menu>
      <Header
        title="Produkter"
        search={setSearchString}
        extra={[<Button key="search" value="Skapa produkt" onClick={() => showCreate(true)} />]}
      />
      <Labels>
        <div />
        <Label>Namn</Label>
        {showPrice && <Label>Pris</Label>}
        {labels.map(e => (
          <Label key={e}>{e}</Label>
        ))}
        <div />
      </Labels>
      <Content>
        {products &&
          products
            .filter(e => e.name.toLowerCase().includes(searchString))
            .map(e => (
              <Product
                key={e.id}
                data={e}
                format={formatData}
                formatInput={formatInput}
                formatSubmit={formatSubmit}
                type={type}
              />
            ))}
      </Content>
      {creating && (
        <PopOverForm
          data={{
            id: 0,
            name: null,
            image,
            initials: 'P',
            title: 'Skapar ny produkt',
            text: `Var noga med att fylla i korrekt data.`,
            submitLabel: 'Spara'
          }}
          formData={{ image }}
          formatInput={formatInput}
          formatSubmit={formatSubmit}
          Form={EditProduct}
          formID="EditProduct"
          editImage={setImage}
          deleteImage={deleteImage}
          onCancel={() => showCreate(false)}
          productType={type}
        />
      )}
    </Component>
  );
};

export default Products;
