import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { createSelector } from 'reselect';
import { Main as MainTemp, Container, Button } from '../../Styles/Styles';
import { Media, Dimensions, Fonts, Regexp } from '../../Resources/Stylesheets/Variables';
import { PERSON, COMPANY } from '../../Library/Variables';
import Item from './Components/Item';
import Input from '../../Components/Forms/Input';
import useForm from '../../Hooks/useForm';
import { calculateTimespanPriceHours, getFieldPrice } from '../../Library/Library';
import { deleteBasket } from '../../Reducers/Basket/actions';
import { useAfterSuccess, useSelectBasket } from '../../Hooks';
import { useCreateBookingReference, useGetCompany } from '../../Library/Hooks';
import Mark from '../Marks/Components/Mark';
import { addErr } from '../../Reducers/Error/actions';

const Main = styled(MainTemp)`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas: 'content basket' 'content summary';
  grid-gap: 30px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: 'content' 'basket' 'summary';
  }
`;

const Content = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${Dimensions.navigationHeight} max-content max-content;
  grid-gap: 30px;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  padding: 30px;
`;

const Basket = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 350px;
  grid-gap: 10px;
  padding: 15px 30px;
  overflow-y: auto;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    height: 300px;
  }
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-rows: 40px max-content;
`;

const BasketWrapper = styled.div`
  grid-area: basket;
  display: grid;
  grid-template-rows: 40px max-content;
`;

const MarksWrapper = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

const MarkWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 15px;
  align-items: center;
`;

const SummaryWrapper = styled.div`
  grid-area: summary;
  display: grid;
  grid-template-rows: 40px max-content;
`;

const Summary = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, max-content) 60px;
  grid-gap: 10px;
  padding: 30px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    margin-bottom: 15px;
    padding: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.color};
  padding-top: 15px;
`;

const ButtonRow = styled.div`
  display: grid;
  align-items: flex-end;
`;

const Discount = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin-left: 30px;

  input {
    text-align: right;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightSemiBold};
    font-size: ${Fonts.sizeSmall};
    background: none;
    color: ${props => props.theme.color};
    appearance: none;
    border: none;
    margin: 0;

    &::placeholder {
      color: ${props => props.theme.color};
    }
  }
`;

const Title = styled.h3`
  color: ${props => props.theme.color};
`;

const Menu = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 140px;
  grid-template-rows: 1fr;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-auto-columns: 1fr;
  }
`;

const Tab = styled(Button)`
  background: ${props => !props.active && 'transparent'};
  color: ${props => !props.active && props.theme.color};
`;

const Items = styled.div`
  overflow-y: auto;
  display: grid;
  grid-auto-rows: max-content;
  margin-bottom: 15px;
  h4 {
    padding-top: 15px;
  }
`;

const BookingForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 30px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const Column = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  grid-gap: 10px;
`;

const MarkButton = styled(Button)`
  ${props => !props.active && `background: transparent;`};
`;

const selectMarks = state => state.marks;

const selectCustomerMarks = customerID =>
  createSelector(selectMarks, marks => {
    return marks.filter(e => e.customers.find(y => y.customerID === customerID));
  });

const Checkout = () => {
  const { values, handleChange, updateValues } = useForm({ discount: 0 });
  const dispatch = useDispatch();
  const { basket, basketFieldsGrouped, basketLockersGrouped, totPrice, basketSize } = useSelectBasket();
  const { camping, field: fields, item: items, conference: conferences, locker: lockers } = basket;
  const [activeTab, setActiveTab] = useState(PERSON);
  const [chosen, setChosen] = useState(false);
  const { history } = useReactRouter();
  const marks = useSelector(selectCustomerMarks(values.customerID));
  const tot = ((totPrice * (100 - parseInt(values.discount || '0', 10))) / 100).toFixed(2);

  const create = useAfterSuccess(useCreateBookingReference(), ({ referenceID }) => {
    history.push(`/bookingReference/${referenceID}`);
    dispatch(deleteBasket());
  });
  const search = useAfterSuccess(useGetCompany(), updateValues);
  const firstName = useRef('');
  const org = useRef('');

  const [mark, setMark] = useState(null);

  useEffect(() => {
    if (!basketSize) history.push('/basket');
  }, [basketSize, history]);

  useEffect(() => {
    if (!mark && !chosen) setMark(marks.length > 1 ? null : marks[0]);
  }, [chosen, mark, marks]);

  const onSubmit = async e => {
    e.preventDefault();

    if (parseFloat(values.discount || '0') > totPrice) {
      dispatch(addErr('Den totala rabatten kan inte var större än det totala priset.'));
      return;
    }

    const body = {
      comment: values.comment,
      customerType: activeTab,
      customer: {
        email: values.email,
        phone: values.phone,
        address: values.address,
        zipCode: values.zipCode,
        city: values.city,
        country: values.country
      }
    };

    if (activeTab === PERSON) {
      body.customer = {
        ...body.customer,
        firstName: values.firstName,
        lastName: values.lastName
      };
    } else if (activeTab === COMPANY) {
      body.customer = {
        ...body.customer,
        customerID: values.customerID,
        reference: values.reference,
        name: values.name
      };
    }

    body.discount = values.discount ? parseInt(values.discount, 10) : 0;
    body.markID = mark && mark.id.toString();
    create.request(body);
  };

  const getCompanyData = async e => {
    e.preventDefault();
    setMark(null);
    search.request({ id: values.customerID });
  };

  const handleMarkClick = e => {
    setChosen(true);
    if (e === mark) {
      setMark(null);
    } else {
      setMark(e);
    }
  };

  const company = (
    <Column>
      <Input
        key="customerID"
        label="Kund ID"
        type="text"
        name="customerID"
        value={values.customerID}
        onChange={handleChange}
        required
        button={getCompanyData}
        ref={org}
      />
      <Input
        key="name"
        label="Namn"
        type="text"
        name="name"
        value={values.name}
        onChange={handleChange}
        pattern={Regexp.name}
        minLength={1}
        maxLength={50}
        required
      />
      <Input
        key="reference"
        label="Referens"
        type="text"
        name="reference"
        value={values.reference}
        onChange={handleChange}
        pattern={Regexp.name}
        minLength={1}
        maxLength={100}
        required
      />
      <Input label="E-Mail" type="email" name="email" value={values.email} onChange={handleChange} required />
      <Input label="Telefon" type="phone" name="phone" value={values.phone} onChange={handleChange} required />
      <Input label="Kommentar" type="text" name="comment" value={values.comment} onChange={handleChange} />
    </Column>
  );

  const person = (
    <Column>
      <Input
        label="Förnamn"
        type="text"
        name="firstName"
        value={values.firstName}
        onChange={handleChange}
        required
        pattern={Regexp.name}
        minLength={1}
        maxLength={50}
        ref={firstName}
      />
      <Input
        label="Efternamn"
        type="text"
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        required
        pattern={Regexp.name}
        minLength={1}
        maxLength={50}
      />
      <Input label="E-Mail" type="email" name="email" value={values.email} onChange={handleChange} required />
      <Input label="Telefon" type="phone" name="phone" value={values.phone} onChange={handleChange} required />
      <Input label="Kommentar" type="text" name="comment" value={values.comment} onChange={handleChange} />
    </Column>
  );

  const address = (
    <Column>
      <Input label="Adress" type="text" name="address" value={values.address} onChange={handleChange} required />
      <Input
        label="Postnummer"
        type="text"
        name="zipCode"
        value={values.zipCode}
        onChange={handleChange}
        minLength={1}
        maxLength={5}
        required
      />
      <Input
        label="Ort"
        type="text"
        name="city"
        value={values.city}
        onChange={handleChange}
        pattern={Regexp.name}
        required
      />
      <Input
        label="Land"
        type="text"
        name="country"
        value={values.country}
        onChange={handleChange}
        pattern={Regexp.name}
        required
      />
    </Column>
  );

  return (
    <Main>
      <Content>
        <Menu>
          <Tab value="Privat person" active={activeTab === PERSON} onClick={() => setActiveTab(PERSON)} />
          <Tab value="Företag" active={activeTab === COMPANY} onClick={() => setActiveTab(COMPANY)} />
        </Menu>
        <FormWrapper>
          <Title>Bokningsinformation</Title>
          <Container>
            <Form id="form" onSubmit={onSubmit}>
              <BookingForm>
                {activeTab === PERSON && person}
                {activeTab === COMPANY && company}
                {address}
              </BookingForm>
            </Form>
          </Container>
        </FormWrapper>
        {activeTab === COMPANY && marks.length > 0 && (
          <MarksWrapper>
            <Title>Märkningar</Title>
            {marks.map(e => (
              <MarkWrapper key={e.id}>
                <Mark data={e} />
                <MarkButton
                  value={e === mark ? 'Vald' : 'Välj'}
                  onClick={() => handleMarkClick(e)}
                  active={e === mark}
                />
              </MarkWrapper>
            ))}
          </MarksWrapper>
        )}
      </Content>
      <BasketWrapper>
        <Title>Varukorg</Title>
        <Basket>
          <Items>
            {camping && (
              <>
                <h4>Bostäder</h4>
                {camping.map(d => (
                  <Item key={d.id} data={d} />
                ))}
              </>
            )}
            {fields && (
              <>
                <h4>Ytor</h4>
                {Object.entries(basketFieldsGrouped).map(([key, e]) => {
                  if (key === 'null') {
                    return e.map(e1 => <Item key={e1.id} data={e1} />);
                  }
                  const price = e.reduce(
                    (s, e1) => s + calculateTimespanPriceHours({ ...e1, price: getFieldPrice(e1), discount: 0 }),
                    0
                  );
                  const data = {
                    ...e[0],
                    name: `${e[0].name} (${e.length})`,
                    fullField: price
                  };
                  return <Item key={e[0].id} data={data} />;
                })}
                {fields.map(d => (
                  <Item key={d.id} data={d} />
                ))}
              </>
            )}
            {lockers && (
              <>
                <h4>Omklädningsrum</h4>
                {Object.entries(basketLockersGrouped).map(([key, e]) => {
                  if (key === 'null') {
                    return e.map(e1 => <Item key={e1.id} data={e1} />);
                  }
                  const price = e.reduce(
                    (s, e1) => s + calculateTimespanPriceHours({ ...e1, price: getFieldPrice(e1), discount: 0 }),
                    0
                  );
                  const data = {
                    ...e[0],
                    name: `${e[0].name} (${e.length})`,
                    fullField: price
                  };
                  return <Item key={e[0].id} data={data} />;
                })}
                {lockers.map(d => (
                  <Item key={d.id} data={d} />
                ))}
              </>
            )}
            {conferences && (
              <>
                <h4>Konferens</h4>
                {conferences.map(d => (
                  <Item key={d.id} data={d} />
                ))}
              </>
            )}
            {items && (
              <>
                <h4>Artiklar</h4>
                {items.map(d => (
                  <Item key={d.id} data={d} />
                ))}
              </>
            )}
          </Items>
        </Basket>
      </BasketWrapper>
      <SummaryWrapper>
        <Title>Sammanställning</Title>
        <Summary>
          <Row>
            <p>Antal</p>
            <h4>{basketSize}</h4>
          </Row>
          <Row>
            <p>Rabatt</p>
            <Discount>
              <input
                type="text"
                name="discount"
                value={values.discount}
                onChange={handleChange}
                pattern="[0-9]+"
                placeholder="0"
              />
              <h4>%</h4>
            </Discount>
          </Row>
          <Row>
            <p>Totalt</p>
            <h4>{`${tot} kr`}</h4>
          </Row>
          <ButtonRow>
            <Button value="Slutför bokning" type="submit" form="form" />
          </ButtonRow>
        </Summary>
      </SummaryWrapper>
    </Main>
  );
};

export default Checkout;
