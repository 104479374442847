import React from 'react';
import PropTypes from 'prop-types';
import { useAfterSuccess } from '../../../Hooks';
import { useDeleteUser } from '../../../Library/Hooks';

const DeleteUser = ({ formID, formData, close }) => {
  const { request } = useAfterSuccess(useDeleteUser(), close);

  const onSubmit = async e => {
    e.preventDefault();
    request({ id: formData.id });
  };

  return <form id={formID} onSubmit={onSubmit} />;
};

DeleteUser.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.instanceOf(Date)])
  ).isRequired,
  close: PropTypes.func.isRequired
};

export default DeleteUser;
