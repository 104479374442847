import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useComponentVisible } from '../../../Hooks';
import { Fonts, Media, Dimensions } from '../../../Resources/Stylesheets/Variables';
import { Dropdown, Ripple } from '../../../Styles/Styles';

const Container = styled.div`
  grid-area: guest;
  border-right: 2px solid ${props => props.theme.border};
  user-select: none;
  position: relative;
  width: 100%;
  @media (max-width: ${Media.phone}) {
    border-right: none;
    border-bottom: 2px solid ${props => props.theme.border};
  }
`;

const Label = styled.div`
  display: grid;
  grid-template-columns: ${Dimensions.navigationHeight} 1fr ${Dimensions.navigationHeight};
  grid-template-rows: ${Dimensions.navigationHeight};
  align-items: center;
`;

const Icon = styled.i`
  display: flex;
  justify-content: center;
  font-size: ${Fonts.sizeRegular};
  color: ${props => (props.active ? props.theme.accent : props.theme.color)};
  transition: 200ms ease-in-out;
`;

const Picker = styled(Dropdown)`
  display: grid;
  width: 100%;
  grid-template-columns: ${Dimensions.navigationHeight} 1fr ${Dimensions.navigationHeight};
  grid-template-rows: ${Dimensions.navigationHeight};
  grid-template-areas: 'decrease value increace';
`;

const Button = styled(Ripple)`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeRegular};
  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const Decreace = styled(Button)`
  grid-area: decrease;
`;

const Increace = styled(Button)`
  grid-area: increace;
`;

const Value = styled.h4`
  user-select: none;
  grid-area: value;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${Fonts.text};
`;

const Guest = ({ guests: value, setGuests: setValue }) => {
  const { ref, visible, setVisible } = useComponentVisible(false);
  return (
    <Container ref={ref}>
      <Label onClick={() => setVisible(v => !v)}>
        <Icon active={visible || value !== 0} className="icon-users" />
        <h4>{value === 0 ? 'Antal gäster?' : value}</h4>
        <Icon className={visible ? 'icon-angle-up' : 'icon-angle-down'} />
      </Label>
      {visible && (
        <Picker>
          <Decreace
            type="button"
            className="icon-minus"
            onClick={() => (value < 1 ? setValue(value) : setValue(value - 1))}
          />
          <Value>{value}</Value>
          <Increace
            type="button"
            className="icon-plus"
            onClick={() => (value > 100 ? setValue(value) : setValue(value + 1))}
          />
        </Picker>
      )}
    </Container>
  );
};

Guest.propTypes = {
  setGuests: PropTypes.func.isRequired,
  guests: PropTypes.number.isRequired
};

export default Guest;
