import { useEffect, useRef } from 'react';

import {
  useGetBasket,
  useGetCoupons,
  useGetCurrentBookings,
  useGetLatestBookings,
  useGetMarks,
  useGetProducts,
  useGetProfile,
  useGetSettings,
  useGetTodayBookings,
  useGetUsers
} from '../../Library/Hooks';

export const useAll = (useHook, m, refetch) => {
  const ret = useHook();
  const { loading, error, request } = ret;
  if (loading) m.loading = loading;
  if (error) m.error = error;
  const first = useRef(0);
  useEffect(() => {
    if (first.current === 0) {
      first.current = 1;
    } else {
      request();
    }
  }, [request, refetch]);
  return ret;
};

const useData = refetch => {
  const ret = { error: null, loading: false };
  useAll(useGetSettings, ret, refetch);
  useAll(useGetProducts, ret, refetch);
  useAll(useGetCurrentBookings, ret, refetch);
  useAll(useGetTodayBookings, ret, refetch);
  useAll(useGetLatestBookings, ret, refetch);
  useAll(useGetBasket, ret, refetch);
  useAll(useGetProfile, ret, refetch);
  useAll(useGetCoupons, ret, refetch);
  useAll(useGetMarks, ret, refetch);

  return ret;
};

export const Admin = ({ refetch }) => {
  const ret = { error: null, loading: false };
  useAll(useGetUsers, ret, refetch);

  return null;
};

export default useData;
