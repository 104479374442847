import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Main } from '../../Styles/Styles';
import Header from '../../Components/Header/Header';
import Booking from '../Bookings/Components/Booking';
import { useSearchBookingReferences } from '../../Library/Hooks';
import Loader from '../../Components/Loader/Loader';
import PageNumberer from '../Bookings/Components/PageNumberer';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-gap: 15px;
  justify-content: center;
  overflow-y: auto;
`;

const Bookings = styled.div`
  display: grid;
  grid-auto-rows: 90px;
  grid-gap: 10px;
`;

const Search = ({ location }) => {
  const searchParams = qs.parse(location.search);
  const { request, loading, data } = useSearchBookingReferences();
  const { searchString } = searchParams;
  const page = parseInt(searchParams.page, 10) || 1;
  const size = parseInt(searchParams.size, 10) || 10;
  const refs = data && data.bookingReferences;

  useEffect(() => {
    if (searchString.length > 2) request({ searchString, size, page });
  }, [page, request, searchString, size]);

  if (loading) return <Loader />;

  const title = searchString ? `Visar resultat för: "${searchString}"` : `Sök efter bokningar genom sökfältet.`;

  return (
    <Main>
      <Container>
        <Header title={title} />
        <Bookings>
          {refs && refs.length > 0 ? (
            refs.map(e => <Booking key={e.referenceID} data={e} />)
          ) : (
            <p>{`Kunde inte hitta några bokningar innehållande: "${searchString}"`}</p>
          )}
        </Bookings>
      </Container>
      <PageNumberer current={page} size={Math.ceil(data.total / size)} params={searchParams} url="/search" />
    </Main>
  );
};

Search.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string })
};

Search.defaultProps = {
  location: null
};

export default Search;
