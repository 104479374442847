import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Media } from '../../../Resources/Stylesheets/Variables';
import Camping from '../../../Resources/Assets/SVG/camping.svg';
import { Container as Template } from '../../../Styles/Styles';

const Container = styled(Template)`
  grid-column: span 18;
  grid-row: span 3;
  display: grid;
  grid-template-columns: 1fr 300px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-column-start: 1;
    grid-column-end: end;
    grid-row: span 4;
    grid-template-columns: 1fr;
  }
`;

const Left = styled.div`
  padding: 20px;
`;

const Right = styled.div`
  padding-top: 15px;
  padding-right: 15px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    display: none;
  }
`;

const Image = styled.div`
  background: url(${props => props.src}) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const Welcome = ({ header, text }) => {
  return (
    <Container>
      <Left>
        <h4>{header}</h4>
        <p>{text}</p>
      </Left>
      <Right>
        <Image src={Camping} />
      </Right>
    </Container>
  );
};

Welcome.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Welcome;
