import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ImagePicker from '../ImagePicker/ImagePicker';
import { Container, Submit, Cancel } from '../../Styles/Styles';
import { Colors, Fonts, Media, ZIndex } from '../../Resources/Stylesheets/Variables';
import { cap } from '../../Library/Library';
import { CAMPING, CONFERENCE, FIELD, ITEM, LOCKER } from '../../Library/Variables';

const Component = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(34, 34, 34, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${ZIndex.dropdown};
`;

const Box = styled(Container)`
  width: ${Media.phone};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, max-content) 1fr;
  grid-template-areas: 'image' 'name' 'title' 'text' 'form' 'button';
  padding: 30px;
  grid-gap: 10px;
`;

const ImageArea = styled.div`
  display: grid;
  align-items: flex-end;
  justify-content: center;
`;

const Image = styled.div`
  background: ${props => `url('${props.src}')`} no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 100px;
  width: 100px;
`;

const Initials = styled.div`
  color: ${Colors.white};
  background: linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeRegular};
  text-transform: uppercase;
`;

const NameArea = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

const Name = styled.h4``;

const TitleArea = styled.div`
  display: grid;
  align-items: flex-end;
  justify-content: center;
`;

const Title = styled.h4``;

const TextArea = styled.div`
  display: grid;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding: 0 70px;
`;

const Text = styled.p``;

const FormArea = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

const ButtonArea = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 15px;
  padding-top: 15px;
`;

const PopOverForm = ({
  data,
  Form,
  formID,
  formData,
  onCancel,
  editImage,
  deleteImage,
  refetch,
  formatInput,
  formatSubmit,
  productType
}) => {
  return createPortal(
    <Component>
      <Box>
        <ImageArea>
          {(() => {
            if (!editImage || !deleteImage) {
              if (data.image) {
                return <Image src={data.image} />;
              }
              return <Initials>{data.initials}</Initials>;
            }
            if (!data.image) {
              return <ImagePicker Image={Initials} text={data.initials} onUpdate={editImage} onDelete={deleteImage} />;
            }
            return <ImagePicker Image={Image} src={data.image} onUpdate={editImage} onDelete={deleteImage} />;
          })()}
        </ImageArea>
        <NameArea>{typeof data.name === 'string' ? <Name>{cap(data.name)}</Name> : data.name}</NameArea>
        <TitleArea>
          <Title>{data.title}</Title>
        </TitleArea>
        <TextArea>
          <Text>{data.text}</Text>
        </TextArea>
        <FormArea>
          {Form && (
            <Form
              formID={formID}
              formData={formData}
              close={onCancel}
              refetch={refetch}
              formatInput={formatInput}
              formatSubmit={formatSubmit}
              type={productType}
            />
          )}
        </FormArea>
        <ButtonArea>
          <Cancel value="Avbryt" onClick={onCancel} />
          <Submit value={data.submitLabel} type="submit" form={formID} />
        </ButtonArea>
      </Box>
    </Component>,
    document.querySelector('#modal')
  );
};

PopOverForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.element]),
    image: PropTypes.string,
    initials: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    submitLabel: PropTypes.string.isRequired
  }).isRequired,
  Form: PropTypes.elementType.isRequired,
  formID: PropTypes.string.isRequired,
  formData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object
  ]),
  onCancel: PropTypes.func.isRequired,
  editImage: PropTypes.func,
  deleteImage: PropTypes.func,
  refetch: PropTypes.func,
  productType: PropTypes.oneOf([CAMPING, FIELD, LOCKER, ITEM, CONFERENCE])
};

PopOverForm.defaultProps = {
  editImage: null,
  deleteImage: null,
  refetch: null,
  formData: null,
  productType: null
};

export default PopOverForm;
