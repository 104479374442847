import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Rows, Row, Label, Data } from './Customer';
import { cap, formatDate, formatPrice, intDiv } from '../../../Library/Library';
import { useGetUser } from '../../../Library/Hooks';
import { BookingReferenceType } from '../../../Types/types';
import { CAMPING } from '../../../Library/Variables';
import Loader from '../../../Components/Loader/Loader';

const Summary = ({ data }) => {
  const user = useGetUser({ id: data.userID });
  const guests = useCallback(
    data.bookedProducts && data.bookedProducts.reduce((sum, e1) => sum + (e1.type === CAMPING ? e1.spec.guests : 0), 0),
    [data]
  );
  const marks = useSelector(state => state.marks);
  const mark = marks.find(e => e.id.toString() === data.markID);
  const vat = formatPrice(intDiv((data.priceVat - data.price) * (100 - data.discount), 100));

  const bookedBy = user && user.data ? `${user.data.firstName} ${user.data.lastName}` : 'Ibex';

  if (!bookedBy) return <Loader />;
  return (
    <Rows>
      <Row>
        <Label>Datum</Label>
        <Data>
          <p>{formatDate(data.date)}</p>
        </Data>
      </Row>
      <Row>
        <Label>Referens</Label>
        <Data>
          <p>{cap(bookedBy)}</p>
        </Data>
      </Row>
      {data.exported && (
        <Row>
          <Label>Exporterad</Label>
          <Data>
            <p>{formatDate(data.exported)}</p>
          </Data>
        </Row>
      )}
      {mark && (
        <Row>
          <Label>Märkning</Label>
          <Data>
            <p>{mark.name}</p>
          </Data>
        </Row>
      )}
      {data.bookedProducts && (
        <Row>
          <Label>Antal</Label>
          <Data>
            <p>{data.bookedProducts.length}</p>
          </Data>
        </Row>
      )}
      {guests > 0 && (
        <Row>
          <Label>Gäster</Label>
          <Data>
            <p>{`${guests} st`}</p>
          </Data>
        </Row>
      )}
      <Row>
        <Label>Rabatt</Label>
        <Data>
          <p>{`${data.discount} %`}</p>
        </Data>
      </Row>
      {vat && (
        <Row>
          <Label>Moms</Label>
          <Data>
            <p>{`${vat} kr`}</p>
          </Data>
        </Row>
      )}
      <Row>
        <Label>Totalt Ink. Moms</Label>
        <Data>
          <p>{`${formatPrice(intDiv(data.priceVat * (100 - data.discount), 100))} kr`}</p>
        </Data>
      </Row>
    </Rows>
  );
};

Summary.propTypes = {
  data: PropTypes.shape(BookingReferenceType)
};

Summary.defaultProps = { data: null };

export default Summary;
