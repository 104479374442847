import React from 'react';
import styled from 'styled-components';
import Input from '../../../Components/Forms/Input';
import Select from '../../../Components/Forms/Select';
import { FIELD_SIZE, HOUSING_TYPE } from '../../../Library/Variables';
import Checkbox from '../../../Components/Forms/Checkbox';
import { formatPrice, getFieldLabel, preparePriceForRequest } from '../../../Library/Library';
import Price from '../../../Components/Forms/Price';

const Cell = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: flex-start;
  align-items: center;
`;

const Details = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
`;

const Detail = styled.h4``;

export const camping = {
  labels: ['Bäddar', 'Storlek', 'Egenskaper'],
  formatData: spec => (
    <>
      <Cell>
        <h4>{`${spec.beds ? spec.beds : 0} st`}</h4>
      </Cell>
      <Cell>
        <h4>{`${spec.size ? spec.size : 0} m\u00b2`}</h4>
      </Cell>
      <Details>
        {spec.tv && <Detail className="icon-tv" />}
        {spec.wifi && <Detail className="icon-wifi" />}
        {spec.electricity && <Detail className="icon-electricity" />}
        {spec.kitchen && <Detail className="icon-kitchen" />}
      </Details>
    </>
  ),
  formatInput: (values, handleChange) => (
    <>
      <Input
        label="Bäddar"
        name="beds"
        value={values.beds}
        onChange={handleChange}
        type="text"
        pattern="[0-9]+"
        minLength={1}
        maxLength={10}
      />
      <Input
        name="size"
        value={values.size}
        onChange={handleChange}
        label="Storlek"
        type="text"
        pattern="[0-9]+"
        minLength={1}
        maxLength={10}
      />
      <Select name="type" value={values.type} onChange={handleChange} label="Typ" options={HOUSING_TYPE} />
      <div />
      <Checkbox name="wifi" value={values.wifi} onChange={handleChange} label="Har boendet wifi?" />
      <Checkbox name="kitchen" value={values.kitchen} onChange={handleChange} label="Har boendet kök?" />
      <Checkbox name="electricity" value={values.electricity} onChange={handleChange} label="Har boendet el?" />
      <Checkbox name="tv" value={values.tv} onChange={handleChange} label="Har boendet tv?" />
    </>
  ),
  formatSubmit: values => ({
    type: values.type,
    beds: values.beds ? parseInt(values.beds, 10) : null,
    size: values.size ? parseFloat(values.size) : null,
    wifi: values.wifi,
    kitchen: values.kitchen,
    electricity: values.electricity,
    tv: values.tv
  })
};

export const field = {
  labels: ['Storlek', 'Tävling', 'Hel', 'Halv', '2/3', '1/3'],
  formatData: (spec, vat) => (
    <>
      <Cell>
        <h4>{getFieldLabel(spec.size)}</h4>
      </Cell>
      <Cell>
        <h4>{`${formatPrice(spec.match, vat)} kr/tim`}</h4>
        <i>{`${formatPrice(spec.matchDay, vat)} kr/dag`}</i>
      </Cell>
      <Cell>
        <h4>{`${formatPrice(spec.fullField, vat)} kr/tim`}</h4>
        <i>{`${formatPrice(spec.fullFieldDay, vat)} kr/dag`}</i>
      </Cell>
      {spec.halfField ? (
        <Cell>
          <h4>{`${formatPrice(spec.halfField, vat)} kr/tim`}</h4>
          <i>{`${formatPrice(spec.halfFieldDay, vat)} kr/dag`}</i>
        </Cell>
      ) : (
        <Cell />
      )}
      {spec.twoThirdsField ? (
        <Cell>
          <h4>{`${formatPrice(spec.twoThirdsField, vat)} kr/tim`}</h4>
          <i>{`${formatPrice(spec.twoThirdsFieldDay, vat)} kr/dag`}</i>
        </Cell>
      ) : (
        <Cell />
      )}
      {spec.oneThirdField ? (
        <Cell>
          <h4>{`${formatPrice(spec.oneThirdField, vat)} kr/tim`}</h4>
          <i>{`${formatPrice(spec.oneThirdFieldDay, vat)} kr/dag`}</i>
        </Cell>
      ) : (
        <Cell />
      )}
    </>
  ),
  formatInput: (values, handleChange, vat) => (
    <>
      <Select name="size" value={values.size} onChange={handleChange} label="Storlek" options={FIELD_SIZE} />
      <Price
        label="Timpris Tävling"
        type="text"
        name="match"
        value={values.match}
        onChange={handleChange}
        required
        vat={vat}
      />
      <Price
        label="Dagspris Tävling"
        type="text"
        name="matchDay"
        value={values.matchDay}
        onChange={handleChange}
        required
        vat={vat}
      />
      <Price
        label="Timpris Hel"
        type="text"
        name="fullField"
        value={values.fullField}
        onChange={handleChange}
        required
        vat={vat}
      />
      <Price
        label="Dagspris Hel"
        type="text"
        name="fullFieldDay"
        value={values.fullFieldDay}
        onChange={handleChange}
        required
        vat={vat}
      />
      <Price
        label="Timpris Halv"
        type="text"
        name="halfField"
        value={values.halfField}
        onChange={handleChange}
        vat={vat}
      />
      <Price
        label="Dagspris Halv"
        type="text"
        name="halfFieldDay"
        value={values.halfFieldDay}
        onChange={handleChange}
        vat={vat}
      />
      <Price
        label="Timpris 2/3"
        type="text"
        name="twoThirdsField"
        value={values.twoThirdsField}
        onChange={handleChange}
        vat={vat}
      />
      <Price
        label="Dagspris 2/3"
        type="text"
        name="twoThirdsFieldDay"
        value={values.twoThirdsFieldDay}
        onChange={handleChange}
        vat={vat}
      />
      <Price
        label="Timpris 1/3"
        type="text"
        name="oneThirdField"
        value={values.oneThirdField}
        onChange={handleChange}
        vat={vat}
      />
      <Price
        label="Dagspris 1/3"
        type="text"
        name="oneThirdFieldDay"
        value={values.oneThirdFieldDay}
        onChange={handleChange}
        vat={vat}
      />
    </>
  ),
  formatSubmit: (values, vat) => ({
    size: values.size,
    match: preparePriceForRequest(values.match, vat),
    matchDay: preparePriceForRequest(values.matchDay, vat),
    fullField: preparePriceForRequest(values.fullField, vat),
    fullFieldDay: preparePriceForRequest(values.fullFieldDay, vat),
    halfField: preparePriceForRequest(values.halfField, vat),
    halfFieldDay: preparePriceForRequest(values.halfFieldDay, vat),
    twoThirdsField: preparePriceForRequest(values.twoThirdsField, vat),
    twoThirdsFieldDay: preparePriceForRequest(values.twoThirdsFieldDay, vat),
    oneThirdField: preparePriceForRequest(values.oneThirdField, vat),
    oneThirdFieldDay: preparePriceForRequest(values.oneThirdFieldDay, vat)
  })
};

export const conference = {
  labels: ['Storlek', 'Pris', 'Egenskaper'],
  formatData: (spec, vat) => (
    <>
      <Cell>
        <h4>{`${spec.size} st`}</h4>
      </Cell>
      <Cell>
        <h4>{`${formatPrice(spec.hour, vat)} kr/tim`}</h4>
        <i>{`${formatPrice(spec.day, vat)} kr/dag`}</i>
      </Cell>
      <Details>
        {spec.tv && <Detail className="icon-tv" />}
        {spec.wifi && <Detail className="icon-wifi" />}
      </Details>
    </>
  ),
  formatInput: (values, handleChange, vat) => (
    <>
      <Input
        name="size"
        value={values.size}
        onChange={handleChange}
        label="Antal personer"
        type="text"
        pattern="[0-9]+"
        minLength={1}
        maxLength={50}
      />
      <Price label="Timpris" type="text" name="hour" value={values.hour} onChange={handleChange} required vat={vat} />
      <Price label="Dagspris" type="text" name="day" value={values.day} onChange={handleChange} required vat={vat} />
      <Checkbox name="wifi" value={values.wifi} onChange={handleChange} label="Har rummet wifi?" />
      <Checkbox name="tv" value={values.tv} onChange={handleChange} label="Har rummet projektor/tv?" />
    </>
  ),
  formatSubmit: (values, vat) => ({
    size: values.size ? parseFloat(values.size) : null,
    hour: preparePriceForRequest(values.hour, vat),
    day: preparePriceForRequest(values.day, vat),
    wifi: values.wifi,
    tv: values.tv
  })
};

export const item = {
  labels: ['Beskrivning'],
  formatData: () => null,
  formatInput: () => null,
  formatSubmit: () => undefined
};

export const locker = {
  labels: ['Storlek', 'Pris'],
  formatData: (spec, vat) => (
    <>
      <Cell>
        <h4>{`${spec.size} st`}</h4>
      </Cell>
      <Cell>
        <h4>{`${formatPrice(spec.hour, vat)} kr/tim`}</h4>
        <i>{`${formatPrice(spec.day, vat)} kr/dag`}</i>
      </Cell>
    </>
  ),
  formatInput: (values, handleChange, vat) => (
    <>
      <Input
        name="size"
        value={values.size}
        onChange={handleChange}
        label="Antal personer"
        type="text"
        pattern="[0-9]+"
        minLength={1}
        maxLength={50}
      />
      <Price label="Timpris" type="text" name="hour" value={values.hour} onChange={handleChange} required vat={vat} />
      <Price label="Dagspris" type="text" name="day" value={values.day} onChange={handleChange} required vat={vat} />
    </>
  ),
  formatSubmit: (values, vat) => ({
    size: values.size ? parseFloat(values.size) : null,
    hour: preparePriceForRequest(values.hour, vat),
    day: preparePriceForRequest(values.day, vat)
  })
};
