import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Main, Grid } from '../../Styles/Styles';
import Welcome from './Components/Welcome';
import { cap } from '../../Library/Library';
import List from '../../Components/List/List';

const Container = styled(Main)``;

const Dashboard = () => {
  const profile = useSelector(state => state.profile);
  const latestBookings = useSelector(state => state.bookings.latest).slice(0, 10);
  const todaysBookings = useSelector(state => state.bookings.today).slice(0, 10);

  return (
    <Container>
      <Grid>
        <Welcome
          header={`Välkommen ${cap(profile.firstName)}!`}
          text="Här får du en översikt över vad som pågår i de olika modulerna. Samt vilka som checkar in under dagen och de senaste bokningarna."
        />
        <List
          spec={{
            label: 'Senaste bokningarna',
            data: latestBookings.map(e => ({ ...e, type: 'camp' }))
          }}
        />
        <List
          spec={{
            label: 'Dagens bokningar',
            data: todaysBookings.map(e => ({ ...e, type: 'camp' }))
          }}
        />
      </Grid>
    </Container>
  );
};

export default Dashboard;
