import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Media } from '../../Resources/Stylesheets/Variables';
import { Container, Main, Icon, Button } from '../../Styles/Styles';

const Component = styled(Main)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    align-items: flex-start;
  }
`;

const Box = styled(Container)`
  width: ${Media.phone};
  height: 500px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 30px 20px 1fr 1fr;
  justify-items: center;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    width: 100%;
    padding: 15px;
  }
`;

const PageNotFound = () => {
  const theme = useSelector(state => state.session.theme);
  return (
    <Component>
      <Box>
        <Icon className="icon-trash" color={theme.negative} />
        <h4>Sidan du sökte kunde inte hittas!</h4>
        <p>{window.location.href}</p>
        <p>Om du har några frågor så kan nu kontakta vår Support.</p>
        <Button value="Gå till Hem" to="/" />
      </Box>
    </Component>
  );
};

export default PageNotFound;
