import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Cancel } from '../../../Styles/Styles';
import { logout } from '../../../Library/Library';
import Input from '../../../Components/Forms/Input';
import { Regexp } from '../../../Resources/Stylesheets/Variables';
import useForm from '../../../Hooks/useForm';
import { addErr } from '../../../Reducers/Error/actions';
import { useSetPassword } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';

const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  padding: 30px;
  grid-gap: 30px;
`;

const Header = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 15px;
`;

const Content = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  align-items: center;
`;

const Tools = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 15px;
`;

const ForcePassword = ({ close }) => {
  const dispatch = useDispatch();
  const { values, handleChange } = useForm();
  const { request } = useAfterSuccess(useSetPassword(), close);

  const handleSubmit = async e => {
    e.preventDefault();
    if (values.newPassword !== values.confirmedPassword) {
      dispatch(addErr('Lösenorden stämmer inte överens.'));
      return;
    }
    request({ password: values.newPassword });
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Header>
        <h4>Du behöver byta lösenord!</h4>
        <p>Denna åtgärd går inte att avbryta. Kontakta en administratör om du har några frågor angående detta.</p>
      </Header>
      <Content>
        <Row>
          <Input
            onChange={handleChange}
            value={values.newPassword}
            type="password"
            label="Nytt lösenord"
            name="newPassword"
            required
            pattern={Regexp.password}
            minLength={8}
            maxLength={256}
          />
          <Input
            onChange={handleChange}
            value={values.confirmedPassword}
            type="password"
            label="Bekräfta nytt lösenord"
            name="confirmedPassword"
            required
            pattern={Regexp.password}
            minLength={8}
            maxLength={256}
          />
        </Row>
      </Content>
      <Tools>
        <Cancel type="button" value="Avbryt" onClick={logout} />
        <Button type="submit" value="Spara" />
      </Tools>
    </Container>
  );
};

ForcePassword.propTypes = {
  close: PropTypes.func.isRequired
};

export default ForcePassword;
