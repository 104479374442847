import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ForcePassword from './Components/ForcePassword/ForcePassword';
import { Media } from '../Resources/Stylesheets/Variables';
import TopBar from './Components/TopBar/TopBar';
import SideBar from './Components/SideBar/SideBar';
import Loader from '../Components/Loader/Loader';
import PageNotFound from '../Containers/PageNotFound/PageNotFound';
import useData, { Admin } from './Hooks/useData';
import routes from '../Library/Routes';
import PopOver from '../Components/PopOver/PopOver';
import { ADMIN } from '../Library/Variables';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-template-rows: 70px 1fr;
  grid-template-areas: 'top top' 'side main';
  position: relative;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    height: ${props => `${props.height}px`};
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr 70px;
    grid-template-areas: 'top' 'main' 'side';
  }
`;

const App = () => {
  const { theme } = useSelector(state => state.session);
  const { role } = useSelector(state => state.profile);
  const [forcePasswordChange, viewForcePasswordChange] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [refresh, setRefresh] = useState(false);
  const { loading, error } = useData(refresh);

  const profile = useSelector(state => state.profile);

  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      setHeight(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    viewForcePasswordChange(profile.changePassword);
  }, [profile]);

  if (loading || error) {
    return (
      <Loader
        background={theme.body}
        color={theme.color}
        active
        refresh={error !== null}
        setRefresh={() => {
          setRefresh(e => !e);
        }}
      />
    );
  }

  return (
    <BrowserRouter>
      <Container height={height}>
        {role === ADMIN.type && <Admin refetch={refresh} />}
        <TopBar />
        <SideBar />
        <Switch>
          {routes
            .filter(e => e.access.includes(profile.role))
            .map(e => (
              <Route exact={e.exact} path={e.path} key={e.title} component={e.component} />
            ))}
          <Route component={PageNotFound} />
        </Switch>
        {forcePasswordChange && <PopOver Content={ForcePassword} close={() => viewForcePasswordChange(false)} />}
      </Container>
    </BrowserRouter>
  );
};

export default App;
