import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SubProduct from './SubProduct';
import Loader from '../../../Components/Loader/Loader';
import {
  Avatar,
  ProductColumn,
  ProductColumns,
  ProductContainer,
  ProductDelete,
  ProductEdit,
  ProductLabel,
  ProductValue
} from '../../../Styles/Styles';
import { BOOKING_TYPE, CALENDAR_REPEAT, FIELD } from '../../../Library/Variables';
import {
  addVat,
  calcTotPrice,
  calculateHours,
  cap,
  formatDate,
  formatPrice,
  formatTimeInterval
} from '../../../Library/Library';
import { useDeleteBasketProductByGroupID } from '../../../Library/Hooks';
import { BasketType } from '../../../Types/types';
import GroupComment from './GroupComment';

const useValues = data => {
  const [values, setValues] = useState(null);

  useEffect(() => {
    setValues({
      price: formatPrice(data.reduce((s, e) => s + calcTotPrice(e), 0)),
      discount: formatPrice(data.reduce((e, e1) => e + addVat(e1.discount, e1.vat), 0)),
      image: data[0].image,
      initials: data[0].name.charAt(0),
      name: cap(data[0].name),
      startTime: formatDate(data[0].startTime),
      endTime: formatDate(data[data.length > 0 && data.length - 1].endTime),
      interval: formatTimeInterval(data[0].startTime, data[0].endTime),
      hours: calculateHours(data[0].startTime, data[0].endTime),
      repeatLabel: CALENDAR_REPEAT.find(e => e.type === data[0].spec.repeatEvery).label,
      repeats: data.length,
      version: data[0].type === FIELD && BOOKING_TYPE.find(e => e.type === data[0].spec.version)
    });
  }, [data]);
  return values;
};

const Grouped = ({ data }) => {
  const [expand, setExpand] = useState(false);
  const summary = useValues(data);
  const { request } = useDeleteBasketProductByGroupID();

  const handleDeleteClick = async () => request({ id: data[0].spec.groupID });

  if (!summary) return <Loader active />;

  return (
    <>
      <ProductContainer>
        <Avatar image={summary.image} initials={summary.initials} size="46px" />
        <ProductColumns columns={5}>
          <ProductColumn>
            <ProductValue>{summary.name}</ProductValue>
            {summary.version && <ProductLabel>{summary.version.label}</ProductLabel>}
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>{summary.startTime}</ProductLabel>
            <ProductValue>{summary.endTime}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Tid</ProductLabel>
            <ProductValue>{summary.interval}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Kommentar</ProductLabel>
            <GroupComment data={data} />
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Rabatt</ProductLabel>
            <ProductValue>{`${summary.discount} kr`}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>{`Repeteras (${summary.repeats})`}</ProductLabel>
            <ProductValue>{`${summary.repeatLabel}`}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Pris</ProductLabel>
            <ProductValue>{`${summary.price} kr`}</ProductValue>
          </ProductColumn>
          <ProductColumn />
          <ProductEdit
            value={<i className={expand ? 'icon-angle-up' : 'icon-angle-down'} />}
            onClick={() => setExpand(v => !v)}
          />
          <ProductDelete value={<i className="icon-trash" />} onClick={handleDeleteClick} />
        </ProductColumns>
      </ProductContainer>
      {expand && data.map(e => <SubProduct key={e.id} data={e} />)}
    </>
  );
};

Grouped.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(BasketType)).isRequired
};

export default Grouped;
