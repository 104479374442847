import { ADMIN, WORKER } from './Variables';
import Dashboard from '../Containers/Dashboard/Dashboard';
import Camping from '../Containers/Camping/Camping';
import Fields from '../Containers/Fields/Fields';
import Conferences from '../Containers/Conference/Conferences';
import Items from '../Containers/Items/Items';
import Statistics from '../Containers/Statistics/Statistics';
import Profile from '../Containers/Profile/Profile';
import Basket from '../Containers/Basket/Basket';
import Products from '../Containers/Products/Products';
import Users from '../Containers/Users/Users';
import Checkout from '../Containers/Checkout/Checkout';
import Reference from '../Containers/Reference/Reference';
import Settings from '../Containers/Settings/Settings';
import Bookings from '../Containers/Bookings/Bookings';
import Economics from '../Containers/Economics/Economics';
import Search from '../Containers/Search/Search';
import ConferenceCalendar from '../Containers/Conference/Components/ConferenceCalendar';
import FieldCalendar from '../Containers/Fields/Components/FieldCalendar';
import LockerCalendar from '../Containers/Fields/Components/LockerCalendar';
import Marks from '../Containers/Marks/Marks';

const routes = [
  {
    path: '/',
    title: 'Dashboard',
    icon: 'icon-home',
    component: Dashboard,
    access: [ADMIN.type, WORKER.type],
    menu: true,
    exact: true
  },
  {
    path: '/camping',
    title: 'Camping',
    icon: 'icon-camp',
    component: Camping,
    access: [ADMIN.type, WORKER.type],
    menu: true,
    exact: true
  },
  {
    path: '/fields',
    title: 'Ytor',
    icon: 'icon-fields',
    component: Fields,
    access: [ADMIN.type],
    menu: true,
    exact: true
  },
  {
    path: '/fields/:id',
    title: 'Ytor',
    icon: 'icon-football',
    component: FieldCalendar,
    access: [ADMIN.type],
    menu: false
  },
  {
    path: '/lockers/:id',
    title: 'Ytor',
    icon: 'icon-football',
    component: LockerCalendar,
    access: [ADMIN.type],
    menu: false
  },
  {
    path: '/conferences',
    title: 'Konferens',
    icon: 'icon-conference',
    component: Conferences,
    access: [ADMIN.type],
    menu: true,
    exact: true
  },
  {
    path: '/conferences/:id',
    title: 'Konferens',
    icon: 'icon-conference',
    component: ConferenceCalendar,
    access: [ADMIN.type],
    menu: false
  },
  {
    path: '/items',
    title: 'Artiklar',
    icon: 'icon-box',
    component: Items,
    access: [ADMIN.type, WORKER.type],
    menu: true,
    exact: true
  },
  {
    path: '/economics',
    title: 'Ekonomi',
    icon: 'icon-dollar',
    component: Economics,
    access: [ADMIN.type],
    menu: true,
    exact: true
  },
  {
    path: '/statistics',
    title: 'Statistik',
    icon: 'icon-chart-line',
    component: Statistics,
    access: [ADMIN.type, WORKER.type],
    menu: true,
    exact: true
  },
  {
    path: '/profile',
    title: 'Profil',
    icon: 'icon-user',
    component: Profile,
    access: [ADMIN.type, WORKER.type],
    menu: false,
    exact: true
  },
  {
    path: '/basket',
    title: 'Varukorg',
    component: Basket,
    access: [ADMIN.type, WORKER.type],
    exact: true
  },
  {
    path: '/products',
    title: 'Produkter',
    icon: 'icon-edit',
    component: Products,
    access: [ADMIN.type],
    menu: true,
    exact: true
  },
  {
    path: '/users',
    title: 'Användare',
    icon: 'icon-users',
    component: Users,
    access: [ADMIN.type],
    menu: true,
    exact: true
  },
  {
    path: '/checkout',
    title: 'Kassa',
    component: Checkout,
    access: [ADMIN.type, WORKER.type],
    exact: true
  },
  {
    path: '/bookingReference/:referenceID',
    title: 'Bokningsreferens',
    icon: 'icon-book',
    component: Reference,
    access: [ADMIN.type, WORKER.type],
    menu: false
  },
  {
    path: '/settings',
    title: 'Inställningar',
    component: Settings,
    access: [ADMIN.type],
    exact: true
  },
  {
    path: '/bookings',
    title: 'Bokningar',
    icon: 'icon-book',
    component: Bookings,
    access: [ADMIN.type, WORKER.type],
    menu: true,
    exact: true
  },
  {
    path: '/search',
    title: 'Sök',
    icon: 'icon-search',
    component: Search,
    access: [ADMIN.type, WORKER.type],
    menu: false,
    exact: true
  },
  {
    path: '/marking',
    title: 'Märkning',
    icon: 'icon-tags',
    component: Marks,
    access: [ADMIN.type],
    menu: true,
    exact: true
  }
];

export default routes;

/*
{
    path: '/discounts',
    title: 'Rabatter',
    icon: 'icon-tags',
    component: Coupons,
    access: [ADMIN.type],
    menu: true,
    exact: true
}
*/
