import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Grid, Main } from '../../Styles/Styles';
import Header from '../../Components/Header/Header';
import Donut from '../../Components/Statistics/Donut';
import useForm from '../../Hooks/useForm';
import DatePicker from '../../Components/Forms/DatePicker';
import { CAMPING, CONFERENCE, FIELD, HOSTEL, HOUSING_TYPE, LOCKER } from '../../Library/Variables';
import Select from '../../Components/Forms/Select';
import { Media } from '../../Resources/Stylesheets/Variables';
import { useGetStatistics } from '../../Library/Hooks';
import { MainLoader } from '../../Components/Loader/Loader';
import List from '../../Components/Statistics/List';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(${Media.phone}, 1175px);
  grid-auto-rows: max-content;
  grid-gap: 30px;
  justify-content: center;
  margin-bottom: 30px;
`;

const Form = styled.div`
  display: grid;
  grid-auto-columns: 275px;
  grid-auto-flow: column;
  grid-gap: 30px;
  align-items: center;
`;

const settingsSelector = state => state.settings.camping;

const selectCheckTime = createSelector(settingsSelector, state => {
  const checkInSplit = state.checkIn.split(':').map(e => parseInt(e, 10));
  const checkOutSplit = state.checkOut.split(':').map(e => parseInt(e, 10));
  return { checkIn: checkInSplit, checkOut: checkOutSplit };
});

const Statistics = () => {
  const { checkIn, checkOut } = useSelector(selectCheckTime);
  const { values, handleChange } = useForm({
    from: new Date(),
    to: new Date(),
    campingType: null
  });

  const { loading, data, request } = useGetStatistics();

  useEffect(() => {
    if (checkIn) {
      const from = new Date(values.from.setHours(checkOut[0], checkOut[1]));
      const to = new Date(values.to.setHours(checkIn[0], checkIn[1]));
      request({
        from,
        to
      });
    }
  }, [checkIn, checkOut, request, values.from, values.to]);

  if (loading) return <MainLoader />;

  const camping = data && {
    bookings: values.campingType
      ? data.campingType.filter(e => e.type === values.campingType).reduce((s, e) => s + e.amount, 0)
      : data.productType.filter(e => e.type === CAMPING).reduce((s, e) => s + e.amount, 0),
    nights: values.campingType
      ? data.nights.filter(e => e.type === values.campingType).reduce((s, e) => s + e.amount, 0)
      : data.nights.reduce((s, e) => s + e.amount, 0),
    guests: values.campingType
      ? data.guests.filter(e => e.type === values.campingType).reduce((s, e) => s + e.amount, 0)
      : data.guests.reduce((s, e) => s + e.amount, 0),
    countries: data.countryGuests.map(e => ({ country: e.type.trim().toLowerCase(), amount: e.amount }))
  };

  const fields = data && {
    bookings: data.productType.filter(e => e.type === FIELD).reduce((s, e) => s + e.amount, 0)
  };

  const lockers = data && {
    bookings: data.productType.filter(e => e.type === LOCKER).reduce((s, e) => s + e.amount, 0)
  };

  const conferences = data && {
    bookings: data.productType.filter(e => e.type === CONFERENCE).reduce((s, e) => s + e.amount, 0)
  };

  return (
    <Main>
      <Container>
        <Header title="Statistik" />
        <Form>
          <DatePicker label="Från" name="from" value={values.from} onChange={handleChange} disableAfter={values.to} />
          <DatePicker label="Till" name="to" value={values.to} onChange={handleChange} disableBefore={values.from} />
        </Form>

        <Header title="Sammanfattning" />
        <Grid>
          <Donut title="Antal bokningar" value={data.bookingsNr} unit="st" loading={loading} />
        </Grid>

        <Header title="Camping" />
        <Form>
          <Select
            label="Boende"
            name="campingType"
            value={values.campingType}
            onChange={handleChange}
            options={HOUSING_TYPE}
          />
        </Form>
        <Grid>
          <Donut title="Antal bokade boenden" value={camping.bookings} unit="st" loading={loading} />
          <Donut title="Antal nätter" value={camping.nights} unit="st" loading={loading} />
          <Donut title="Antal gäster" value={camping.guests} unit="st" loading={loading} />
          <List spec={{ label: 'Antal Länder', data: camping.countries }} />
          {values.campingType === HOSTEL && (
            <Donut title="Gästnätter" value={data.hostelGuests.guestNights} unit="st" loading={loading} />
          )}
          {values.campingType === HOSTEL && (
            <Donut title="Belagda rum" value={data.hostelGuests.usedRooms} unit="st" loading={loading} />
          )}
        </Grid>

        <Header title="Ytor" />
        <Grid>
          <Donut title="Antal bokade ytor" value={fields.bookings} unit="st" loading={loading} />
        </Grid>

        <Header title="Omklädningsrum" />
        <Grid>
          <Donut title="Antal bokade rum" value={lockers.bookings} unit="st" loading={loading} />
        </Grid>

        <Header title="Konferens" />
        <Grid>
          <Donut title="Antal bokade rum" value={conferences.bookings} unit="st" loading={loading} />
        </Grid>
      </Container>
    </Main>
  );
};

export default Statistics;
