import { useEffect } from 'react';
import useForm from '../../Hooks/useForm';

export const upTo999Pattern = '^([0-9]|[0-9][0-9]|[0-9][0-9][0-9])$';
export const vatPattern = '^([0-9]|[0-9][0-9]|100)$';
export const hhmmPattern = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';
export const namePattern =
  "^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$";

const test = (r, v) => new RegExp(r).test(v);

const validate = ([key, value]) => {
  let pattern = null;
  switch (key) {
    case 'vat':
      pattern = vatPattern;
      break;
    case 'checkIn':
    case 'checkOut':
      pattern = hhmmPattern;
      pattern = hhmmPattern;
      break;
    case 'basketTime':
      pattern = upTo999Pattern;
      break;
    default:
      throw Error(`Unknown setting: ${key}`);
  }
  return test(pattern, value);
};

const reducer = (s, e) => s && e;

export const validateSettings = values =>
  Object.entries(values)
    .map(validate)
    .reduce(reducer, true);

export const useSettings = (settings, setValues) => {
  const { values, handleChange } = useForm(settings);
  useEffect(() => {
    if (validateSettings(values)) setValues(values);
  }, [setValues, values]);
  return { values, handleChange };
};
