import { useGetProducts, useGetPublicSettings } from '../../Library/Hooks';
import { useAll } from './useData';

const usePublicData = refetch => {
  const ret = { error: null, loading: false };
  useAll(useGetPublicSettings, ret, refetch);
  useAll(useGetProducts, ret, refetch);
  return ret;
};

export default usePublicData;
