export const SET_TODAY_BOOKINGS = 'SET_TODAY_BOOKINGS';
export const SET_CURRENT_BOOKINGS = 'SET_CURRENT_BOOKINGS';
export const SET_LATEST_BOOKINGS = 'SET_LATEST_BOOKINGS';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const ADD_BOOKING = 'ADD_BOOKING';
export const DEL_BOOKING = 'DEL_BOOKING';
export const DEL_BOOKINGS = 'DEL_BOOKINGS';

export const setTodayBookings = payload => ({
  type: SET_TODAY_BOOKINGS,
  payload
});
export const setCurrentBookings = payload => ({
  type: SET_CURRENT_BOOKINGS,
  payload
});
export const setLatestBookings = payload => ({
  type: SET_LATEST_BOOKINGS,
  payload
});

export const updateBooking = payload => ({
  type: UPDATE_BOOKING,
  payload
});

export const addBooking = payload => ({
  type: ADD_BOOKING,
  payload
});

export const deleteBooking = payload => ({
  type: DEL_BOOKING,
  payload
});

export const deleteBookings = payload => ({
  type: DEL_BOOKINGS,
  payload
});
