import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Fonts, Dimensions, Media } from '../../Resources/Stylesheets/Variables';
import { useComponentVisible } from '../../Hooks';

const selectHeight = '50px';
const itemHeight = '50px';

const Container = styled.div`
  user-select: none;
  position: relative;
  padding: 0 10px;
  background: ${props => props.theme.background};
  border: 2px solid ${props => props.theme.border};
  border-top-left-radius: ${Dimensions.cornerRadius};
  border-top-right-radius: ${Dimensions.cornerRadius};
  border-bottom-left-radius: ${props => (props.active ? 0 : Dimensions.cornerRadius)};
  border-bottom-right-radius: ${props => (props.active ? 0 : Dimensions.cornerRadius)};
  color: ${props => props.theme.color};
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr;
  grid-template-rows: ${selectHeight} 1fr;
  grid-template-areas: 'label' 'dropdown';
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  outline: none;
  width: 100%;
`;

export const Label = styled.div`
  grid-area: label;
  color: ${props => props.theme.color};
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeTiny};
  cursor: pointer;
`;

export const Header = styled.i`
  position: absolute;
  top: ${props => (props.active ? '2px' : '-13px')};
  left: 10px;
  font-size: ${props => (props.active ? Fonts.sizeTiny : '0.7rem')};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  transition: 300ms ease-out;
  line-height: 50px;
  z-index: 0;
`;

export const Icon = styled.i`
  transform: ${props => (props.active ? `rotateZ(180deg)` : `none`)};
  transition: 200ms ease-in-out;
  font-size: ${Fonts.sizeTiny};
`;

const Dropdown = styled.div`
  background: ${props => props.theme.background};
  display: ${props => (props.active ? 'block' : 'none')};
  position: absolute;
  top: ${selectHeight};
  left: -2px;
  right: -2px;
  box-shadow: 1px 5px 5px -7px ${Colors.black};
  border-bottom-left-radius: ${Dimensions.cornerRadius};
  border-bottom-right-radius: ${Dimensions.cornerRadius};
  border-left: 2px solid ${props => props.theme.border};
  border-right: 2px solid ${props => props.theme.border};
  border-bottom: 2px solid ${props => props.theme.border};
  max-height: 300px;
  overflow-y: auto;

  @media (max-width: ${Media.phone}) {
    grid-area: dropdown;
    top: -2px;
  }
`;

export const Text = styled.p`
  line-height: 50px;
  padding-top: 8px;
  font-size: ${Fonts.sizeSmall};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  color: ${props => props.theme.color};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Item = styled.div`
  line-height: ${itemHeight};
  padding: 0 10px;
  font-size: ${Fonts.sizeTiny};
  font-family: ${Fonts.text};
  background: ${props => (props.selected ? props.theme.main : 'none')};
  color: ${props => props.theme.color};
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  &:hover {
    background: ${props => props.theme.main};
  }
  &:last-of-type {
    border-bottom-left-radius: ${Dimensions.cornerRadius};
    border-bottom-right-radius: ${Dimensions.cornerRadius};
  }
`;

const Select = ({ name, label, options, value, onChange }) => {
  const { ref, visible, setVisible } = useComponentVisible(false);

  const [val, setVal] = useState({ type: '', label: '' });

  useEffect(() => {
    const p = value && options.find(o => o.type === value);
    setVal(p || { type: '', label: '' });
  }, [options, value]);

  useEffect(() => {
    if (visible) onChange({ target: { value, name } });
  }, [name, onChange, value, visible]);

  return (
    <Container active={visible} ref={ref} tabIndex={0}>
      <Label active={visible} onClick={() => setVisible(v => !v)}>
        <Header active={(!visible && !val.label) || (visible && !val.label)}>{label}</Header>
        <Text>{val.label}</Text>
        <Icon active={visible} className="icon-angle-down" />
      </Label>
      <Dropdown active={visible}>
        {options.map(e => (
          <Item
            key={e.type}
            selected={e.type === val.type}
            onClick={event => {
              event.target.name = name;
              if (e.type !== val.type) {
                event.target.value = e.type;
                onChange(event);
                setVal({ type: e.type, label: e.label });
                setVisible(false);
              } else {
                event.target.value = '';
                onChange(event);
                setVal({ type: '', label: '' });
              }
            }}
          >
            {e.label}
            <i className={e.type === val.type ? 'icon-cancel-circled' : ''} />
          </Item>
        ))}
      </Dropdown>
    </Container>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired
};

Select.defaultProps = {
  value: null
};

export default Select;
