import qs from 'query-string';
import { GET, POST, PUT, DELETE, getJSON, deleteJSON, postJSON, putJSON, formatBody } from './Fetch';

const apiBuilder = (url, method) => {
  switch (method) {
    case GET:
      return () => getJSON(url);
    case DELETE:
      return () => deleteJSON(url);
    case POST:
      return body => postJSON(url, body);
    case PUT:
      return body => putJSON(url, body);
    default:
      return null;
  }
};

const apiBuilderURLParam = (url, method) => {
  const rep = (o, u) => {
    formatBody(o);
    return Object.entries(o).reduce((s, [k, v]) => s.replace(`$${k}`, v), u);
  };

  switch (method) {
    case GET:
      return o => getJSON(rep(o, url));
    case DELETE:
      return o => deleteJSON(rep(o, url));
    case POST:
      return o => postJSON(rep(o, url), o);
    case PUT:
      return o => putJSON(rep(o, url), o);
    default:
      return null;
  }
};

const apiBuilderQueryParam = (url, method) => {
  const rep = o => {
    formatBody(o);
    return `${url}?${qs.stringify(o)}`;
  };

  switch (method) {
    case GET:
      return o => getJSON(rep(o));

    case DELETE:
      return o => deleteJSON(rep(o));
    case POST:
      return o => postJSON(rep(o), o.body);
    case PUT:
      return o => putJSON(rep(o), o.body);
    default:
      return null;
  }
};

export const getUsers = apiBuilder('/user', GET);
export const getUser = apiBuilderURLParam('/user/$id', GET);
export const createUser = apiBuilder('/user', PUT);
export const deleteUser = apiBuilderURLParam('/user/$id', DELETE);
export const setUserRole = apiBuilderURLParam('/user/$id/role', POST);
export const getProfile = apiBuilder('/user/info', GET);
export const updateProfile = apiBuilder('/user/info', POST);
export const changePassword = apiBuilder('/user/password', POST);
export const setPassword = apiBuilder('/user/password', PUT);
export const forgotPassword = apiBuilderURLParam('/user/password/$id', POST);

// Basket
export const getBasket = apiBuilder('/basket', GET);
export const deleteBasket = apiBuilder('/basket', PUT);
export const addBasketProduct = apiBuilder('/basket/product', PUT);
export const editBasketProduct = apiBuilder('/basket/product', POST);
export const deleteBasketProduct = apiBuilderURLParam('/basket/product/$id', DELETE);
export const deleteBasketProductByGroupID = apiBuilderURLParam('/basket/product/group/$id', DELETE);

// Product
export const getProducts = apiBuilder('/product', GET);
export const addProduct = apiBuilder('/product', PUT);
export const getProduct = apiBuilderURLParam('/product/$id', GET);
export const editProduct = apiBuilder('/product', POST);
export const deleteProduct = apiBuilderURLParam('/product/$id', DELETE);
export const getUnoccupiedProducts = apiBuilderQueryParam('/product/unoccupied', GET);
export const getOccupiedProducts = apiBuilderQueryParam('/product/occupied', GET);
export const getBLArticles = apiBuilder('/product/article', GET);

export const getCoupons = apiBuilder('/coupon', GET);
export const addCoupon = apiBuilder('/coupon', PUT);
export const editCoupon = apiBuilder('/coupon', POST);
export const deleteCoupon = apiBuilderURLParam('/coupon/$id', DELETE);

export const getMarks = apiBuilder('/mark', GET);
export const addMark = apiBuilder('/mark', PUT);
export const editMark = apiBuilder('/mark', POST);
export const deleteMark = apiBuilderURLParam('/mark/$id', DELETE);

// Booking reference
export const getBookingReferences = apiBuilderQueryParam('/bookingReference', GET);
export const getBookingReference = apiBuilderURLParam('/bookingReference/$id', GET);
export const createBookingReference = apiBuilder('/bookingReference', PUT);
export const editBookingReference = apiBuilder('/bookingReference', POST);
export const deleteBookingReference = apiBuilderURLParam('/bookingReference/$id', DELETE);
export const addBasketToBookingReference = apiBuilder('/bookingReference/basket', PUT);
export const searchBookingReferences = apiBuilderQueryParam('/bookingReference/search', GET);
export const getLatestBookings = apiBuilder('/bookingReference?size=20', GET);
export const getTodaysNewBookings = apiBuilder('/bookingReference/today', GET);
export const getCurrentBookings = apiBuilder('/bookingReference/current', GET);
export const editBookingReferenceProduct = apiBuilder('/bookingReference/product', POST);
export const deleteBookingReferenceProduct = apiBuilderURLParam('/bookingReference/product/$id', DELETE);

export const getAvailableCamping = apiBuilderQueryParam('/camping/available', GET);
export const getCompany = apiBuilderURLParam('/company/$id', GET);
export const getSettings = apiBuilder('/settings', GET);
export const getPublicSettings = apiBuilder('/public/settings', GET);
export const updateSettings = apiBuilder('/settings', POST);

export const getStatistics = apiBuilderQueryParam('/statistics', GET);

export const getCustomerBookingReferences = apiBuilderQueryParam('/bookingReference/customer', GET);
export const createInvoice = apiBuilder('/invoice', POST);
