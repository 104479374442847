import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SubProduct from './SubProduct';
import Loader from '../../../Components/Loader/Loader';
import {
  Avatar,
  ProductColumn,
  ProductColumns,
  ProductDelete,
  ProductEdit,
  ProductContainer,
  ProductValue,
  ProductLabel
} from '../../../Styles/Styles';
import {
  addVat,
  calcTotPrice,
  calculateHours,
  cap,
  formatDate,
  formatPrice,
  formatTimeInterval
} from '../../../Library/Library';
import { BOOKING_TYPE, CALENDAR_REPEAT, FIELD } from '../../../Library/Variables';
import DeleteBookedProduct from './DeleteBookedProduct';
import PopOverForm from '../../../Components/PopOver/PopOverForm';
import { BasketType } from '../../../Types/types';
import EditGroupedBookedProduct from './EditGroupedBookedProduct';

const useValues = data => {
  const [values, setValues] = useState(null);

  useEffect(() => {
    setValues({
      referenceID: data[0].referenceID.toUpperCase(),
      price: formatPrice(data.reduce((s, e) => s + calcTotPrice(e), 0)),
      discount: formatPrice(data.reduce((s, e) => s + addVat(e.discount, e.vat), 0)),
      image: data[0].image,
      initials: data[0].name.charAt(0),
      name: cap(data[0].name),
      startTime: formatDate(data[0].startTime),
      endTime: formatDate(data[data.length > 0 && data.length - 1].endTime),
      interval: formatTimeInterval(data[0].startTime, data[0].endTime),
      hours: calculateHours(data[0].startTime, data[0].endTime),
      repeatLabel: data[0].spec.repeatEvery && CALENDAR_REPEAT.find(e => e.type === data[0].spec.repeatEvery).label,
      repeats: data.length,
      version: data[0].type === FIELD && BOOKING_TYPE.find(e => e.type === data[0].spec.version),
      comment: data[0].spec.comment
    });
  }, [data]);
  return values;
};

const Grouped = ({ data, refetch }) => {
  const [expand, setExpand] = useState(false);
  const summary = useValues(data);
  const [deleting, showDelete] = useState(false);
  const [editing, showEdit] = useState(false);

  if (!summary) return <Loader active />;

  return (
    <>
      <ProductContainer>
        <Avatar image={summary.image} initials={summary.initials} size="46px" />
        <ProductColumns columns={6}>
          <ProductColumn>
            <ProductValue>{summary.name}</ProductValue>
            {summary.version && <ProductLabel>{summary.version.label}</ProductLabel>}
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>{summary.startTime}</ProductLabel>
            <ProductValue>{summary.endTime}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Tid</ProductLabel>
            <ProductValue>{summary.interval}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Kommentar</ProductLabel>
            <ProductValue>{summary.comment || ` `}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>{`Repeteras (${summary.repeats})`}</ProductLabel>
            <ProductValue>{`${summary.repeatLabel}`}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Tot. Rabatt</ProductLabel>
            <ProductValue>{`${summary.discount} kr`}</ProductValue>
          </ProductColumn>
          <ProductColumn />
          <ProductColumn>
            <ProductLabel>Tot. Pris</ProductLabel>
            <ProductValue>{`${summary.price} kr`}</ProductValue>
          </ProductColumn>
          <ProductEdit
            value={<i className={expand ? 'icon-angle-up' : 'icon-angle-down'} />}
            onClick={() => setExpand(v => !v)}
          />
          <ProductDelete value={<i className="icon-edit" />} onClick={() => showEdit(true)} />
          <ProductDelete value={<i className="icon-trash" />} onClick={() => showDelete(true)} />
        </ProductColumns>
      </ProductContainer>
      {expand && data.map(e => <SubProduct key={e.id} data={e} refetch={refetch} />)}
      {editing && (
        <PopOverForm
          data={{
            id: summary.referenceID,
            name: `${summary.name} (${data.length})`,
            image: data.image,
            initials: 'B',
            title: 'Redigerar produkt',
            text: 'Den enskilda kommentaren för varje produkt i denna grupp att ändras.',
            submitLabel: 'Spara'
          }}
          Form={EditGroupedBookedProduct}
          formID="EditGroupedBookedProduct"
          formData={data}
          onCancel={() => showEdit(false)}
          refetch={refetch}
        />
      )}
      {deleting && (
        <PopOverForm
          data={{
            id: summary.referenceID,
            name: `${summary.name} (${data.length})`,
            image: data.image,
            initials: 'B',
            title: 'Vill du verkligen ta bort alla produkter i denna grupp från bokningen?',
            text: `Denna åtgärd går inte att ångra.`,
            submitLabel: 'Radera produkter'
          }}
          Form={DeleteBookedProduct}
          formID="DeleteBookedProduct"
          formData={data}
          onCancel={() => showDelete(false)}
          refetch={refetch}
        />
      )}
    </>
  );
};

Grouped.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(BasketType)).isRequired,
  refetch: PropTypes.func.isRequired
};

export default Grouped;
