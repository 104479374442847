import React from 'react';
import PropTypes from 'prop-types';
import { calcTotPrice, calculateHours, formatDate, formatPrice, formatTimeInterval } from '../../../Library/Library';
import Discount from './Discount';
import Vat from './Vat';
import { useDeleteBasketProduct } from '../../../Library/Hooks';
import { BasketType } from '../../../Types/types';
import {
  ProductColumn,
  SubProductColumns,
  ProductDelete,
  SubProductContainer,
  SubProductWrapper,
  ProductLabel,
  ProductValue
} from '../../../Styles/Styles';
import Comment from './Comment';

const SubProduct = ({ data }) => {
  const { id, vat } = data;
  const { request } = useDeleteBasketProduct();

  const handleDeleteClick = async () => request({ id });

  return (
    <SubProductContainer>
      <ProductColumn />
      <SubProductWrapper>
        <ProductColumn />
        <SubProductColumns columns={5}>
          <ProductColumn>
            <ProductLabel>{formatDate(data.startTime)}</ProductLabel>
            <ProductValue>{formatTimeInterval(data.startTime, data.endTime)}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Timmar</ProductLabel>
            <ProductValue>{`${calculateHours(data.startTime, data.endTime)} h`}</ProductValue>
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Kommentar</ProductLabel>
            <Comment data={data} />
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Rabatt</ProductLabel>
            <Discount data={data} />
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Moms</ProductLabel>
            <Vat data={data} value={vat} />
          </ProductColumn>
          <ProductColumn>
            <ProductLabel>Pris</ProductLabel>
            <ProductValue>{`${formatPrice(calcTotPrice(data))} kr`}</ProductValue>
          </ProductColumn>
          <ProductColumn />
          <ProductColumn />
          <ProductDelete value={<i className="icon-trash" />} onClick={handleDeleteClick} />
        </SubProductColumns>
      </SubProductWrapper>
    </SubProductContainer>
  );
};

SubProduct.propTypes = {
  data: PropTypes.shape(BasketType).isRequired
};

export default SubProduct;
