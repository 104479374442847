import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container as Template } from '../../../Styles/Styles';
import { COMPANY, PERSON } from '../../../Library/Variables';
import { cap } from '../../../Library/Library';
import Header from '../../../Components/Header/Header';

const Container = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

export const Rows = styled(Template)`
  display: grid;
  grid-auto-rows: max-content;
  align-items: center;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  border-bottom: 1px solid ${props => props.theme.border};
  &:last-of-type {
    border-bottom: none;
  }
`;

const Label = styled.p`
  display: grid;
  border-right: 1px solid ${props => props.theme.border};
  padding: 15px;
`;

const Data = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  padding: 15px;
`;

const Customer = ({ data }) => {
  const type = data.firstName ? PERSON : COMPANY;

  const Company = () => (
    <>
      <Row>
        <Label>Namn</Label>
        <Data>
          <p>{cap(data.name)}</p>
        </Data>
      </Row>
      <Row>
        <Label>Referens</Label>
        <Data>
          <p>{cap(data.reference)}</p>
        </Data>
      </Row>
    </>
  );

  const Person = () => (
    <>
      <Row>
        <Label>Namn</Label>
        <Data>
          <p>{cap(`${data.firstName} ${data.lastName}`)}</p>
        </Data>
      </Row>
    </>
  );

  return (
    <Container>
      <Header title="Kund" />
      <Rows>
        {type === PERSON && <Person />}
        {type === COMPANY && <Company />}
        <Row>
          <Label>E-Mail</Label>
          <Data>
            <p>{data.email}</p>
          </Data>
        </Row>
        <Row>
          <Label>Telefon</Label>
          <Data>
            <p>{data.phone}</p>
          </Data>
        </Row>
        <Row>
          <Label>Adress</Label>
          <Data>
            <p>{cap(data.address)}</p>
            <p>{`${data.zipCode} ${cap(data.city)}`}</p>
            <p>{cap(data.country)}</p>
          </Data>
        </Row>
      </Rows>
    </Container>
  );
};

Customer.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    customerID: PropTypes.string,
    reference: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    comment: PropTypes.string
  })
};

Customer.defaultProps = { data: null };

export default Customer;
