import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Fonts, Media, ZIndex } from '../../../Resources/Stylesheets/Variables';
import { Ripple, Tooltip } from '../../../Styles/Styles';
import routes from '../../../Library/Routes';

const Container = styled.div`
  background: ${props => props.theme.background};
  border-right: 2px solid ${props => props.theme.border};
  grid-area: side;
  display: grid;
  grid-auto-rows: 70px;
  grid-auto-columns: 70px;
  grid-auto-flow: row;
  z-index: ${ZIndex.sideBar};
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-auto-flow: column;
    overflow-x: auto;
    overflow-y: hidden;
    border-right: none;
    border-top: 2px solid ${props => props.theme.border};
  }
`;

const Button = styled(Ripple)`
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: ${Fonts.sizeRegular};
  color: ${props => props.theme.color};

  svg {
    border-radius: 0;
  }

  &:hover,
  &.active {
    color: ${props => props.theme.accent};
  }
`;

const SideBar = () => {
  const profile = useSelector(state => state.profile);

  if (!profile) {
    return null;
  }

  return (
    <Container>
      {routes
        .filter(e => e.access.includes(profile.role) && e.menu)
        .map(e => (
          <Tooltip key={e.title} text={e.title} component={<Button exact to={e.path} className={e.icon} />} right />
        ))}
    </Container>
  );
};

Button.displayName = 'Button';

export default SideBar;
