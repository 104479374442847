import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dimensions, Colors, Fonts, Shadows, ZIndex } from '../../Resources/Stylesheets/Variables';
import { useComponentVisible } from '../../Hooks';
import { WEEKDAYS } from '../../Library/Variables';
import { isSameDate, formatDate, cap } from '../../Library/Library';
import { Ripple } from '../../Styles/Styles';

const Container = styled.div`
  display: grid;
  z-index: ${props => props.zIndex};
`;

const Edit = styled.div`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 50px;
  position: relative;
  cursor: pointer;
  padding: 0 0 0 10px;
  align-items: center;
  border-top-left-radius: ${Dimensions.cornerRadius};
  border-top-right-radius: ${Dimensions.cornerRadius};
  border-bottom-left-radius: ${props => (props.active ? 0 : Dimensions.cornerRadius)};
  border-bottom-right-radius: ${props => (props.active ? 0 : Dimensions.cornerRadius)};
  border-left: 2px solid ${props => (props.invalid ? props.theme.negative : props.theme.border)};
  border-right: 2px solid ${props => (props.invalid ? props.theme.negative : props.theme.border)};
  border-bottom: 2px solid
    ${props => {
      if (props.invalid) {
        if (props.active) {
          return 'transparent';
        }
        return Colors.strawberry;
      }
      if (props.active) {
        return 'transparent';
      }
      return props.theme.border;
    }};
  border-top: 2px solid ${props => (props.invalid ? props.theme.negative : props.theme.border)};
`;

const Label = styled.i`
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
`;

const Angle = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => (props.active ? `rotateZ(180deg)` : `none`)};
  transition: 200ms ease-in-out;
  font-size: 1rem;
  width: 100%;
  height: 100%;
`;

const DateLabel = styled.p`
  font-size: ${Fonts.sizeSmall};
`;

const Dropdown = styled.div`
  display: ${props => (props.active ? 'grid' : 'none')};
  grid-template-columns: 1fr;
  grid-template-rows: 30px 30px 1fr;
  grid-template-areas: 'month' 'week' 'days';
  grid-gap: 5px;
  justify-content: center;
  padding: 20px;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  position: absolute;
  top: 50px;
  left: -2px;
  right: -2px;
  box-shadow: ${Shadows.regular};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 2px solid ${props => (props.bad ? props.theme.negative : props.theme.border)};
  border-right: 2px solid ${props => (props.bad ? props.theme.negative : props.theme.border)};
  border-bottom: 2px solid ${props => (props.bad ? props.theme.negative : props.theme.border)};
  z-index: ${ZIndex.dropdown};
`;

const Month = styled.div`
  grid-area: month;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const Week = styled.div`
  grid-area: week;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
`;

const WeekLabel = styled.i`
  font-size: 0.7rem;
  color: ${props => (props.current ? props.theme.accent : props.theme.color)};
  font-weight: ${Fonts.weightBold};
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
`;

const Days = styled.div`
  grid-area: days;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
`;

const Day = styled.i`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 0.7rem;
  user-select: none;
  cursor: pointer;
  transition: 200ms ease-out;
  border-radius: ${props => (props.selectedAsFrom || props.selectedAsTo ? '50%' : '0')};
  ${props => {
    if (props.disabled || props.afterEndTime) {
      return `
          opacity: 0.2;
          pointer-events: none;
          color: ${props.theme.color};
        `;
    }
    if (props.selected) {
      return `
				background: ${props.theme.accent};
        border-radius: 50%;
        color: ${props.theme.color};
      `;
    }
    if (props.today) {
      return `
        color: ${props.theme.accent};
        font-weight: ${Fonts.weightBold};
      `;
    }
    return `
      color: ${props.theme.color};
    `;
  }}
  &:hover {
    color: ${Colors.white};
    background: ${props => props.theme.accent};
    opacity: 1;
  }
`;

const getDaysOfMonth = date => new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0).getDate();

const DatePicker = ({
  label,
  name,
  value,
  onChange,
  disabledDates,
  disableBefore,
  disableAfter,
  hour,
  minute,
  invalid
}) => {
  const [monthOptions, setMonthOptions] = useState({ month: 'short' });
  const { ref, visible, setVisible } = useComponentVisible(false);
  const [days, setDays] = useState(getDaysOfMonth(value));
  const [month, setMonth] = useState(value);
  const [val, setVal] = useState(value || new Date());

  useEffect(() => {
    setMonthOptions(
      new Date(month).getFullYear() === new Date().getFullYear()
        ? { month: 'long' }
        : { month: 'short', year: 'numeric' }
    );
    setDays(getDaysOfMonth(month));
  }, [month]);

  /*
  const isOkMonth = () => {
    const now = new Date();
    now.setMonth(now.getMonth() - 12);
    return now;
  };
   */

  const isOccupied = d =>
    disabledDates &&
    disabledDates.filter(e => {
      const startTime = new Date(e.startTime).getTime();
      const endTime = new Date(e.endTime).getTime();
      return d >= startTime && d <= endTime;
    }).length > 0;

  const isBefore = d =>
    disableBefore &&
    new Date(disableBefore).getTime() > new Date(d).getTime() &&
    new Date(value).getTime() > new Date(d).getTime();

  const isAfter = d =>
    disableAfter &&
    new Date(disableAfter).getTime() < new Date(d).getTime() &&
    new Date(value).getTime() < new Date(d).getTime();

  return (
    <Container>
      <Edit ref={ref} active={visible} invalid={invalid}>
        <div role="button" tabIndex="-4" onKeyDown={() => setVisible(v => !v)} onClick={() => setVisible(v => !v)}>
          <Label>{label}</Label>
          <DateLabel>{formatDate(val)}</DateLabel>
        </div>
        <Angle active={visible} className="icon-angle-down" onClick={() => setVisible(v => !v)} />
        <Dropdown active={visible}>
          <Month>
            <Ripple
              type="button"
              value={<p className="icon-angle-left" />}
              onClick={() => setMonth(m => new Date(m).setMonth(new Date(m).getMonth() - 1))}
            />
            <h5>{cap(new Date(month).toLocaleDateString('sv-SE', monthOptions))}</h5>
            <Ripple
              type="button"
              value={<p className="icon-angle-right" />}
              onClick={() => setMonth(m => new Date(m).setMonth(new Date(m).getMonth() + 1))}
            />
          </Month>
          <Week>
            {WEEKDAYS.map(e => (
              <WeekLabel key={e.id} current={e.id === new Date().getDay()}>
                {e.label}
              </WeekLabel>
            ))}
          </Week>
          <Days>
            {(() => {
              const d = [];
              const firstDay = new Date(new Date(month).getFullYear(), new Date(month).getMonth(), 1);
              if (firstDay.getDay() === 0) {
                for (let i = 0; i < 6; i += 1) {
                  d.push(<i key={Math.random()} />);
                }
              } else {
                for (let i = 0; i < firstDay.getDay() - 1; i += 1) {
                  d.push(<i key={Math.random()} />);
                }
              }
              for (let i = 1; i <= days; i += 1) {
                const tempDate = new Date(new Date(month).getFullYear(), new Date(month).getMonth(), i, hour, minute);
                d.push(
                  <Day
                    key={i.toString()}
                    onClick={event => {
                      event.target.name = name;
                      event.target.value = tempDate;
                      onChange(event);
                      setVal(tempDate);
                      setVisible(false);
                    }}
                    today={isSameDate(new Date(), tempDate)}
                    selected={isSameDate(val, tempDate)}
                    disabled={isOccupied(tempDate) || isBefore(tempDate) || isAfter(tempDate)}
                  >
                    {i}
                  </Day>
                );
              }
              return d;
            })()}
          </Days>
        </Dropdown>
      </Edit>
    </Container>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  disabledDates: PropTypes.arrayOf(PropTypes.shape({ startTime: PropTypes.string, endTime: PropTypes.string })),
  disableBefore: PropTypes.instanceOf(Date),
  disableAfter: PropTypes.instanceOf(Date),
  hour: PropTypes.number,
  minute: PropTypes.number,
  invalid: PropTypes.bool
};

DatePicker.defaultProps = {
  disabledDates: null,
  disableBefore: null,
  disableAfter: null,
  hour: 0,
  minute: 0,
  invalid: false
};

export default DatePicker;
