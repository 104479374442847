import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts, Dimensions } from '../../Resources/Stylesheets/Variables';
import { calcVat } from '../../Library/Library';

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px max-content;
  border-radius: ${Dimensions.cornerRadius};
  background: ${props => props.theme.background};
  border: 2px solid ${props => props.theme.border};
  color: ${props => props.theme.color};
  grid-gap: 5px;
  height: 50px;
`;

const Enter = styled.input`
  position: relative;
  appearance: none;
  outline: none;
  border: none;
  background: none;
  line-height: 50px;
  padding: 8px 10px 0 10px;
  font-size: ${Fonts.sizeSmall};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  z-index: 1;
  color: ${props => props.theme.color};

  &:focus {
    outline: none;
  }
  /* Implement later
    &:valid + i::before {}
    &:invalid + i::before {}
  */
`;

const Header = styled.i`
  position: absolute;
  top: ${props => (props.active ? '2px' : '-13px')};
  left: 10px;
  font-size: ${props => (props.active ? Fonts.sizeTiny : Fonts.sizeSuperTiny)};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  transition: 300ms ease-out;
  line-height: 50px;
  z-index: 0;
`;

const Vat = styled.i`
  position: absolute;
  right: 10px;
  top: 3px;
  font-size: ${Fonts.sizeSuperTiny};
`;

const Price = forwardRef(
  ({ label, type, name, value, onChange, required, pattern, minLength, maxLength, vat }, ref) => {
    const [isEmpty, setIsEmpty] = useState(!value);
    const vatPrice = calcVat(value, vat);

    return (
      <Container>
        <Header active={!value && isEmpty}>{label}</Header>
        {!Number.isNaN(vatPrice) && <Vat>{`Moms ${vatPrice.toFixed(2)} kr`}</Vat>}
        <Enter
          ref={ref}
          type={type}
          name={name}
          required={required}
          pattern={pattern}
          minLength={minLength}
          maxLength={maxLength}
          onChange={onChange}
          value={value || ''}
          onFocus={() => setIsEmpty(false)}
          onBlur={e => setIsEmpty(!e.target.value)}
        />
      </Container>
    );
  }
);

Price.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vat: PropTypes.number.isRequired
};

Price.defaultProps = {
  name: null,
  required: false,
  pattern: null,
  minLength: 0,
  maxLength: PropTypes.value,
  value: null
};

export default Price;
