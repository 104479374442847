import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Ripple } from '../../Styles/Styles';
import { Media, Dimensions, Fonts } from '../../Resources/Stylesheets/Variables';
import Type from './Type/Type';
import Dates from './Dates/Dates';
import Guest from './Guest/Guest';

const Component = styled(Container)`
  grid-area: search;
  display: grid;
  grid-template-columns: 1.5fr 3fr 1.5fr ${Dimensions.navigationHeight};
  grid-template-rows: ${Dimensions.navigationHeight};
  grid-template-areas: 'type date guest button';
  cursor: pointer;
  @media (max-width: ${Media.phone}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, ${Dimensions.navigationHeight});
    grid-template-areas: 'type' 'date' 'guest' 'button';
  }
`;

const Button = styled(Ripple)`
  grid-area: button;
  display: flex;
  apperarence: none;
  outline: none;
  justify-content: center;
  align-items: center;
  font-size: ${Fonts.sizeRegular};
  color: ${props => props.theme.color}
  cursor: pointer;
  background: none;
  border: none;
  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const Search = ({ onSubmit, setDates, dates, setGuests, guests, type, setType }) => {
  return (
    <form onSubmit={onSubmit}>
      <Component>
        <Type type={type} setType={setType} />
        <Dates setDates={setDates} dates={dates} />
        <Guest setGuests={setGuests} guests={guests} />
        <Button className="icon-search" type="submit" />
      </Component>
    </form>
  );
};

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setDates: PropTypes.func.isRequired,
  dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  setGuests: PropTypes.func.isRequired,
  guests: PropTypes.number.isRequired,
  type: PropTypes.shape({ type: PropTypes.string, label: PropTypes.string }).isRequired,
  setType: PropTypes.func.isRequired
};

export default Search;
