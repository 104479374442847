import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container as Template, ProductColumn, ProductLabel, ProductValue } from '../../../Styles/Styles';
import { calcTotPrice, cap, formatDate, formatPrice, formatTimeInterval } from '../../../Library/Library';
import { CAMPING, CONFERENCE, FIELD, ITEM, LOCKER } from '../../../Library/Variables';

const Columns = styled(Template)`
  display: grid;
  grid-template-columns: 110px 150px repeat(7, 1fr);
  grid-template-rows: 70px;
  grid-auto-flow: column;
  grid-gap: 15px;
  padding: 0 15px;
  align-items: center;
`;

const hourBased = e => (
  <>
    <ProductColumn>
      <ProductLabel>Datum</ProductLabel>
      <ProductValue>{formatDate(e.startTime)}</ProductValue>
    </ProductColumn>
    <ProductColumn>
      <ProductLabel>Tid</ProductLabel>
      <ProductValue>{formatTimeInterval(e.startTime, e.endTime)}</ProductValue>
    </ProductColumn>
    <ProductColumn>
      <ProductLabel>Timmar</ProductLabel>
      <ProductValue>
        {formatTimeInterval(e.startTime, e.endTime) === 'Heldag' ? `${e.quantity} dag` : `${e.quantity} h`}
      </ProductValue>
    </ProductColumn>
  </>
);

const camping = e => (
  <>
    <ProductColumn>
      <ProductLabel>Från</ProductLabel>
      <ProductValue>{formatDate(e.startTime)}</ProductValue>
    </ProductColumn>
    <ProductColumn>
      <ProductLabel>Till</ProductLabel>
      <ProductValue>{formatDate(e.endTime)}</ProductValue>
    </ProductColumn>
    <ProductColumn>
      <ProductLabel>Nätter</ProductLabel>
      <ProductValue>{`${e.quantity} st`}</ProductValue>
    </ProductColumn>
  </>
);

const item = e => (
  <>
    <div />
    <div />
    <ProductColumn>
      <ProductLabel>Antal</ProductLabel>
      <ProductValue>{`${e.quantity} st`}</ProductValue>
    </ProductColumn>
  </>
);

const getColumns = e => {
  switch (e.type) {
    case CAMPING:
      return camping(e);
    case FIELD:
      return hourBased(e);
    case LOCKER:
      return hourBased(e);
    case CONFERENCE:
      return hourBased(e);
    case ITEM:
      return item(e);
    default:
      return null;
  }
};

const Booking = ({ data }) => {
  return (
    <Columns key={data.id}>
      <ProductColumn>
        <ProductLabel>Bokning</ProductLabel>
        <ProductValue>{data.referenceID.toUpperCase()}</ProductValue>
      </ProductColumn>
      <ProductColumn>
        <ProductLabel>Namn</ProductLabel>
        <ProductValue>{cap(data.name)}</ProductValue>
      </ProductColumn>
      <ProductColumn>
        <ProductLabel>Kommentar</ProductLabel>
        <ProductValue>{data.spec.comment || ' '}</ProductValue>
      </ProductColumn>
      {getColumns(data)}
      <ProductColumn>
        <ProductLabel>Pris</ProductLabel>
        <ProductValue>{`${formatPrice(data.price, data.vat)} kr`}</ProductValue>
      </ProductColumn>
      <ProductColumn>
        <ProductLabel>Andel</ProductLabel>
        <ProductValue>{`${data.percent} %`}</ProductValue>
      </ProductColumn>
      <ProductColumn>
        <ProductLabel>Totalt</ProductLabel>
        <ProductValue>{`${formatPrice(calcTotPrice(data))} kr`}</ProductValue>
      </ProductColumn>
    </Columns>
  );
};

Booking.propTypes = {
  data: PropTypes.shape({
    referenceID: PropTypes.string,
    customerID: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    vat: PropTypes.number,
    fieldID: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    userID: PropTypes.number,
    discount: PropTypes.number,
    itemType: PropTypes.string,
    comment: PropTypes.string,
    guests: PropTypes.number,
    groupID: PropTypes.number,
    percent: PropTypes.number,
    markPrice: PropTypes.number,
    spec: PropTypes.shape({ comment: PropTypes.string })
  }).isRequired
};

export default Booking;
