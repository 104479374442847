import React from 'react';
import qs from 'query-string';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../../../Styles/Styles';

const Pages = styled.div`
  display: grid;
  grid-auto-columns: 30px;
  grid-auto-flow: column;
  grid-template-rows: 40px;
  grid-gap: 5px;
  justify-content: center;
  margin-bottom: 30px;
`;

const Page = styled(Button)`
  background: ${props => (props.active ? props.theme.accent : 'none')};
  color: ${props => props.theme.color};
  display: grid;
  height: auto;
  width: auto;
  min-width: auto;
  padding: 0;
`;

const PageNumberer = ({ current, size, params, url }) => {
  let pageLinks;
  if (size < 8) {
    pageLinks = [1, 2, 3, 4, 5, 6, 7].filter(e => e <= size);
  } else if (current < 5) {
    pageLinks = [1, 2, 3, 4, 5, 'e', size];
  } else if (current > size - 4) {
    pageLinks = [1, 'b', size - 4, size - 3, size - 2, size - 1, size];
  } else {
    pageLinks = [1, 'b', current - 2, current - 1, current, current + 1, current + 2, 'e', size];
  }

  return (
    <Pages>
      {current > 1 && (
        <Page className="icon-angle-left" to={`${url}?${qs.stringify({ ...params, page: current - 1 })}`} />
      )}
      {pageLinks.map(e => {
        let val = e;
        if (e === 'b') {
          val = current - 5 > 0 ? current - 5 : 1;
        } else if (e === 'e') {
          val = current + 5 < size ? current + 5 : size;
        }
        const p = qs.stringify({ ...params, page: val });
        return (
          <Page key={e} active={current === val} value={e === 'b' || e === 'e' ? '...' : `${e}`} to={`${url}?${p}`} />
        );
      })}
      {current < size && (
        <Page className="icon-angle-right" to={`${url}?${qs.stringify({ ...params, page: current + 1 })}`} />
      )}
    </Pages>
  );
};

PageNumberer.propTypes = {
  current: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  params: PropTypes.shape({}).isRequired,
  url: PropTypes.string.isRequired
};

export default PageNumberer;
