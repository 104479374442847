import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Fonts } from '../../Resources/Stylesheets/Variables';
import { addErr } from '../../Reducers/Error/actions';

const Container = styled.form`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Icon = styled.label`
  position: absolute;
  color: ${props => props.theme.color};
  font-size: ${Fonts.sizeTiny};
  cursor: pointer;
`;

const Update = styled(Icon)`
  top: -20px;
  left: 3px;
  &:hover {
    color: ${props => props.theme.accent};
  }
  input {
    display: none;
  }
`;

const Delete = styled(Icon)`
  top: 3px;
  left: -20px;
  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const readFileAsync = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
};

/*
function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
*/

const ImagePicker = ({ Image, text, src, onUpdate, onDelete }) => {
  const dispatch = useDispatch();

  const updateIt = async file => {
    if (file !== null && file !== undefined) {
      if (file.size >= 2097152) {
        dispatch(addErr('Bildstorleken får inte överskrida 2MB.'));
        return;
      }

      const f = await readFileAsync(file);
      onUpdate(f);
    }
  };

  return (
    <Container>
      <Update className="icon-edit">
        <input type="file" onChange={e => updateIt(e.target.files[0])} />
      </Update>
      {src && <Delete className="icon-trash" onClick={onDelete} />}
      {src !== null ? <Image src={src} /> : <Image>{text}</Image>}
    </Container>
  );
};

ImagePicker.propTypes = {
  Image: PropTypes.elementType.isRequired,
  text: PropTypes.string,
  src: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

ImagePicker.defaultProps = {
  text: null,
  src: null
};

export default ImagePicker;
