import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useReactRouter from 'use-react-router';
import { useComponentVisible } from '../../../../Hooks';
import { Dimensions, Fonts, Media } from '../../../../Resources/Stylesheets/Variables';
import { Dropdown } from '../../../../Styles/Styles';
import { cap, getBookingName } from '../../../../Library/Library';
import { useSearchBookingReferences } from '../../../../Library/Hooks';
import useDebounce from '../../../../Hooks/useDebounce';

const Container = styled.div`
  grid-area: search;
  position: relative;
  width: 250px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    width: 100%;
  }
`;

const Bar = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 40px;
  grid-template-areas: 'icon input';
  justify-items: center;
  align-items: center;
  border: 2px solid ${props => (props.visible ? props.theme.border : 'transparent')};
  border-radius: ${Dimensions.cornerRadius};

  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-template-columns: 35px;
    grid-template-rows: 70px;
    grid-template-areas: 'icon';
    border: none;
  }
`;

const Icon = styled.i`
  grid-area: icon;
  color: ${props => (props.visible ? props.theme.accent : props.theme.color)};
  font-size: ${Fonts.sizeSmall};
  transform: ${props => (props.visible ? 'scaleX(-1)' : 'scaleX(1)')};
  transition: 200ms ease-out;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    color: ${props => props.theme.color};
    font-size: ${Fonts.sizeRegular};
  }
`;

const Input = styled.input`
  grid-area: input;
  width: 100%;
  padding: 0;
  background: none;
  color: ${props => props.theme.color};
  border: none;
  display: block;
  box-sizing: border-box;
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeTiny};
  outline: none;

  &::placeholder {
    color: ${props => props.theme.color};
  }

  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    display: none;
  }
`;

const Items = styled(Dropdown)`
  width: 100%;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    display: none;
  }
`;

const Item = styled.i`
  cursor: pointer;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 60px;
  grid-template-areas: 'indicator' 'label';
  transition: 200ms ease-out;
  color: ${props => (props.active ? props.theme.accent : props.theme.color)};

  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const Indicator = styled.i`
  grid-area: indicator;
  display: grid;
  justify-items: center;
  align-items: center;
  font-size: ${Fonts.sizeSmall};
`;

const Label = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  font-size: ${Fonts.sizeTiny};
`;

const Search = () => {
  const { history } = useReactRouter();
  const [searchStrings, setSearchString] = useState('');
  const searchString = useDebounce(searchStrings, 150);
  const input = useRef(null);
  const [ind, setInd] = useState(-1);
  const { ref, visible, setVisible } = useComponentVisible(false);
  const [vars, setVars] = useState(null);
  const [searchables, setSearchables] = useState([]);
  const { request, data } = useSearchBookingReferences(null);

  useEffect(() => {
    if (searchString.length < 3) setSearchables([]);
    else if (data) setSearchables(data.bookingReferences);
  }, [data, searchString.length]);

  useEffect(() => {
    if (searchString.length > 2) request({ searchString, size: 5, page: 1 });
  }, [request, searchString]);

  useEffect(() => {
    if (!visible && ind === -1 && vars) {
      input.current.blur();
      if (vars.argName) history.push(`${vars.path}?${vars.argName}=${vars.arg}`);
      else history.push(`${vars.path}/${vars.arg}`);
    }
  }, [visible, ind, vars, history]);

  const search = useCallback(
    (path, argName, arg) => {
      setVisible(false);
      setVars({ path, argName, arg });
      setInd(() => -1);
    },
    [setVisible]
  );

  const searchReference = useCallback(
    (path, arg) => {
      setVisible(false);
      setVars({ path, arg });
      setInd(() => -1);
    },
    [setVisible]
  );

  const handleUserKeyPress = useCallback(
    event => {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          setInd(prev => (prev > -1 ? prev - 1 : prev));
          break;
        case 'ArrowDown':
          event.preventDefault();
          setInd(prev => (prev < searchables.length - 1 ? prev + 1 : prev));
          break;
        case 'Enter':
          event.preventDefault();

          setInd(prev => {
            if (prev > -1) {
              searchReference('/bookingReference', searchables[prev].referenceID);
            } else if (input.current.value !== '') {
              search(
                '/search',
                'searchString',
                encodeURIComponent(
                  input.current.value
                    .toLowerCase()
                    .trim()
                    .replace(/\s+/g, ' ')
                )
              );
              setVisible(false);
            }
            return prev;
          });
          break;
        default:
          break;
      }
    },
    [search, searchReference, searchables, setVisible]
  );

  useEffect(() => {
    if (input.current) {
      input.current.addEventListener('keydown', handleUserKeyPress);
      return () => {
        input.current.removeEventListener('keydown', handleUserKeyPress);
      };
    }
    return () => {};
  }, [handleUserKeyPress]);

  const onChange = e => {
    setSearchString(
      e.target.value
        .toLowerCase()
        .trim()
        .replace(/\s+/g, ' ')
    );
    setInd(-1);
  };

  const getItems = () => {
    if (searchString === '') {
      return <></>;
    }
    if (searchables.length === 0) {
      return (
        <Item
          onClick={() => {
            search('/search', 'searchString', encodeURIComponent(searchString));
          }}
        >
          <Indicator className="icon-search" />
          <Label>Sök på Ibex</Label>
        </Item>
      );
    }
    return searchables.map((e, i) => (
      <Item
        key={e.referenceID}
        active={ind === i}
        onClick={() => {
          searchReference('/bookingReference', e.referenceID);
        }}
      >
        <Indicator className="icon-book" />
        <Label>
          <p>{cap(getBookingName(e.customer, e.customerType))}</p>
          <i>{e.referenceID.toUpperCase()}</i>
        </Label>
      </Item>
    ));
  };

  return (
    <Container ref={ref}>
      <Bar visible={visible}>
        <Icon
          visible={visible}
          className="icon-search"
          onClick={() => {
            if (input.current.value === '') {
              input.current.blur();
              history.push(`/search`);
            }
          }}
        />
        <Input
          ref={e => {
            input.current = e;
          }}
          type="text"
          placeholder="Sök bland bokningar..."
          onFocus={() => setVisible(true)}
          onChange={e => onChange(e)}
        />
      </Bar>
      {searchString.length !== 0 && visible && <Items reverse={false}>{visible && getItems()}</Items>}
    </Container>
  );
};

Input.displayName = 'Input';
Item.displayName = 'Item';

export default Search;
