import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts, Media } from '../../Resources/Stylesheets/Variables';
import { cap, groupBy, rgba } from '../../Library/Library';
import { Container as Template, ProductColumn } from '../../Styles/Styles';

const Container = styled(Template)`
  grid-column: span 6;
  grid-row: span ${props => props.length + 3};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr;
  padding: 20px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-column-start: 1;
    grid-column-end: end;
  }
`;

const Items = styled.div`
  display: flex;
  flex-flow: column;
`;

const Value = styled.div`
  height: 50px;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  cursor: pointer;
  grid-gap: 10px;
`;

const Icon = styled.i`
  color: ${props => props.color};
  font-size: 15px;
  background: ${props => rgba(0.3, props.theme.positive)};
  border-radius: 50%;
  border: 2px solid ${props => rgba(0.1, props.theme.positive)};
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms ease-in-out;
`;

const Name = styled.i`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: ${Fonts.weightBold};
`;

const List = ({ spec }) => {
  const distinct = Object.entries(groupBy(spec.data, 'country')).map(([key, value]) => ({
    key,
    value: value.reduce((s, e) => s + e.amount, 0)
  }));
  return (
    <Container length={distinct.length}>
      <h5>{spec.label}</h5>
      <Items length={distinct.length}>
        {distinct.map(e => (
          <Value key={e.key}>
            <Icon className="icon-fowlit" />
            <ProductColumn>
              <Name>{`${cap(e.key)} - ${e.value} gäster`}</Name>
            </ProductColumn>
          </Value>
        ))}
      </Items>
    </Container>
  );
};

List.propTypes = {
  spec: PropTypes.shape({
    label: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({ country: PropTypes.string, amount: PropTypes.number }))
  }).isRequired
};

export default List;
