import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from '../../Resources/Stylesheets/Variables';

/**
 * Styled initials circle with gradient background.
 * Place in box with fixed height and width.
 * Minimum one character and max two.
 * @param size size of circle.
 */
export const Initials = styled.h5`
  background: ${`linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});`};
  color: ${Colors.white};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.size ? props.size : '100%')};
  width: ${props => (props.size ? props.size : '100%')};
  border-radius: 50%;
  user-select: none;
  border: 2px solid ${props => props.theme.border};
`;

/**
 * Styled image circle.
 * Place in box with fixed height and width.
 * @param src path or url to image.
 * @param size size of circle.
 */
export const Image = styled.div`
  background: ${props => `url(${props.src})`} center;
  background-size: cover;
  height: ${props => (props.size ? props.size : '100%')};
  width: ${props => (props.size ? props.size : '100%')};
  border-radius: 50%;
  border: 2px solid ${props => props.theme.border};
  box-sizing: border-box;
`;

const Avatar = ({ size, image, initials }) => {
  return image ? <Image src={image} size={size} /> : <Initials size={size}>{initials.toUpperCase()}</Initials>;
};

Avatar.propTypes = {
  size: PropTypes.string.isRequired,
  image: PropTypes.string,
  initials: PropTypes.string.isRequired
};

Avatar.defaultProps = {
  image: null
};

export default Avatar;
