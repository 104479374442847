import { INIT_PRODUCTS, UPDATE_PRODUCT, ADD_PRODUCT, DEL_PRODUCT } from './actions';
import { alphaNumeric } from '../../Library/Library';
import { CAMPING, CONFERENCE, FIELD, ITEM, LOCKER } from '../../Library/Variables';

const init = {
  camping: [],
  fields: [],
  conferences: [],
  items: [],
  lockers: []
};

export const getName = type => {
  switch (type) {
    case CAMPING:
      return 'camping';
    case FIELD:
      return 'fields';
    case CONFERENCE:
      return 'conferences';
    case ITEM:
      return 'items';
    case LOCKER:
      return 'lockers';
    default:
      throw Error('Okänd product type');
  }
};

const products = (state = init, action) => {
  switch (action.type) {
    case INIT_PRODUCTS: {
      const h = action.payload;
      h.sort((e, e1) => alphaNumeric(e.name, e1.name));
      return {
        ...h.reduce((s, e) => {
          const name = getName(e.type);
          state[name] = [...state[name], e];
          return state;
        }, init)
      };
    }

    case UPDATE_PRODUCT: {
      const product = action.payload;
      const name = getName(product.type);
      state[name] = state[name].map(e => (e.id === product.id ? { ...e, ...product } : e));
      state[name].sort((e, e1) => alphaNumeric(e.name, e1.name));
      return { ...state };
    }

    case ADD_PRODUCT: {
      const product = action.payload;

      const name = getName(product.type);
      const dup = state[name].find(e => e.id === product.id);
      if (dup) {
        return state;
      }
      state[name] = [...state[name], product].sort((e, e1) => alphaNumeric(e.name, e1.name));
      return { ...state };
    }

    case DEL_PRODUCT: {
      const product = action.payload;
      Object.keys(state).forEach(name => {
        state[name] = state[name].filter(e => e.id !== product.id);
      });

      return { ...state };
    }

    default:
      return state;
  }
};

export default products;
