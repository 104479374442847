import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import useForm from '../../../Hooks/useForm';
import { BasketType } from '../../../Types/types';
import { editBasketProduct } from '../../../Library/api';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: 1fr;
  grid-gap: 3px;
  align-items: center;
  grid-auto-flow: column;

  input {
    transition: 300ms ease-out;
    width: ${props => `${props.length * 10 || 100}px`};
    max-width: 100px;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeTiny};
    background: none;
    color: ${props => props.theme.color};
    appearance: none;
    overflow-x: hidden;
    border: none;
  }
`;

const GroupComment = ({ data }) => {
  const { values, handleChange } = useForm({ comment: data[0].spec.comment || '' });
  const handleBlur = async () => {
    data.forEach(e => {
      const body = e;
      body.spec.comment = values.comment === '' ? null : values.comment;
      editBasketProduct(body);
    });
  };

  return (
    <Container length={values.comment ? values.comment.toString().length : 0}>
      <input
        type="text"
        name="comment"
        value={values.comment}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={20}
        placeholder="Skriv här..."
      />
    </Container>
  );
};

GroupComment.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(BasketType)).isRequired
};

export default GroupComment;
