export const INIT_COUPONS = 'INIT_COUPONS';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const ADD_COUPON = 'ADD_COUPON';
export const DEL_COUPON = 'DEL_COUPON';

export const initCoupons = payload => ({
  type: INIT_COUPONS,
  payload
});

export const updateCoupon = payload => ({
  type: UPDATE_COUPON,
  payload
});

export const addCoupon = payload => ({
  type: ADD_COUPON,
  payload
});

export const deleteCoupon = payload => ({
  type: DEL_COUPON,
  payload
});
