import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useAddBasketProduct, useDeleteBasketProduct } from '../../../Library/Hooks';
import { ProductType } from '../../../Types/types';
import { CardButton } from '../../../Components/Card/Card';

const withinRange = (st1, et1, st2, et2) => et2 > st1 && st2 < et1;

const Button = styled(CardButton)``;

const ActionButton = ({ data, startTime, endTime }) => {
  const id = useSelector(state =>
    state.basket.reduce((s, e) => {
      if (startTime || endTime) {
        if (e.productID === data.id && withinRange(e.startTime, e.endTime, startTime, endTime)) {
          return e.id;
        }
      } else if (e.productID === data.id) {
        return e.id;
      }
      return s;
    }, null)
  );
  const add = useAddBasketProduct();
  const del = useDeleteBasketProduct();

  const addItem = () =>
    add.request({
      name: data.name,
      productID: data.id,
      type: data.type,
      startTime,
      endTime
    });

  const deleteItem = () => del.request({ id });

  return id ? (
    <Button value={<i className="icon-minus" />} isNegative onClick={deleteItem} />
  ) : (
    <Button value={<i className="icon-plus" />} onClick={addItem} />
  );
};

ActionButton.propTypes = {
  data: PropTypes.shape(ProductType).isRequired,
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date)
};

ActionButton.defaultProps = {
  startTime: null,
  endTime: null
};

export default ActionButton;
