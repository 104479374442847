export const INIT_MARKS = 'INIT_MARKS';
export const UPDATE_MARK = 'UPDATE_MARK';
export const ADD_MARK = 'ADD_MARK';
export const DEL_MARK = 'DEL_MARK';

export const initMarks = payload => ({
  type: INIT_MARKS,
  payload
});

export const updateMark = payload => ({
  type: UPDATE_MARK,
  payload
});

export const addMark = payload => ({
  type: ADD_MARK,
  payload
});

export const deleteMark = payload => ({
  type: DEL_MARK,
  payload
});
