import React from 'react';
import styled from 'styled-components';
import { Fonts, Media } from '../../../Resources/Stylesheets/Variables';
import { Button } from '../../../Styles/Styles';
import { encodeURL, getRandomString } from '../../../Library/Library';
import { BASE_HTTP_URL } from '../../../Library/Fetch';

const Container = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: 120px 1fr max-content 1fr 120px;
  grid-template-rows: 1fr;
  align-items: center;
  grid-template-areas: 'name . icon . login';
  padding: 0 30px;
  background: ${props => props.theme.background};
  border-bottom: 2px solid ${props => props.theme.border};
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    padding: 0 15px;
  }
`;

const Name = styled.a`
  grid-area: name;
  color: #29729d;
`;

const Icon = styled.div`
  grid-area: icon;
  font-size: ${Fonts.sizeLarge};
  color: ${props => props.theme.accent};
`;

const Login = styled(Button)`
  grid-area: login;
  background: transparent;
  color: ${props => props.theme.accent};
  font-size: ${Fonts.sizeTiny};
  min-width: auto;
  padding: 0;
  justify-content: flex-end;
`;

const Header = () => {
  const onClickHandler = () => {
    const data = {
      state: getRandomString(30),
      nonce: getRandomString(30),
      redirect_uri: `${window.location.origin}/callback`
    };
    window.localStorage.setItem('state', data.state);
    window.localStorage.setItem('nonce', data.nonce);
    window.location = encodeURL(`${BASE_HTTP_URL}/oauth2/code`, data);
  };

  return (
    <Container>
      <Name href="http://campigge.se">Camp Igge</Name>
      <Icon className="icon-fowlit" />
      <Login value="Logga in" onClick={onClickHandler} />
    </Container>
  );
};

export default Header;
