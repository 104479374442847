import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Input from '../../../Components/Forms/Input';
import { CAMPING, CONFERENCE, FIELD, ITEM, LOCKER } from '../../../Library/Variables';
import useForm from '../../../Hooks/useForm';
import Price from '../../../Components/Forms/Price';
import { useAddProduct, useEditProduct } from '../../../Library/Hooks';
import { cap, formatPrice, preparePriceForRequest } from '../../../Library/Library';

const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px 250px;
  grid-gap: 10px;
`;

const EditProduct = ({ formID, formData, formatInput, formatSubmit, close, type }) => {
  const settings = useSelector(state => state.settings);
  const { vat } = settings[type.toLowerCase()];
  const v = formData && {
    ...formData,
    ...formData.spec,
    name: cap(formData.name),
    price: formatPrice(formData.price, vat),
    match: formData.spec && formData.spec.match && formatPrice(formData.spec.match, vat),
    matchDay: formData.spec && formData.spec.matchDay && formatPrice(formData.spec.matchDay, vat),
    fullField: formData.spec && formData.spec.fullField && formatPrice(formData.spec.fullField, vat),
    fullFieldDay: formData.spec && formData.spec.fullFieldDay && formatPrice(formData.spec.fullFieldDay, vat),
    halfField: formData.spec && formData.spec.halfField && formatPrice(formData.spec.halfField, vat),
    halfFieldDay: formData.spec && formData.spec.halfFieldDay && formatPrice(formData.spec.halfFieldDay, vat),
    twoThirdsField: formData.spec && formData.spec.twoThirdsField && formatPrice(formData.spec.twoThirdsField, vat),
    twoThirdsFieldDay:
      formData.spec && formData.spec.twoThirdsFieldDay && formatPrice(formData.spec.twoThirdsFieldDay, vat),
    oneThirdField: formData.spec && formData.spec.oneThirdField && formatPrice(formData.spec.oneThirdField, vat),
    oneThirdFieldDay:
      formData.spec && formData.spec.oneThirdFieldDay && formatPrice(formData.spec.oneThirdFieldDay, vat),
    hour: formData.spec && formData.spec.hour && formatPrice(formData.spec.hour, vat),
    day: formData.spec && formData.spec.day && formatPrice(formData.spec.day, vat)
  };

  const { values, handleChange } = useForm(v);
  const input = formatInput(values, handleChange, vat);
  const edit = useEditProduct();
  const add = useAddProduct();
  const { request, loading, error } = formData.id ? edit : add;
  const showPrice = type === CAMPING || type === ITEM;

  useEffect(() => {
    if (!loading && !error) close();
  }, [close, error, loading]);

  const handleSubmit = async e => {
    e.preventDefault();
    request({
      id: formData && formData.id,
      image: formData && formData.image,
      name: values.name,
      price: preparePriceForRequest(values.price, vat),
      type,
      active: formData ? formData.active : true,
      description: values.description,
      spec: formatSubmit(values, vat)
    });
  };

  return (
    <Form id={formID} onSubmit={handleSubmit}>
      <Input
        label="Namn"
        type="text"
        name="name"
        value={values.name}
        onChange={handleChange}
        required
        minLength={1}
        maxLength={50}
      />
      {showPrice && (
        <Price
          label="Pris"
          type="text"
          name="price"
          value={values.price}
          onChange={handleChange}
          required
          minLength={1}
          maxLength={50}
          vat={vat}
        />
      )}
      {type === ITEM && (
        <Input
          name="description"
          value={values.description}
          onChange={handleChange}
          label="Beskrivning"
          type="text"
          minLength={1}
          maxLength={255}
          required
        />
      )}

      {input}
    </Form>
  );
};

EditProduct.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.shape({})])
  ),
  close: PropTypes.func.isRequired,
  formatInput: PropTypes.func.isRequired,
  formatSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOf([CAMPING, FIELD, LOCKER, ITEM, CONFERENCE]).isRequired
};

EditProduct.defaultProps = {
  formData: null
};

export default EditProduct;
