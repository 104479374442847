import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Loader from './Components/Loader/Loader';
import Landing from './Containers/Landing/Landing';
import { BASE_HTTP_URL } from './Library/Fetch';

const CallbackHandler = () => {
  const params = new URLSearchParams(window.location.search);
  const { theme } = useSelector(state => state.session);

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const resp = await fetch(`${BASE_HTTP_URL}/oauth2/code?redirect_uri=${window.location.origin}/callback`, {
        method: 'POST',
        body: JSON.stringify({
          code: new URLSearchParams(window.location.search).get('code')
        })
      });
      if (!resp.ok) {
        setErr(`Request failed with status ${resp.status}`);
      } else {
        const b = await resp.json();
        setData(b);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader background={theme.body} color={theme.color} active />;
  }
  if (err) {
    return <div>{err}</div>;
  }

  if (
    data.nonce === window.localStorage.getItem('nonce') &&
    params.get('state') === window.localStorage.getItem('state')
  ) {
    window.localStorage.setItem('sessionToken', data.sessionToken);
    window.localStorage.removeItem('state');
    window.localStorage.removeItem('nonce');
    window.location = '/';
    return <></>;
  }
  return <div>Login could not be verified</div>;
};

const UnauthRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/callback" component={CallbackHandler} />
        <Route exact path="/" component={Landing} />
        {/* This is very bad fix m8 */}
        <Route exact path="/fields" component={Landing} />
        <Route exact path="/fields/:id" component={Landing} />
        <Route exact path="/lockers/:id" component={Landing} />
        <Route exact path="/conferences" component={Landing} />
        <Route exact path="/conferences/:id" component={Landing} />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default UnauthRouter;
