import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { cap } from '../../../../Library/Library';
import Input from '../../../../Components/Forms/Input';
import { Media } from '../../../../Resources/Stylesheets/Variables';
import useForm from '../../../../Hooks/useForm';
import { useUpdateProfile } from '../../../../Library/Hooks';

const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px 250px;
  grid-gap: 10px;
  padding: 15px 0;

  @media (max-width: ${Media.phone}) {
    grid-template-columns: 250px;
    grid-template-rows: 50px 50px;
    padding: 30px;
  }
`;

const UserForm = ({ formID, formData, close }) => {
  const { values, handleChange } = useForm({
    firstName: cap(formData.firstName),
    lastName: cap(formData.lastName),
    image: formData.image
  });
  const ref = useRef('');
  const { request, loading, error } = useUpdateProfile();

  useEffect(() => {
    ref.current.focus();
  }, []);

  useEffect(() => {
    if (!loading && !error) close();
  }, [close, error, loading]);

  const setUserInfo = async e => {
    e.preventDefault();
    request(values);
  };

  return (
    <Form id={formID} onSubmit={setUserInfo}>
      <Input
        ref={ref}
        label="Förnamn"
        type="text"
        name="firstName"
        value={values.firstName}
        onChange={handleChange}
        required
        minLength={1}
        maxLength={50}
      />
      <Input
        label="Efternamn"
        type="text"
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        required
        minLength={1}
        maxLength={50}
      />
    </Form>
  );
};

UserForm.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.instanceOf(Date)])
  ).isRequired,
  close: PropTypes.func.isRequired
};

export default UserForm;
