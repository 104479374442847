import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { Main as TempMain } from '../../Styles/Styles';
import Booking from './Components/Booking';
import Loader from '../../Components/Loader/Loader';
import PageNumberer from './Components/PageNumberer';
import Header from '../../Components/Header/Header';
import { getBookingReferences } from '../../Library/api';
import { addErr } from '../../Reducers/Error/actions';

const Main = styled(TempMain)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

const Container = styled.div`
  display: grid;
  grid-auto-rows: 70px;
  grid-gap: 10px;
`;

const Bookings = ({ location }) => {
  const searchParams = qs.parse(location.search);
  const page = parseInt(searchParams.page, 10) || 1;
  const size = parseInt(searchParams.size, 10) || 10;
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const resp = await getBookingReferences({ page, size });
      if (resp.error) dispatch(addErr('Kunde inte hämta bokningarna'));
      setData(resp.data);
    })();
  }, [dispatch, page, size]);

  if (!data) return <Loader active />;

  const { bookingReferences } = data;

  return (
    <Main>
      <Header title="Bokningar" />
      <Container>
        {bookingReferences.length === 0 ? (
          <p>Inga bokningar finns.</p>
        ) : (
          bookingReferences.map(b => <Booking key={b.referenceID} data={b} />)
        )}
      </Container>
      <PageNumberer current={page} size={Math.ceil(data.total / size)} params={searchParams} url="/bookings" />
    </Main>
  );
};

Bookings.propTypes = { location: PropTypes.shape({ search: PropTypes.string }).isRequired };

export default Bookings;
