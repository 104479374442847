import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dimensions, Media } from '../../../Resources/Stylesheets/Variables';
import { addErr } from '../../../Reducers/Error/actions';
import { fetchJSON } from '../../../Library/Fetch';
import { cap, formatPrice, ISODateString } from '../../../Library/Library';
import Search from '../../../Components/Search/Search';
import Header from '../../../Components/Header/Header';
import Card from '../../../Components/Card/Card';
import Loader from '../../../Components/Loader/Loader';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-gap: 30px;
  padding: 30px;
  box-sizing: border-box;
  grid-template-areas:
    'search'
    'content';
  @media (max-width: ${Media.phone}) {
    padding: 15px;
  }
`;

const Content = styled.div`
  grid-area: content;
  display: grid;
  grid-gap: 30px;
`;

const Result = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${Dimensions.navigationHeight} 1fr;
  grid-gap: 10px;
  grid-template-areas:
    'title'
    '.';
`;

const Box = styled.div`
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-template-rows: repeat(auto-fill, 350px);
  grid-auto-columns: 225px;
  grid-auto-rows: 325px;
  grid-gap: 30px;
  @media (max-width: ${Media.phone}) {
    grid-auto-flow: column;
    grid-auto-columns: 250px;
    grid-auto-rows: max-content;
    justify-content: center;
    overflow-x: auto;
  }
`;

const Title = styled.h3`
  grid-area: title;
  display: flex;
  align-items: center;
`;

const Camping = () => {
  const [respData, setRespData] = useState(null);
  const [dates, setDates] = useState([null, null]);
  const [guests, setGuests] = useState(0);
  const [type, setType] = useState({ type: '', label: '' });
  const dispatch = useDispatch();
  const camping = useSelector(state => state.products.camping);
  const vat = useSelector(state => state.settings.camping && state.settings.camping.vat);

  const onSubmit = async e => {
    e.preventDefault();

    if (!type.type) {
      dispatch(addErr('Välj typ av bostad.'));
      return;
    }
    if (dates.length < 2) {
      dispatch(addErr('Välj ett datumintervall.'));
      return;
    }
    if (guests === 0) {
      dispatch(addErr('Ange antal gäster.'));
      return;
    }

    const { error, data } = await fetchJSON(
      `/camping/available?guests=${guests}&type=${type.type}&startTime=${ISODateString(
        dates[0]
      )}&endTime=${ISODateString(dates[1])}`
    );

    if (error) {
      dispatch(addErr('Kunde inte genomföra sökningen.'));
    } else {
      setRespData(data);
    }
  };

  const getHousingByID = id => {
    const house = camping.find(e => e.id === id);
    const { spec } = house;
    return (
      <Card
        key={house.id}
        image={house.image}
        name={cap(house.name)}
        rows={[
          { icon: 'icon-home', label: 'Storlek', value: `${spec.size ? spec.size : 0} m\u00b2` },
          { icon: 'icon-bed', label: 'Bäddar', value: `${spec.beds ? spec.beds : 0} st` },
          { icon: 'icon-tags', label: 'Pris', value: `${formatPrice(house.price, vat)} kr` }
        ]}
        details={[
          spec.tv ? 'icon-tv' : null,
          spec.wifi ? 'icon-wifi' : null,
          spec.kitchen ? 'icon-kitchen' : null,
          spec.electricity ? 'icon-electricity' : null
        ]}
      />
    );
  };

  const rec = respData ? respData.recommended : null;
  const all = respData ? respData.housing : null;

  const empty = respData && rec.length === 0 && all.length === 0;

  if (!vat) return <Loader />;

  return (
    <Container>
      <Search
        onSubmit={onSubmit}
        setDates={setDates}
        dates={dates}
        setGuests={setGuests}
        guests={guests}
        type={type}
        setType={setType}
      />
      <Content>
        {!respData && <Result />}
        {empty && (
          <Result>
            <Title>Kunde inte hitta någon bostad.</Title>
            <p>Testa ändra typ, datum eller antalet gäster och sök igen.</p>
          </Result>
        )}
        {rec && rec.length > 0 && (
          <Result>
            <Header title="Rekommenderad bokning" />
            <Box>{rec.map(getHousingByID)}</Box>
          </Result>
        )}

        {all && all.length > 0 && (
          <Result>
            <Header title={`Tillgängliga bostäder (${all.length})`} />
            <Box>{all.map(getHousingByID)}</Box>
          </Result>
        )}
      </Content>
    </Container>
  );
};

export default Camping;
