import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { addErr } from '../../../../Reducers/Error/actions';
import Input from '../../../../Components/Forms/Input';
import { Regexp } from '../../../../Resources/Stylesheets/Variables';
import useForm from '../../../../Hooks/useForm';
import { useChangePassword } from '../../../../Library/Hooks';

const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px;
  grid-template-rows: 50px 50px 50px;
  grid-gap: 10px;
  padding: 15px 0;
`;

const PasswordForm = ({ formID, close }) => {
  const { values, handleChange } = useForm({ currentPassword: '', newPassword: '', confirmPassword: '' });
  const dispatch = useDispatch();
  const ref = useRef('');
  const { request, loading, error } = useChangePassword();

  useEffect(() => {
    ref.current.focus();
  }, []);
  useEffect(() => {
    if (!loading && !error) close();
  }, [close, error, loading]);

  const setUserInfo = async e => {
    e.preventDefault();

    if (values.newPassword === values.confirmPassword) {
      request({ password: values.password, newPassword: values.newPassword });
    } else {
      dispatch(addErr('Lösenorden stämmer inte överens.'));
    }
  };

  return (
    <Form id={formID} onSubmit={setUserInfo}>
      <Input
        ref={ref}
        type="password"
        label="Nuvarande lösenord"
        name="password"
        value={values.password}
        onChange={handleChange}
        required
        pattern={Regexp.password}
        minLength={8}
        maxLength={256}
      />
      <Input
        type="password"
        label="Nytt lösenord"
        name="newPassword"
        value={values.newPassword}
        onChange={handleChange}
        required
        pattern={Regexp.password}
        minLength={8}
        maxLength={256}
      />
      <Input
        type="password"
        label="Bekräfta nytt lösenord"
        name="confirmPassword"
        value={values.confirmPassword}
        onChange={handleChange}
        required
        pattern={Regexp.password}
        minLength={8}
        maxLength={256}
      />
    </Form>
  );
};

PasswordForm.propTypes = {
  formID: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default PasswordForm;
