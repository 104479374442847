import { getWeek } from 'date-fns';
import {
  COMPANY,
  FIELD_FULL,
  FIELD_HALF,
  FIELD_MATCH,
  FIELD_ONE_THIRD,
  FIELD_SIZE,
  FIELD_TWO_THIRD,
  ONEDAY,
  ONEHOUR,
  PERSON
} from './Variables';

export const rgba = (o, h) =>
  `rgba(${parseInt(h.slice(1, 3), 16)},${parseInt(h.slice(3, 5), 16)},${parseInt(h.slice(5, 7), 16)},${o})`;

export const logout = () => {
  (async () => {
    await fetch('/session/logout', {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
      }
    });
    localStorage.removeItem('sessionToken');
    window.location.reload();
  })();
};

const isArray = value => {
  return value && typeof value === 'object' && value.constructor === Array;
};

export const encodeURL = (url, params) => {
  const createdParams = Object.keys(params)
    .map(key => {
      const param = params[key];

      return `${key}=${isArray(param) ? param.join(' ') : encodeURIComponent(param)}`;
    })
    .join('&');

  return `${url}?${createdParams}`;
};

export const getRandomString = len => {
  let res = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = chars.length;

  for (let i = 0; i < len; i += 1) {
    res += chars.charAt(Math.floor(Math.random() * charactersLength));
  }
  return res;
};

export const setImage = e => {
  if (e.image && e.image.substring(0, 5) !== 'data:') {
    e.image = e.image.length === 0 ? '' : `data:image;base64,${e.image}`;
  }
  return e;
};

/**
 * Makes the first letter in every word to uppercase and the rest to lowercase.
 * @param {string} s
 */
export const cap = s => {
  if (!s) return '';
  const temp = s
    .split(' ')
    .map(e => {
      if (e.length === 2 && e === 'ab') {
        return `${e.toUpperCase()} `;
      }
      return `${e.charAt(0).toUpperCase()}${e.slice(1).toLowerCase()} `;
    })
    .reduce((e1, e2) => e1 + e2);

  return temp.trim();
};

export const formatDate = d => {
  return new Date(d).toLocaleDateString('sv-SV', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
};

const padDate = n => (n < 10 ? `0${n}` : n);

export const ISODateString = d =>
  `${d.getUTCFullYear()}-${padDate(d.getUTCMonth() + 1)}-${padDate(d.getUTCDate())}T${padDate(
    d.getUTCHours()
  )}:${padDate(d.getUTCMinutes())}:${padDate(d.getUTCSeconds())}Z`;

export const arrayBufferToBase64 = buffer => {
  let binary = '';
  const bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach(b => {
    binary += String.fromCharCode(b);
  });

  return window.btoa(binary);
};

export const alphaNumeric = (x, y) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base'
  });
  return collator.compare(x, y);
};

export const calculateTimespanPriceDays = e => e.price * Math.ceil((e.endTime - e.startTime) / ONEDAY) - e.discount;

export const calculateTimespanPriceHours = e => e.price * Math.ceil((e.endTime - e.startTime) / ONEHOUR) - e.discount;

export const getWeekNumber = date => getWeek(date, { weekStartsOn: 1, firstWeekContainsDate: 4 });

export const isSameDate = (d1, d2) =>
  new Date(d1).getDate() === new Date(d2).getDate() &&
  new Date(d1).getMonth() === new Date(d2).getMonth() &&
  new Date(d1).getFullYear() === new Date(d2).getFullYear();

export const calculateHours = (start, end) => {
  const hours = ((new Date(end).getTime() - new Date(start).getTime()) / ONEHOUR).toFixed(2);
  if (Math.ceil(hours) === 24) return '24.00';
  return hours;
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const groupBy2 = (xs, key1, key2) => {
  return xs.reduce((rv, x) => {
    (rv[x[key1][key2]] = rv[x[key1][key2]] || []).push(x);
    return rv;
  }, {});
};

export const groupByLC = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key].toLowerCase()] = rv[x[key].toLowerCase()] || []).push(x);
    return rv;
  }, {});
};

export const intDiv = (n, d) => Math.floor((n + Math.floor(d / 2)) / d);
export const subVat = (n, vat) => intDiv(100 * n, (vat || 0) + 100);
export const addVat = (n, vat) => intDiv((vat + 100) * n, 100);

export const formatPrice = (n, vat) => {
  const p = ((vat ? addVat(n, vat) : n) / 10000).toFixed(2).toLocaleString();
  if (p === 'NaN') return null;
  return p;
};

export const calcVat = (price, vat) => intDiv(vat * price, 100);

export const preparePriceForRequest = (price, vat) => subVat(10000 * price, vat);

export const calcTotPrice = ({ price, discount, addition, quantity, vat }) => {
  if (vat) {
    return addVat(price, vat) * (quantity || 0) + addVat(addition || 0, vat) - addVat(discount || 0, vat);
  }
  return price * (quantity || 0) + (addition || 0) - (discount || 0);
};

export const getFieldPrice = (spec, version) => {
  switch (version) {
    case FIELD_MATCH.type:
      return spec.match;
    case FIELD_FULL.type:
      return spec.fullField;
    case FIELD_HALF.type:
      return spec.halfField;
    case FIELD_TWO_THIRD.type:
      return spec.twoThirdsField;
    case FIELD_ONE_THIRD.type:
      return spec.oneThirdField;
    default:
      return 0;
  }
};

export const formatTimeInterval = (start, end) => {
  if (Math.ceil(calculateHours(start, end)) === 24) return 'Heldag';
  return `${new Date(start).toLocaleTimeString('sv-SV', {
    hour: '2-digit',
    minute: '2-digit'
  })} - ${new Date(end).toLocaleTimeString('sv-SV', {
    hour: '2-digit',
    minute: '2-digit'
  })}`;
};

const convertToCSV = objArray => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const index in array[i]) {
      if (line !== '') line += ',';

      line += array[i][index];
    }

    str += `${line}\r\n`;
  }

  return str;
};

export const exportCSVFile = (headers, items, fileTitle) => {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const exportedFilenmae = `${fileTitle}.csv` || 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const encodeImage = image => (image ? image.substring(image.indexOf('base64,') + 7) : null);

// exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download

export const serializePrice = p => (p ? Math.ceil(parseFloat(p).toFixed(2)) : null);

export const addIfDefined = (cond, arg) => (cond !== undefined ? [arg] : []);

export const getFieldLabel = size => {
  const field = FIELD_SIZE.find(e => e.type === size);
  return field ? field.label : '';
};

export const addHours = (date, hour) => {
  const h = new Date(date).getHours();
  return new Date(date).setHours(h + hour, 0, 0, 0);
};

export const timeBetween = (date, start, end) => {
  const d1 = new Date(date).setHours(parseInt(start.substring(0, 2), 10), parseInt(start.substring(3, 5), 10), 0, 0);
  const d2 = new Date(date).setHours(parseInt(end.substring(0, 2), 10), parseInt(end.substring(3, 5), 10), 0, 0);
  return ((d2 - d1) / ONEHOUR).toFixed(2);
};

export const parseTime = (date, time) => {
  const d = new Date(date);
  const times = time
    .split(':')
    .filter(e => e.length < 3)
    .map(e => parseInt(e, 10));

  d.setHours(times[0], times[1]);

  return times.length === 2 ? d : null;
};

export const isValidTime = time =>
  time.length === 5 &&
  time
    .split(':')
    .filter(e => e.length < 3)
    .map(e => parseInt(e, 10)).length === 2;

export const calcBookingPrice = data => data.bookedProducts.reduce((s, e) => s + calcTotPrice(e), 0);

export const getBookingName = (customer, type) => {
  switch (type) {
    case PERSON:
      return `${customer.firstName} ${customer.lastName}`;
    case COMPANY:
      return customer.name;
    default:
      throw Error(`Invalid customerType: ${type}`);
  }
};

export const calculateNights = (start, end) =>
  Math.round((new Date(end).getTime() - new Date(start).getTime()) / ONEDAY);

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getDateInterval = (start, end) =>
  [...Array(calculateNights(start, end) + 1).keys()].map(i => addDays(start, i));

export const getWeekDates = date => {
  const d = new Date(date);
  const day = d.getDay();
  const first = d.getDate() - day + (day === 0 ? -6 : 1);
  d.setDate(first);
  return [...Array(7).keys()].map(e => addDays(d, e));
};
