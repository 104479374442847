import { INIT_MARKS, UPDATE_MARK, ADD_MARK, DEL_MARK } from './actions';

const marks = (state = [], action) => {
  switch (action.type) {
    case INIT_MARKS: {
      return [...action.payload];
    }

    case UPDATE_MARK: {
      const mark = action.payload;
      return state.map(e => (e.id === mark.id ? mark : e));
    }

    case ADD_MARK: {
      const mark = action.payload;
      if (state.find(({ id }) => id === mark.id)) return state;

      return [...state, mark];
    }

    case DEL_MARK: {
      const mark = action.payload;
      return state.filter(({ id }) => id !== mark.id);
    }

    default:
      return state;
  }
};

export default marks;
