import React, { useReducer } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Main, Button } from '../../Styles/Styles';
import Header from '../../Components/Header/Header';
import Mark from './Components/Mark';
import CreateMark from './Components/CreateMark';
import PopOver from '../../Components/PopOver/PopOver';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 30px;
`;

const Items = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

const CREATE = 'CREATE';
const SEARCH = 'SEARCH';

const reducer = (state, action) => {
  switch (action.type) {
    case CREATE:
      return { ...state, creating: !state.creating };
    case SEARCH:
      return { ...state, search: action.payload };
    default:
      throw new Error('Invalid reducer state');
  }
};

const Marks = () => {
  const [{ creating, search }, dispatch] = useReducer(reducer, {
    creating: false,
    search: ''
  });
  const showCreate = () => dispatch({ type: CREATE });
  const setSearch = payload => dispatch({ type: SEARCH, payload });
  const marks = useSelector(state => state.marks);

  return (
    <Main>
      <Container>
        <Header
          title="Märkning"
          search={setSearch}
          extra={[<Button key="create" value="Skapa märkning" onClick={showCreate} />]}
        />
        <Items>
          {marks
            .filter(e => e.name.toLowerCase().includes(search.trim().toLowerCase()))
            .map(e => (
              <Mark key={e.id} data={e} />
            ))}
        </Items>
      </Container>
      {creating && (
        <PopOver
          data={{
            title: 'Skapar märkning',
            submitLabel: 'Spara'
          }}
          Content={CreateMark}
          close={showCreate}
        />
      )}
    </Main>
  );
};

export default Marks;
