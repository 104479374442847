import { SET_PROFILE } from './actions';

const initialState = {};

function profile(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export default profile;
