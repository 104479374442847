import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Cancel, ProductColumn, ProductLabel, ProductValue } from '../../Styles/Styles';
import { calcTotPrice, cap, formatDate, formatPrice, groupBy, intDiv } from '../../Library/Library';
import { addErr } from '../../Reducers/Error/actions';
import {
  BOOKING_TYPE,
  CAMPING,
  CONFERENCE,
  FIELD,
  FIELD_FULL_DAY,
  ITEM,
  LOCKER,
  PRODUCTS
} from '../../Library/Variables';
import Option from '../Forms/Option';
import useForm from '../../Hooks/useForm';
import Select from '../Forms/Select';
import { ZIndex } from '../../Resources/Stylesheets/Variables';
import { useCreateInvoice } from '../../Library/Hooks';
import { useAfterSuccess } from '../../Hooks';
import Checkbox from '../Forms/Checkbox';

const Container = styled.form`
  display: grid;
  grid-template-columns: minmax(250px, 300px) minmax(250px, 300px);
  grid-template-rows: max-content max-content max-content 1fr max-content max-content max-content;
  grid-gap: 30px;
  grid-template-areas: 'header header' 'filter filter' 'colHead colHead' 'scroll scroll' 'markHead markHead' 'mark mark' 'tools tools';
  height: 80vh;
  padding: 30px 50px;
`;

const Header = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

const Filters = styled.div`
  grid-area: filter;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  align-items: center;
  grid-gap: 30px;
`;

const ColumnHeaders = styled.div`
  grid-area: colHead;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  align-items: center;
  grid-gap: 30px;
`;

const ScrollColumn = styled.div`
  grid-area: scroll;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  align-items: center;
  grid-gap: 30px;
  overflow-y: auto;
`;

const MarkHeaders = styled.div`
  grid-area: markHead;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  align-items: center;
`;

const Mark = styled.div`
  grid-area: mark;
  display: grid;
  grid-auto-columns: max-content;
  grid-template-rows: max-content;
  align-items: center;
  grid-gap: 30px;
  grid-auto-flow: column;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

const Tools = styled.div`
  grid-area: tools;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 15px;
`;

const getBLID = (products, bookedProduct) => {
  let product;
  switch (bookedProduct.type) {
    case CAMPING:
      product = products.camping.find(e => e.id === bookedProduct.productID);
      break;
    case FIELD:
      product = products.fields.find(e => e.id === bookedProduct.productID);
      break;
    case LOCKER:
      product = products.lockers.find(e => e.id === bookedProduct.productID);
      break;
    case CONFERENCE:
      product = products.conferences.find(e => e.id === bookedProduct.productID);
      break;
    case ITEM:
      product = products.items.find(e => e.id === bookedProduct.productID);
      break;
    default:
      return null;
  }
  return { ...bookedProduct, blID: product === undefined ? null : product.blID };
};

const getVatCode = vat => {
  switch (vat) {
    case 25:
      return '1';
    case 12:
      return '2';
    case 6:
      return '3';
    default:
      throw Error('Invalid vat code');
  }
};

const createLines = (products, discount, percent, customer) =>
  products.map(x => {
    return {
      referenceID: x.referenceID,
      articleId: x.blID,
      description: `${customer} - ${cap(x.name)} - ${
        x.spec && x.type === FIELD
          ? `${BOOKING_TYPE.find(y => y.type === x.spec.version).label} ${
              x.spec.comment !== null ? `- ${x.spec.comment}` : ''
            }`
          : ''
      }`,
      deliveredQuantity: x.quantity,
      unitPrice: formatPrice(percent * intDiv(calcTotPrice({ ...x, vat: null }) / x.quantity, 100)),
      discountPercentage: discount || x.discountPercentage,
      unit: x.type === CAMPING || x.type === ITEM ? 'st' : `${x.spec.version === FIELD_FULL_DAY.type ? 'dag' : 'tim'}`,
      comment: x.spec && (x.type === FIELD || x.type === LOCKER) && x.spec.comment !== null ? x.spec.comment : '',
      vatCode: getVatCode(x.vat)
    };
  });

const createOrder = (id, products, discount, percent, customer) => ({
  customerId: id,
  lines: createLines(products, discount, percent, customer)
});

const InvoiceExport = ({ data, close }) => {
  const marks = useSelector(state => state.marks);
  const { values, handleChange } = useForm({ type: PRODUCTS, markID: data.markID, createOneInvoice: true });
  const mark = marks.find(e => e.id.toString() === values.markID);
  const MARK_OPTIONS = marks.map(e => ({ type: e.id.toString(), label: e.name }));
  const products = useSelector(state => state.products);
  const types = values.type && values.type.map(({ type }) => type);
  const filteredProducts = data.bookedProducts
    .map(e => e && getBLID(products, e))
    .filter(e => e && e.blID && types.includes(e.type));
  const groupedProducts = Object.entries(groupBy(filteredProducts, 'productID')).map(([, value]) => value[0]);
  const dispatch = useDispatch();

  const { request } = useAfterSuccess(useCreateInvoice(), close);

  const handleSubmit = async e => {
    e.preventDefault();
    if (filteredProducts.length === 0) {
      dispatch(addErr('Det finns inga produkter att exportera.'));
      return;
    }

    if (values.markID) {
      const notMarkedProducts = filteredProducts.filter(x => !mark.type.includes(x.type));
      const markedProducts = filteredProducts.filter(x => mark.type.includes(x.type));
      const customerPaysNotMarked =
        notMarkedProducts.length > 0 && createLines(notMarkedProducts, data.discount, data.customer.name);
      const customerPaysMarked =
        markedProducts.length > 0 &&
        createOrder(
          data.customerID,
          markedProducts,
          data.discount,
          mark && mark.value && mark.value.percent,
          data.customer.name
        );
      const splitCustomersPaysMarked =
        markedProducts.length > 0 &&
        mark.value.split.map(x =>
          createOrder(x.customerID, markedProducts, data.discount, x.percent, data.customer.name)
        );

      const body = { orders: [] };

      if (customerPaysNotMarked) customerPaysMarked.lines = [...customerPaysMarked.lines, ...customerPaysNotMarked];
      if (customerPaysMarked) body.orders.push(customerPaysMarked);
      if (splitCustomersPaysMarked) body.orders.push(...splitCustomersPaysMarked);

      if (values.createOneInvoice) {
        request({ orders: [body.orders[0]] });
        return;
      }
      request(body);
      return;
    }
    const order = createOrder(data.customerID, filteredProducts, data.discount, 100, data.customerName);
    request({ orders: [order] });
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Header>
        <h4>Skapa och exportera fakturaunderlag</h4>
        <p>Endast Ibex Produkter med en kopplad Björn Lundén Artikel kommer att exporteras.</p>
      </Header>
      <Filters>
        <h4>Inställningar</h4>
        <div style={{ zIndex: ZIndex.dropdown + 1 }}>
          <Option value={values.type} onChange={handleChange} name="type" label="Produkter" options={PRODUCTS} />
        </div>
        {data.markID && (
          <Select value={values.markID} onChange={handleChange} name="markID" label="Märkning" options={MARK_OPTIONS} />
        )}
        {values.markID && (
          <Checkbox
            name="createOneInvoice"
            value={values.createOneInvoice}
            label={`Skapa bara faktura för Kund ID ${data.customerID}?`}
            onChange={handleChange}
          />
        )}
      </Filters>
      <ColumnHeaders>
        <h4>Ibex Produkt</h4>
        <h4>Björn Lundén Artikel</h4>
      </ColumnHeaders>
      <ScrollColumn>
        {groupedProducts.length === 0 && (
          <Row>
            <h5>Det finns inga artiklar att exportera.</h5>
          </Row>
        )}
        {groupedProducts.map(e => (
          <Row key={e.id}>
            <h5>{cap(e.name)}</h5>
            <h5>{e.blID}</h5>
          </Row>
        ))}
      </ScrollColumn>
      {mark && (
        <>
          <MarkHeaders>
            <h4>Märkning</h4>
            <p>{`De produkttyper som ingår i märkningen ${mark.name} kommer fördelas på följande Kund ID.`}</p>
          </MarkHeaders>
          <Mark>
            <ProductColumn>
              <ProductLabel>Kund</ProductLabel>
              <ProductValue>{`${mark.value.percent} %`}</ProductValue>
            </ProductColumn>
            {mark.value.split.map(e => (
              <ProductColumn key={e.customerID}>
                <ProductLabel>{e.customerID.toUpperCase()}</ProductLabel>
                <ProductValue>{`${e.percent} %`}</ProductValue>
              </ProductColumn>
            ))}
          </Mark>
        </>
      )}
      <Tools>
        {data.exported && <p>{`Exporterad den: ${formatDate(data.exported)}`}</p>}
        <Cancel type="button" value="Avbryt" onClick={close} />
        <Button type="submit" value="Exportera" />
      </Tools>
    </Container>
  );
};

InvoiceExport.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
    .isRequired,
  close: PropTypes.func.isRequired
};

export default InvoiceExport;
