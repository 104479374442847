import { combineReducers } from 'redux';
import errors from './Error';
import profile from './Profile';
import session from './Session';
import basket from './Basket';
import bookings from './Bookings';
import users from './Users';
import settings from './Settings';
import products from './Products';
import coupons from './Coupons';
import marks from './Marks';

export default combineReducers({
  settings,
  errors,
  users,
  products,
  bookings,
  profile,
  session,
  basket,
  coupons,
  marks
});
