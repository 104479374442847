export const ADMIN = { type: 'ADMIN', label: 'Administratör' };

export const WORKER = { type: 'WORKER', label: 'Arbetare' };

export const USER_TYPE = [ADMIN, WORKER];

export const DAILY = { type: 'DAY', label: 'Varje dag' };
export const WEEKLY = { type: 'WEEK', label: 'Varje vecka' };

export const CALENDAR_REPEAT = [DAILY, WEEKLY];

export const CABIN = 'CABIN';
export const ROOM = 'ROOM';
export const HOSTEL = 'HOSTEL';
export const CARAVAN = 'CARAVAN';
export const TENT = 'TENT';
export const FIELD = 'FIELD';
export const CONFERENCE = 'CONFERENCE';
export const ITEM = 'ITEM';
export const CAMPING = 'CAMPING';
export const LOCKER = 'LOCKER';

export const PRODUCTS = [
  { type: CAMPING, label: 'Camping' },
  { type: FIELD, label: 'Ytor' },
  { type: LOCKER, label: 'Omklädningsrum' },
  { type: CONFERENCE, label: 'Konferensrum' },
  { type: ITEM, label: 'Artiklar' }
];

export const BOOKING_REFERENCE = 'BOOKING_REFERENCE';
export const PRODUCT = 'PRODUCT';

export const HOUSING_TYPE = [
  { type: CABIN, label: 'Stuga' },
  { type: ROOM, label: 'Arena' },
  { type: HOSTEL, label: 'Vandrarhem' },
  { type: CARAVAN, label: 'Husvagn' },
  { type: TENT, label: 'Campingplats UE' }
];

export const PERSON = 'PERSON';
export const COMPANY = 'COMPANY';

export const FIELD_ELEVEN = { type: 11, label: '11 mot 11' };
export const FIELD_NINE = { type: 9, label: '9 mot 9' };
export const FIELD_SEVEN = { type: 7, label: '7 mot 7' };
export const FIELD_FIVE = { type: 5, label: '5 mot 5' };
export const FIELD_ADAPTED = { type: 2, label: 'Anpassad' };
export const FIELD_OTHER = { type: 1, label: 'Övrigt' };

export const FIELD_SIZE = [FIELD_ELEVEN, FIELD_NINE, FIELD_SEVEN, FIELD_FIVE, FIELD_ADAPTED, FIELD_OTHER];

export const FIELD_MATCH = { type: 'MATCH', label: 'Tävling' };
export const FIELD_FULL = { type: 'FULL', label: 'Hel' };
export const FIELD_HALF = { type: 'HALF', label: 'Halv' };
export const FIELD_ONE_THIRD = { type: 'ONE_THIRD', label: 'En tredjedel' };
export const FIELD_TWO_THIRD = { type: 'TWO_THIRDS', label: 'Två tredjedelar' };

export const FIELD_MATCH_DAY = { type: 'MATCH_DAY', label: 'Tävling - Heldag' };
export const FIELD_FULL_DAY = { type: 'FULL_DAY', label: 'Hel - Heldag' };
export const FIELD_HALF_DAY = { type: 'HALF_DAY', label: 'Halv - Heldag' };
export const FIELD_ONE_THIRD_DAY = { type: 'ONE_THIRD_DAY', label: 'En tredjedel - Heldag' };
export const FIELD_TWO_THIRD_DAY = { type: 'TWO_THIRDS_DAY', label: 'Två tredjedelar - Heldag' };

export const BOOKING_TYPE = [
  FIELD_MATCH,
  FIELD_FULL,
  FIELD_HALF,
  FIELD_ONE_THIRD,
  FIELD_TWO_THIRD,
  DAILY,
  WEEKLY,
  FIELD_MATCH_DAY,
  FIELD_FULL_DAY,
  FIELD_HALF_DAY,
  FIELD_ONE_THIRD_DAY,
  FIELD_TWO_THIRD_DAY
];

export const ONEDAY = 24 * 60 * 60 * 1000;

export const ONEHOUR = 60 * 60 * 1000;

export const HOUR_LABELS = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '00:00'
];

export const WEEKDAYS = [
  { id: 1, label: 'M' },
  { id: 2, label: 'T' },
  { id: 3, label: 'O' },
  { id: 4, label: 'T' },
  { id: 5, label: 'F' },
  { id: 6, label: 'L' },
  { id: 7, label: 'S' }
];
