import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import useForm from '../../../Hooks/useForm';
import Input from '../../../Components/Forms/Input';
import { Regexp } from '../../../Resources/Stylesheets/Variables';
import { cap, formatPrice } from '../../../Library/Library';
import { useEditBookingReference, useGetCompany } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';
import { COMPANY, PERSON } from '../../../Library/Variables';
import Select from '../../../Components/Forms/Select';
import { addErr } from '../../../Reducers/Error/actions';

const Form = styled.form`
  display: grid;
  grid-template-columns: 250px 250px;
  grid-template-rows: 1fr;
  grid-gap: 10px;
`;

const Column = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  grid-gap: 10px;
`;

const selectMarks = state => state.marks;

const selectCustomerMarks = customerID =>
  createSelector(selectMarks, marks => {
    return marks.filter(e => e.customers.find(y => y.customerID === customerID));
  });

const EditReference = ({ formID, formData, close, refetch }) => {
  const type = formData.customerType;
  const { customer } = formData;
  const marks = useSelector(selectCustomerMarks(customer.customerID));
  const MARKS = marks.map(e => ({ type: e.id, label: e.name }));
  const mark = MARKS.find(e => e.type.toString() === formData.markID);
  const dispatch = useDispatch();
  const { values, handleChange, updateValues } = useForm({
    ...formData,
    firstName: cap(customer.firstName),
    lastName: cap(customer.lastName),
    customerID: customer.customerID,
    name: cap(customer.name),
    reference: cap(customer.reference),
    phone: customer.phone,
    email: customer.email,
    address: cap(customer.address),
    zipCode: customer.zipCode,
    city: cap(customer.city),
    country: cap(customer.country),
    discount: formData.discount,
    markID: mark && mark.type
  });
  const edit = useAfterSuccess(useEditBookingReference(), () => {
    refetch();
    close();
  });

  const search = useAfterSuccess(useGetCompany(), data => updateValues(data));

  const getCompanyData = async e => {
    e.preventDefault();
    search.request({ id: values.customerID });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const body = {
      comment: values.comment || null,
      customerType: type,
      markID: values.markID && values.markID.toString(),
      customer: {
        email: values.email,
        phone: values.phone,
        address: values.address,
        zipCode: values.zipCode,
        city: values.city,
        country: values.country
      }
    };
    if (type === PERSON) {
      body.customer = {
        ...body.customer,
        firstName: values.firstName,
        lastName: values.lastName
      };
    } else if (type === COMPANY) {
      body.customer = {
        ...body.customer,
        customerID: values.customerID,
        reference: values.reference,
        name: values.name
      };
    }
    if (parseFloat(values.discount || 0) > formatPrice(formData.priceVat)) {
      dispatch(addErr('Den totala rabatten kan inte var större än det totala priset.'));
      return;
    }
    body.discount = parseInt(values.discount, 10) || 0;
    body.referenceID = formData.referenceID;
    edit.request({ id: formData.referenceID, ...body });
  };

  return (
    <Form id={formID} onSubmit={handleSubmit}>
      <Column>
        {type === COMPANY && (
          <>
            <Input
              key="org"
              label="Kund ID"
              type="text"
              name="customerID"
              value={values.customerID}
              onChange={handleChange}
              required
              button={getCompanyData}
            />
            <Input
              key="name"
              label="Namn"
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              pattern={Regexp.name}
              minLength={1}
              maxLength={50}
              required
            />
            <Input
              key="reference"
              label="Referens"
              type="text"
              name="reference"
              value={values.reference}
              onChange={handleChange}
              pattern={Regexp.name}
              minLength={1}
              maxLength={100}
              required
            />
          </>
        )}
        {type === PERSON && (
          <>
            <Input
              label="Förnamn"
              type="text"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              required
              pattern={Regexp.name}
              minLength={1}
              maxLength={50}
            />
            <Input
              label="Efternamn"
              type="text"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              required
              pattern={Regexp.name}
              minLength={1}
              maxLength={50}
            />
          </>
        )}
        <Input label="E-Mail" type="email" name="email" value={values.email} onChange={handleChange} required />
        <Input label="Telefon" type="phone" name="phone" value={values.phone} onChange={handleChange} required />
        <Input label="Kommentar" type="text" name="comment" value={values.comment} onChange={handleChange} />
      </Column>
      <Column>
        <Input label="Adress" type="text" name="address" value={values.address} onChange={handleChange} required />
        <Input
          label="Postnummer"
          type="text"
          name="zipCode"
          value={values.zipCode}
          onChange={handleChange}
          minLength={1}
          maxLength={5}
          required
        />
        <Input
          label="Ort"
          type="text"
          name="city"
          value={values.city}
          onChange={handleChange}
          pattern={Regexp.name}
          required
        />
        <Input
          label="Land"
          type="text"
          name="country"
          value={values.country}
          onChange={handleChange}
          pattern={Regexp.name}
          required
        />
        <Input label="Rabatt (%)" type="text" name="discount" value={values.discount} onChange={handleChange} />
        {formData.customerType === COMPANY && (
          <Select label="Märkning" name="markID" value={values.markID} options={MARKS} onChange={handleChange} />
        )}
      </Column>
    </Form>
  );
};

EditReference.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
  ).isRequired,
  close: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

export default EditReference;
