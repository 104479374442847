import React, { useReducer } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts } from '../../../Resources/Stylesheets/Variables';
import PopOverForm from '../../../Components/PopOver/PopOverForm';
import { Avatar, Container as Template, Ripple } from '../../../Styles/Styles';
import { cap, formatDate } from '../../../Library/Library';
import { USER_TYPE } from '../../../Library/Variables';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import ForceUser from './ForceUser';
import { UserType } from '../../../Types/types';

const Container = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 10px;
`;

const Cells = styled(Template)`
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr)) 210px;
  grid-template-rows: max-content;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 10px;
  padding-left: 15px;
`;

const Cell = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: flex-start;
  align-items: center;
`;

const Manage = styled.div`
  display: grid;
  grid-template-columns: 70px 70px 70px;
  grid-template-rows: 70px;
  justify-content: flex-end;
`;

const Button = styled(Ripple)`
  font-size: ${Fonts.sizeRegular};
  color: ${props => props.theme.color};
  cursor: pointer;
  border-radius: 0;
  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const Delete = styled(Button)`
  border-left: 1px solid ${props => props.theme.border};
  border-right: 1px solid ${props => props.theme.border};
  &:hover {
    color: ${props => props.theme.negative};
  }
`;

const Lock = styled(Button)`
  color: ${props => (props.active ? props.theme.negative : props.theme.positive)};
`;

const DELETE = 'DELETE';
const EDIT = 'EDIT';
const FORCE = 'FORCE';
const IMAGE = 'IMAGE';

const reducer = (state, action) => {
  switch (action.type) {
    case EDIT:
      return { ...state, editing: !state.editing };
    case DELETE:
      return { ...state, deleting: !state.deleting };
    case FORCE:
      return { ...state, forcing: !state.forcing };
    case IMAGE:
      return { ...state, image: action.payload };
    default:
      throw new Error('Invalid reducer state');
  }
};

const createTime = date => {
  const h = date.getHours();
  const m = date.getMinutes();

  let t = '';
  t += h < 10 ? `0${h}` : h;
  t += ':';
  t += m < 10 ? `0${m}` : m;
  return t;
};

const User = ({ data }) => {
  const [{ editing, deleting, forcing, image }, dispatch] = useReducer(reducer, {
    deleting: false,
    editing: false,
    image: data.image
  });
  const showEdit = () => dispatch({ type: EDIT });
  const showDelete = () => dispatch({ type: DELETE });
  const showForce = () => dispatch({ type: FORCE });

  const name = cap(`${data.firstName} ${data.lastName}`);
  const initials = data.firstName.charAt(0) + data.lastName.charAt(0);
  const since = `sedan ${new Date(Date.parse(data.createdAt)).getFullYear()}`;
  const lastLogin = createTime(new Date(Date.parse(data.lastLogin)));

  return (
    <Container>
      <Avatar image={image} initials={initials} size="50px" />
      <Cells>
        <Cell>
          <h4>{name}</h4>
          <p>{data.username}</p>
        </Cell>
        <Cell>
          <h4>{USER_TYPE.filter(e => e.type === data.role).map(e => e.label)}</h4>
          <p>{since}</p>
        </Cell>
        <Cell>
          {data.lastLogin && <h4>{formatDate(data.lastLogin)}</h4>}
          {data.lastLogin && <p>{lastLogin}</p>}
        </Cell>
        <Manage>
          <Button value={<i className="icon-edit" />} onClick={showEdit} />
          <Delete value={<i className="icon-trash" />} onClick={showDelete} />
          <Lock
            value={<i className={data.changePassword ? 'icon-lock' : 'icon-lock-open'} />}
            active={data.changePassword}
            onClick={showForce}
          />
        </Manage>
      </Cells>
      {editing && (
        <PopOverForm
          data={{
            id: data.id,
            name,
            image,
            initials,
            title: 'Vill du byta denna användares roll?',
            text: 'Användaren kommer att loggas ut innan den nya ändringen börjar gälla.',
            submitLabel: 'Spara'
          }}
          Form={EditUser}
          formID="EditUser"
          formData={data}
          onCancel={showEdit}
        />
      )}
      {deleting && (
        <PopOverForm
          data={{
            id: data.id,
            name,
            image,
            initials,
            title: 'Vill du verkligen ta bort denna användare?',
            text: 'Denna åtgärd går inte att ångra. Kontakta en administratör om du har några frågor angående detta.',
            submitLabel: 'Ta bort användaren'
          }}
          Form={DeleteUser}
          formID="DeleteUser"
          formData={data}
          onCancel={showDelete}
        />
      )}
      {forcing && (
        <PopOverForm
          data={{
            id: data.id,
            name,
            image,
            initials,
            title: 'Vill du tvinga denna användare till ett lösenordsbyte?',
            text: 'Denna åtgärd går inte att ångra. Kontakta en administratör om du har några frågor angående detta.',
            submitLabel: 'Tvinga lösenordsbyte'
          }}
          Form={ForceUser}
          formID="ForceUser"
          formData={data}
          onCancel={showForce}
        />
      )}
    </Container>
  );
};

User.propTypes = {
  data: PropTypes.shape(UserType).isRequired
};

User.defaultValues = {
  image: ''
};

export default User;
