import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from '../Reducers/Profile/actions';
import { addUser, deleteUser, updateUser } from '../Reducers/Users/actions';
import { addProduct, deleteProduct, updateProduct } from '../Reducers/Products/actions';
import { setSettings } from '../Reducers/Settings/actions';
import {
  addBasketItem,
  deleteBasketItem,
  deleteBasketItemByProductID,
  updateBasketItem,
  updateBasketItemVat
} from '../Reducers/Basket/actions';
import { addBooking, deleteBooking, updateBooking } from '../Reducers/Bookings/actions';
import { BASE_HTTP_URL, parseData } from '../Library/Fetch';
import { addMark, deleteMark, updateMark } from '../Reducers/Marks/actions';

const URL = `${BASE_HTTP_URL.replace('http', 'ws')}/api/v1/ws`;

const ADMIN = 'ADMIN';

const handleUser = ({ method, data }, dispatch, profile) => {
  switch (method) {
    case 'UPDATE':
      if (data.id === profile.id) {
        dispatch(setProfile({ ...data }));
      }
      if (profile.role === ADMIN) {
        dispatch(updateUser(data));
      }
      break;
    case 'CREATE':
      dispatch(addUser(data));
      break;
    case 'DELETE':
      dispatch(deleteUser(data));
      break;
    default:
      throw Error('invalid user type in ws.');
  }
};

const handleProducts = ({ method, data }, dispatch) => {
  switch (method) {
    case 'UPDATE':
      dispatch(updateProduct(data));
      break;
    case 'CREATE':
      dispatch(addProduct(data));
      break;
    case 'DELETE':
      dispatch(deleteProduct(data));
      dispatch(deleteBasketItemByProductID(data));
      break;
    default:
      throw Error('invalid basket type in ws.');
  }
};

const handleMarks = ({ method, data }, dispatch) => {
  switch (method) {
    case 'UPDATE':
      dispatch(updateMark(data));
      break;
    case 'CREATE':
      dispatch(addMark(data));
      break;
    case 'DELETE':
      dispatch(deleteMark(data));
      break;
    default:
      throw Error('invalid basket type in ws.');
  }
};

const handleBookings = ({ method, data }, dispatch) => {
  switch (method) {
    case 'UPDATE':
      dispatch(updateBooking(data));
      break;
    case 'CREATE':
      dispatch(addBooking(data));
      break;
    case 'DELETE':
      dispatch(deleteBooking(data));
      break;
    default:
      throw Error('invalid basket type in ws.');
  }
};

const handleBasket = async ({ method, data }, dispatch) => {
  switch (method) {
    case 'UPDATE':
      dispatch(updateBasketItem(data));
      break;
    case 'CREATE':
      dispatch(addBasketItem(data));
      break;
    case 'DELETE':
      dispatch(deleteBasketItem(data));
      break;
    default:
      throw Error('invalid basket type in ws.');
  }
};

const handleSettings = async ({ method, data }, dispatch) => {
  switch (method) {
    case 'UPDATE':
      dispatch(setSettings(data));
      Object.entries(data).forEach(([type, { vat }]) => {
        if (type !== 'general') dispatch(updateBasketItemVat({ type: type.toUpperCase(), vat }));
      });
      break;
    default:
      throw Error('invalid settings type in ws.');
  }
};

const handleRequests = (subject, body, dispatch, profile) => {
  switch (subject) {
    case 'USER':
      return handleUser(body, dispatch, profile);
    case 'BASKET':
      return handleBasket(body, dispatch);
    case 'PRODUCT':
      return handleProducts(body, dispatch);
    case 'SETTINGS':
      return handleSettings(body, dispatch);
    case 'BOOKING':
      return handleBookings(body, dispatch);
    case 'MARK':
      return handleMarks(body, dispatch);
    default:
      throw Error(`Invalid method ${subject}`);
  }
};

const WS = () => {
  const { sessionToken } = useSelector(state => state.session);
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const [ws, setWs] = useState(new WebSocket(`${URL}?auth=${sessionToken}`));

  useEffect(() => {
    ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      // eslint-disable-next-line no-console
      console.log('connected');
    };

    ws.onmessage = evt => {
      // on receiving a message, add it to the list of messages
      const data = JSON.parse(evt.data);
      if (data.body.data) parseData(data.body.data);
      handleRequests(data.subject, data.body, dispatch, profile);
      // console.log(data);
      // const message = JSON.parse(evt.data);
      // this.addMessage(message);
    };

    ws.onclose = () => {
      // eslint-disable-next-line no-console
      console.log('disconnected');
      // automatically try to reconnect on connection loss
      setWs(new WebSocket(URL));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws.onclose, ws.onmessage, ws.onopen]);

  return <></>;
};

export default WS;
