import React from 'react';
import PropTypes from 'prop-types';
import useReactRouter from 'use-react-router';
import { useDeleteBookingReference } from '../../../Library/Hooks';
import { useAfterSuccess } from '../../../Hooks';

const DeleteReference = ({ formID, formData, close }) => {
  const { history } = useReactRouter();
  const { request } = useAfterSuccess(useDeleteBookingReference(), () => {
    close();
    history.push('/');
  });

  const handleSubmit = async e => {
    e.preventDefault();
    request({ id: formData.referenceID });
  };

  return <form id={formID} onSubmit={handleSubmit} />;
};

DeleteReference.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
    .isRequired,
  close: PropTypes.func.isRequired
};

export default DeleteReference;
